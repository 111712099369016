import { forwardRef } from 'react'
import { Text } from '@dlpco/ginga-stone'

import { Box, Flex } from '~/domains/platform/design-system'

import { type SelectProps } from './interface'
import { Wrapper } from './styles'

/**
 *
 * @deprecated reference the Select component of the jade design system.
 * @see [Select](https://jade-web.pagar.me/docs/?path=/docs/data-entry-select--docs)
 */

export const Select = forwardRef(({ label, error, name, children, ...restProps }: SelectProps, ref) => {
  return (
    <Flex flexDirection="column">
      <Box color="darkGray" pb={1} fontSize={2}>
        <Text as="label" inheritColor htmlFor={name} data-testid="label">
          {label}
        </Text>
      </Box>
      <Wrapper data-testid="select" fontSize={2} ref={ref} error={error} id={name} name={name} {...restProps}>
        {children}
      </Wrapper>
      <Box color="red" pt={1}>
        <Text inheritColor data-testid="error" size="small">
          {error && error}
        </Text>
      </Box>
    </Flex>
  )
})
