/**
 * @deprecated Don't use this function anymore. Create a local function inside your module instead.
 */
export const extractErrorMessage = (errors: any, errorPath: Array<number | string>) => {
  if (!errors) return undefined

  const error = errorPath.slice().reduce((acc, curr, _index, originalArr) => {
    if (acc) {
      const errorKeyPath = acc[curr]

      if (!errorKeyPath) originalArr.splice(1)

      return acc[curr]
    }

    return errors[curr]
  }, null)
  return (error as unknown as Record<'message', string>)?.message
}
