import { flowStepperEventBus } from '@credit-web/common/flow-stepper'
import { Link, Text } from '@stone-payments/jade'
import jadeTheme from '@stone-payments/jade-design-tokens/cjs/stone'

import { Flex } from '~/domains/platform/design-system'
import BlackBird from '~/domains/platform/lib/blackbird'
import { For, If } from '~/domains/platform/lib/utilities-components'

import { RefinancingSteps } from '../../helpers/refinancing-flow-routes'

export interface RefinancingFlowBreadcrumbProps {
  step: RefinancingSteps | undefined
}

export function RefinancingFlowBreadcrumb({ step }: RefinancingFlowBreadcrumbProps) {
  if (!step) return null
  if ([RefinancingSteps.SUCCESS, RefinancingSteps.CONFIRMATION].includes(step)) {
    return null
  }

  const moveToStep = (step: RefinancingSteps) => {
    BlackBird.travelTo({
      // eslint-disable-next-line no-template-curly-in-string
      pathname: `/credito/emprestimo/renegociacao/${step}`,
      urlParams: { step }
    })

    flowStepperEventBus.emit('triggerMoveState', { stepKey: step, direction: 'backward' })
  }

  const defaultBreadcrumbs = [
    {
      steps: [RefinancingSteps.ROOT],
      title: 'Início',
      action: moveToStep
    },
    {
      steps: [RefinancingSteps.PROPOSALS_POOL],
      title: 'Condições',
      action: moveToStep
    },
    {
      steps: [RefinancingSteps.SIMULATION],
      title: 'Simulação',
      action: moveToStep
    },
    {
      steps: [RefinancingSteps.PROPOSAL],
      title: 'Proposta',
      action: moveToStep
    },
    {
      steps: [RefinancingSteps.KYC],
      title: 'Dados pessoais',
      action: moveToStep
    },
    {
      steps: [RefinancingSteps.SUMMARY],
      title: 'Resumo',
      action: moveToStep
    },
    {
      steps: [RefinancingSteps.PIN],
      title: 'Senha',
      action: moveToStep
    }
  ]

  const breadcrumbs = defaultBreadcrumbs.slice(0, defaultBreadcrumbs.findIndex(e => step && e.steps.includes(step)) + 1)

  return (
    <Flex
      alignContent="center"
      gap="var(--comp-link-gap-x)"
      minHeight="var(--comp-navigation-bar-min-height)"
      alignItems="center"
      ml={['20px', 0, 0, 0]}
    >
      <For
        of={breadcrumbs}
        render={(breadcrumb, index) => {
          const { title, action } = breadcrumb
          return (
            <Flex key={breadcrumb.title} alignItems="center" gap={jadeTheme.theme.space.xsmall}>
              <If condition={index < breadcrumbs.length - 1}>
                <Link onClick={() => action(breadcrumb.steps[0])} variant="neutral" style={{ fontWeight: 400 }}>
                  {title}
                </Link>
              </If>
              <If condition={index === breadcrumbs.length - 1}>
                <Text variant="text-medium" weight="semibold">
                  {title}
                </Text>
              </If>
            </Flex>
          )
        }}
      />
    </Flex>
  )
}
