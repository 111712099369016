import React from 'react'
import jadeTheme from '@stone-payments/jade-design-tokens/cjs/stone'

import { Flex } from '~/domains/platform/design-system'

export const maxWidth = '904px'

export const SharedPageContainerWrapper: React.FC<{ children: React.ReactNode; style?: React.CSSProperties }> =
  React.memo(({ children, style }) => {
    return (
      <Flex
        style={{ background: jadeTheme.theme.color.background.body, ...style }}
        justifyContent="center"
        data-testid="outer-container"
        flex="1"
      >
        <Flex flexDirection="column" width="100%" maxWidth={maxWidth} data-testid="child-wrapper">
          {children}
        </Flex>
      </Flex>
    )
  })
