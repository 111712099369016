import snakecaseKeys from 'snakecase-keys'

import { type ScenarioMock } from '~/scaffold/mocks/types/mock-flags-types'

import {
  activeLoanMock,
  activeLoanWithoutPaymentMock,
  failedRenegotiationLoanMock,
  multipleOverdueLoan,
  multipleOverdueWithRenegotiationMock,
  oneInstallmentOverdueLoan,
  oneSettledInstallmentMock,
  renegotiatedLoanMock,
  settledLoanMock
} from './loan-details-mock-content'

export const loanDetails: ScenarioMock[] = [
  {
    scenario: 'Empréstimo com renegociação em andamento /  empréstimo com atraso múltiplo',
    mocks: [
      {
        method: 'GET',
        url: '**/experience-bff-service/management/api/web/v1/loans/**/details',
        status: 200,
        delay: 200,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(snakecaseKeys(multipleOverdueWithRenegotiationMock))
      }
    ]
  },
  {
    scenario: 'Empréstimo com renegociação que não aconteceu',
    mocks: [
      {
        method: 'GET',
        url: '**/experience-bff-service/management/api/web/v1/loans/**/details',
        status: 200,
        delay: 200,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(snakecaseKeys(failedRenegotiationLoanMock))
      }
    ]
  },
  {
    scenario: 'Empréstimo renegociado',
    mocks: [
      {
        method: 'GET',
        url: '**/experience-bff-service/management/api/web/v1/loans/**/details',
        status: 200,
        delay: 200,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(snakecaseKeys(renegotiatedLoanMock))
      }
    ]
  },
  {
    scenario: 'Empréstimo quitado',
    mocks: [
      {
        method: 'GET',
        url: '**/experience-bff-service/management/api/web/v1/loans/**/details',
        status: 200,
        delay: 200,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(snakecaseKeys(settledLoanMock))
      }
    ]
  },
  {
    scenario: 'Empréstimo aberto com pagamento em dia',
    mocks: [
      {
        method: 'GET',
        url: '**/experience-bff-service/management/api/web/v1/loans/**/details',
        status: 200,
        delay: 200,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(snakecaseKeys(activeLoanMock))
      }
    ]
  },
  {
    scenario: 'Empréstimo em dia sem nenhum pagamento',
    mocks: [
      {
        method: 'GET',
        url: '**/experience-bff-service/management/api/web/v1/loans/**/details',
        status: 200,
        delay: 200,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(snakecaseKeys(activeLoanWithoutPaymentMock))
      }
    ]
  },
  {
    scenario: 'Empréstimo com atraso multiplo',
    mocks: [
      {
        method: 'GET',
        url: '**/experience-bff-service/management/api/web/v1/loans/**/details',
        status: 200,
        delay: 200,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(snakecaseKeys(multipleOverdueLoan))
      }
    ]
  },
  {
    scenario: 'Empréstimo com uma parcela em atraso',
    mocks: [
      {
        method: 'GET',
        url: '**/experience-bff-service/management/api/web/v1/loans/**/details',
        status: 200,
        delay: 200,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(snakecaseKeys(oneInstallmentOverdueLoan))
      }
    ]
  },
  {
    scenario: 'Empréstimo com uma parcela em quitada',
    mocks: [
      {
        method: 'GET',
        url: '**/experience-bff-service/management/api/web/v1/loans/**/details',
        status: 200,
        delay: 200,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(snakecaseKeys(oneSettledInstallmentMock))
      }
    ]
  }
]
