import { Bucket } from '~/domains/platform/lib/bucket'
import { isomorphicPush } from '~/lib/helpers'

import { handlePinChallenge } from './helpers'
import { type HttpErrorHandler, HttpErrorHandlerPriority } from './types'

const challengerValidationHandler: HttpErrorHandler = async ({
  res,
  requestInfo
}) => {
  const data = await res.json()

  if (res.status === 403 && data.type === 'srn:error:challenge_required') {
    const newRes = await handlePinChallenge(
      requestInfo.url,
      requestInfo.options,
      data.challenge
    )
    return { data: await newRes.json(), status: newRes.status }
  }
}

const unauthorizedHandler: HttpErrorHandler = async ({ res }) => {
  if (res.status === 401) {
    isomorphicPush('/sair?shouldRedirect=true')
  }
}

const kycPendingHandler: HttpErrorHandler = async ({ res }) => {
  const data = await res.json()

  if (res.status === 403 && data.type === 'srn:error:kyc_pending_request') {
    isomorphicPush('/sair?shouldRedirect=true')
  }
}

const challengerLockedHandler: HttpErrorHandler = async ({ res }) => {
  const data = await res.json()

  if (res.status === 403 && data.type === 'srn:error:challenge_locked') {
    isomorphicPush('/pin-bloqueado')
  }
}

const inMaintenanceHandler: HttpErrorHandler = async ({ res }) => {
  const data = await res.json()
  if (res.status === 503 && data.type === 'srn:error:in_maintenance') {
    const message = data.message
    Bucket.cookies.set('maintenance::error::message', message)
    isomorphicPush('/fora-de-servico')
  }

  if (res.status === 503) {
    isomorphicPush('/manutencao')
  }
}

const handlers = [
  {
    key: 'unauthorized',
    handler: unauthorizedHandler,
    priority: HttpErrorHandlerPriority.HIGH
  },
  {
    key: 'challenger-validation',
    handler: challengerValidationHandler,
    priority: HttpErrorHandlerPriority.HIGH
  },
  {
    key: 'kyc-pending',
    handler: kycPendingHandler,
    priority: HttpErrorHandlerPriority.HIGH
  },
  {
    key: 'challenger-locked',
    handler: challengerLockedHandler,
    priority: HttpErrorHandlerPriority.HIGH
  },
  {
    key: 'in-maintenance',
    handler: inMaintenanceHandler,
    priority: HttpErrorHandlerPriority.HIGH
  }
] as const

type DefaultErrorHandlersKeys = (typeof handlers)[number]['key']

export const defaultErrorHandlers = {
  /**
   * Get default error handlers by key
   *
   * @example
   * ```ts
   * get('unauthorized', 'challengerValidation')
   * /* return 'unauthorized' and 'challengerValidation' default error handlers *\/
   * ```
   */
  get: (...keys: DefaultErrorHandlersKeys[]) =>
    handlers.filter(handler => keys.includes(handler.key)),
  /**
   * Get all default error handlers
   *
   * @example
   * ```ts
   * getAll()
   * /* return all default error handlers *\/
   * ```
   */
  getAll: () => handlers,
  /**
   * Ignore error handlers by key
   *
   * @example
   * ```ts
   * ignore('unauthorized', 'challengerValidation')
   * /* return all default error handlers except 'unauthorized' and 'challengerValidation' *\/
   */
  ignore: (...keys: DefaultErrorHandlersKeys[]) =>
    handlers.filter(handler => !keys.includes(handler.key))
} as const
