import { analiticaInstance } from 'shared/analitica'

export default analiticaInstance.eventFactory('StoneAccount', {
  profileMenu: {
    /**
     * @analitica When the user clicks on the go back button
     */
    click: (item: string) => ({
      label: 'home web - menu perfil chosen button',
      metadata: { 'menu-perfil': item }
    })
  },
  emailChange: {
    /**
     * @analitica When the user initiates the email change process
     */
    start: () => ({
      label: 'changing.registration.data-change.data'
    }),
    /**
     * @analitica When the user views the email change in-review screen
     */
    kycInReviewViewed: () => ({
      label: 'email.change-error',
      metadata: {
        reason: 'kyc em análise',
        propertyDescription:
          'Quando o cliente fez o fluxo de completar cadastro e ainda está em análise. O cliente só poderá alterar seu e-mail depois que a análise estiver completa. Evento dispara quando o cliente visualizar esta tela.'
      }
    }),

    /**
     * @analitica When the user views the pending KYC email change screen
     */
    pendingKycViewed: () => ({
      label: 'email.change-error',
      metadata: {
        reason: 'kyc pendente',
        propertyDescription:
          'Quando o cliente ainda não completou o cadastro. O cliente só consegue alterar o e-mail depois de realizar KYC e se a análise tiver sucesso. Evento dispara quando o cliente visualizar esta tela.'
      }
    }),
    /**
     * @analitica When the user attempts to use an already registered email
     */
    newEmailBelongsToAnotherAccount: () => ({
      label: 'email.change-error',
      metadata: {
        reason: 'novo email pertence a outra conta',
        propertyDescription:
          'Quando o cliente escolhe um novo e-mail que já está associado a outra conta na Stone. Dessa forma, vamos instruir que o cliente escolha outro endereço de e-mail ou que tenha maior ajuda clicando em saiba mais. Evento dispara quando o cliente visualizar a tela.'
      }
    }),
    /**
     * @analitica When the user clicks "Saiba Mais" for more information
     */
    bottomSheetMoreInformation: () => ({
      label: 'email.change-bottom.sheet.more.information',
      metadata: {
        reason: 'novo email pertence a outra conta',
        propertyDescription:
          'Quando o cliente clica em "saiba mais” na tela anterior e visualiza o bottom sheet com mais informações.  Evento dispara quando o cliente visualizar o bottom sheet.'
      }
    }),
    /**
     * @analitica When the user sees the cancel confirmation modal
     */
    bottomSheetToCancelViewed: (action: 'continuar depois' | 'cancelar pedido') => ({
      label: 'email.change-bottomsheet.to.cancel.viewed',
      metadata: { action }
    }),
    /**
     * @analitica When the user decides to continue or cancel the email change request
     */
    continue: (action: 'continuar' | 'cancelar pedido') => ({
      label: 'email.change-continue',
      metadata: { action }
    }),
    /**
     * @analitica When the user fails the PIN validation more than 5 times
     */
    pinErrorFeedback: () => ({
      label: 'email.change-pin.error.feedback'
    }),
    /**
     * @analitica When the user needs to confirm their identity in the app
     */
    confirmIdentityOnTheApp: () => ({
      label: 'email.change-confirm.identity.on.the.app.viewed'
    })
  }
})
