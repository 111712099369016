import { Heimdall } from '~/domains/platform/core/heimdall'
import BlackBird from '~/domains/platform/lib/blackbird'

export function checkRefinancingFeatureFlags(): boolean {
  /**
   * Add more FF's to the array pass, of others refinancing product versions
   */
  if (!Heimdall.pass(['renegotiation_contract_new_ccb_addendum'], 'all')) {
    BlackBird.travelTo('/404')
    return false
  }

  return true
}
