import { Stack } from '@dlpco/fluid-layout'

import { For } from '~/domains/platform/lib/utilities-components'

import { StepListItem } from './item'
import { type Step, type StepListProps, type StepWithKey } from './types'

/**
 *
 * @deprecated reference the Stepper component of the jade design system.
 * @see [Stepper](https://jade-web.pagar.me/docs/?path=/docs/status-feedback-stepper--docs)
 */

export function StepList<
  Keys extends StepListProps['steps'][number]['key'],
  CurrentType extends Keys | number = Keys | number
>(props: StepListProps<Keys, CurrentType>) {
  const { steps, current, isNavigable, navigationText = 'Editar', status = 'in-progress' } = props

  const stepInProgress = steps.findIndex(step => !step.completed)
  const currentIndex =
    typeof current === 'string' ? (steps as StepWithKey[]).findIndex(step => step.key === current) : current

  function handleVariant(index: number) {
    const step = steps[index]

    if (step.completed) {
      return 'checked'
    } else if (stepInProgress === index) {
      return status
    }

    return 'empty'
  }

  return (
    <Stack space="1.5rem">
      <For
        of={steps as Step[]}
        render={(step, index) => (
          <StepListItem
            key={index}
            isCurrent={currentIndex === index}
            isClickable={isNavigable && stepInProgress === index}
            variant={handleVariant(index)}
            title={step.title}
            description={step.description}
            navigationText={navigationText}
            showNavigationText={isNavigable && step.completed}
            onNavigate={step.onNavigate}
            thirdLine={step.thirdLine}
          />
        )}
      />
    </Stack>
  )
}
