import { getDefaultHeaders, isHttpClientResponse } from './helpers'
import { defaultErrorHandlers } from './http-error-handlers'
import {
  type HttpBody,
  type HttpClientResponse,
  type HttpErrorHandler,
  type HttpErrorHandlerWithPriority,
  type HttpHeaders,
  type HttpParams,
  type HttpRequestInfo,
  type HttpResponseType,
  type Options,
  HttpClienteError,
  HttpErrorHandlerPriority
} from './types'
import { camelify } from './utils'

type HttpErrorHandlerProp = (HttpErrorHandler | HttpErrorHandlerWithPriority)[]

type HttpClientProps = {
  baseUrl: string
  errorHandlers?: HttpErrorHandlerProp
  /**
   * If true, the default error handlers will be used.
   * @default true
   */
  withDefaultErrorHandlers?: boolean
}

/**
 * A class for making HTTP requests with customizable error handling.
 *
 * This class provides methods for making HTTP requests (GET, POST, PUT, PATCH, DELETE)
 * and allows for custom error handling through the use of error handlers.
 *
 * @class
 */
export class EXPERIMENTAL_HttpClient {
  #baseUrl: string
  #errorHandlers: HttpErrorHandler[]

  /**
   * Creates an instance of HttpClient.
   *
   * @param baseUrl - The base URL for the HTTP requests.
   * @param errorHandlers - Custom error handlers.
   * @param withDefaultErrorHandlers - Whether to use default error handlers.
   */
  constructor({
    baseUrl,
    errorHandlers = [],
    withDefaultErrorHandlers = true
  }: HttpClientProps) {
    const defaultHandlers = withDefaultErrorHandlers
      ? defaultErrorHandlers.getAll()
      : []
    const customHandlers = errorHandlers.map(handler => {
      if (typeof handler === 'function') {
        return { handler, priority: HttpErrorHandlerPriority.MEDIUM }
      }
      return handler
    })
    const handlers = [...defaultHandlers, ...customHandlers].sort(
      (a, b) => a.priority - b.priority
    )

    this.#baseUrl = baseUrl
    this.#errorHandlers = handlers.map(({ handler }) => handler)
  }

  /**
   * Makes an HTTP request.
   *
   * @private
   * @template T - The type of the response data.
   * @template E - The type of the error data.
   * @param url - The URL for the request.
   * @param options - The options for the request.
   * @returns The response from the server.
   * @throws {HttpClienteError<E>} - If the request fails.
   */
  async #request<T, E>(
    url: string,
    options: Options
  ): Promise<HttpClientResponse<T>> {
    const {
      headers,
      params,
      body,
      method = 'GET',
      responseType = 'json',
      ...restOptions
    } = options

    const resolvedUrl = this.#resolveUrl(url, params)
    const allHeaders = this.#handleHeaders(headers)

    const requestInfo: HttpRequestInfo = {
      url: resolvedUrl,
      options: {
        headers: allHeaders,
        body: body ? this.#handleBody(body) : undefined,
        method,
        ...restOptions
      }
    }

    const res = await fetch(requestInfo.url, requestInfo.options)

    if (!res.ok) {
      for (const handler of this.#errorHandlers) {
        const handled = await handler<T, E>({ res, requestInfo })

        if (handled instanceof HttpClienteError) {
          throw handled
        }

        if (isHttpClientResponse(handled)) {
          return {
            data:
              responseType === 'json' ? camelify(handled.data) : handled.data,
            status: handled.status
          }
        }
      }

      throw new HttpClienteError<E>({
        status: res.status,
        message: res.statusText,
        data: await res.json()
      })
    }

    const data = await this.#parseResponse<T>(res, responseType)

    return { data, status: res.status }
  }

  /**
   * Makes a GET request.
   *
   * @template T - The type of the response data.
   * @template E - The type of the error data.
   * @param url - The URL for the request.
   * @param params - The query parameters for the request.
   * @param options - The options for the request.
   * @returns The response from the server.
   * @example
   * ```ts
   * const response = await httpClient.get<MyResponseType>('/endpoint', { param1: 'value1' });
   * ```
   */
  async get<T, E = unknown>(
    url: string,
    params?: HttpParams,
    options?: Omit<Options, 'method' | 'params' | 'body'>
  ) {
    return this.#request<T, E>(url, { ...options, params, method: 'GET' })
  }

  /**
   * Makes a POST request.
   *
   * @template T - The type of the response data.
   * @template E - The type of the error data.
   * @param url - The URL for the request.
   * @param body - The body of the request.
   * @param options - The options for the request.
   * @returns The response from the server.
   * @example
   * ```ts
   * const response = await httpClient.post<MyResponseType>('/endpoint', { key: 'value' });
   * ```
   */
  async post<T, E = unknown>(
    url: string,
    body: HttpBody,
    options?: Omit<Options, 'method' | 'body'>
  ) {
    return this.#request<T, E>(url, { ...options, body, method: 'POST' })
  }

  /**
   * Makes a PUT request.
   *
   * @template T - The type of the response data.
   * @template E - The type of the error data.
   * @param url - The URL for the request.
   * @param body - The body of the request.
   * @param options - The options for the request.
   * @returns The response from the server.
   * @example
   * ```ts
   * const response = await httpClient.put<MyResponseType>('/endpoint', { key: 'value' });
   * ```
   */
  async put<T, E = unknown>(
    url: string,
    body: HttpBody,
    options?: Omit<Options, 'method' | 'body'>
  ) {
    return this.#request<T, E>(url, { ...options, body, method: 'PUT' })
  }

  /**
   * Makes a PATCH request.
   *
   * @template T - The type of the response data.
   * @template E - The type of the error data.
   * @param url - The URL for the request.
   * @param body - The body of the request.
   * @param options - The options for the request.
   * @returns The response from the server.
   * @example
   * ```ts
   * const response = await httpClient.patch<MyResponseType>('/endpoint', { key: 'value' });
   * ```
   */
  async patch<T, E = unknown>(
    url: string,
    body: HttpBody,
    options?: Omit<Options, 'method' | 'body'>
  ) {
    return this.#request<T, E>(url, { ...options, body, method: 'PATCH' })
  }

  /**
   * Makes a DELETE request.
   *
   * @template T - The type of the response data.
   * @template E - The type of the error data.
   * @param url - The URL for the request.
   * @param options - The options for the request.
   * @returns The response from the server.
   * @example
   * ```ts
   * const response = await httpClient.delete<MyResponseType>('/endpoint');
   * ```
   */
  async delete<T, E = unknown>(url: string, options?: Omit<Options, 'method'>) {
    if (options?.body) {
      console.warn(
        '🚨 DELETE requests with body may be ignored by some servers!'
      )
    }
    return this.#request<T, E>(url, { ...options, method: 'DELETE' })
  }

  /**
   * Handles the headers for the request.
   *
   * @private
   * @param headers - The headers for the request.
   * @returns The combined headers.
   */
  #handleHeaders(headers?: HttpHeaders) {
    const defaultHeaders = getDefaultHeaders()
    const allHeaders = new Headers(defaultHeaders)

    if (headers) {
      for (const [key, value] of Object.entries(headers)) {
        allHeaders.set(key, value)
      }
    }

    return allHeaders
  }

  /**
   * Resolves the URL for the request.
   *
   * @private
   * @param path - The path for the request.
   * @param params - The query parameters for the request.
   * @returns The resolved URL.
   */
  #resolveUrl(path: string, params?: HttpParams) {
    const fullUrl = `${this.#baseUrl}/${path}`
      .replace(/([^:]\/)\/+/g, '$1')
      .replace(/\/$/, '')

    const url = new URL(fullUrl)

    if (!params || Object.keys(params).length === 0) return url

    for (const [key, value] of Object.entries(params)) {
      if (value != null && value !== '') {
        url.searchParams.append(key, String(value))
      }
    }

    return url
  }

  /**
   * Parses the response from the server.
   *
   * @private
   * @template T - The type of the response data.
   * @param  res - The response from the server.
   * @param responseType - The expected response type.
   * @returns The parsed response data.
   */
  async #parseResponse<T>(res: Response, responseType: HttpResponseType) {
    switch (responseType) {
      case 'blob':
        return (await res.blob()) as T
      case 'text':
        return (await res.text()) as T
      case 'arrayBuffer':
        return (await res.arrayBuffer()) as T
      case 'json':
      default: {
        const data = await res.json()
        const camelizedData = camelify(data)
        return camelizedData as T
      }
    }
  }

  /**
   * Handles the body of the request.
   *
   * @private
   * @param body - The body of the request.
   * @returns  The processed body.
   */
  #handleBody(body: HttpBody) {
    if (
      body instanceof Blob ||
      body instanceof FormData ||
      body instanceof ReadableStream ||
      body instanceof URLSearchParams
    ) {
      return body
    }

    return JSON.stringify(body)
  }
}
