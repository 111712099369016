import { Fragment, useEffect } from 'react'
import { useEffectOnce } from 'react-use'
import { Stack } from '@dlpco/fluid-layout'
import { Card, CardBody, Heading } from '@dlpco/ginga-stone'

import withCreditGuard from '~/domains/credit/ports/components/HOCS/with-credit-guard'
import { Flex } from '~/domains/platform/design-system'
import { Dimmer } from '~/domains/platform/design-system/dimmer'
import { Loader } from '~/domains/platform/design-system/loader/loader'
import { Skeleton } from '~/domains/platform/design-system/skeleton/skeleton'
import { type WithPageConfigProps } from '~/domains/platform/infra/page-enhancers/with-page-config'
import BlackBird from '~/domains/platform/lib/blackbird'
import { If } from '~/domains/platform/lib/utilities-components'

import { LoanConcessionModal } from '../../loan-concession-v2/components/loan-concession-modal'
import { type LoanSection, type OpportunitySectionElement } from '../entities'
import { translateOpportunityType } from '../helper/loan-home'
import analitica from '../helpers/analitica'
import { useGoToOffers } from '../hooks/use-go-to-offers'
import { useLoanHistoryCard } from '../hooks/use-loan-history-card'
import { useLoanHome } from '../hooks/use-loan-home'
import { useLoanHomeLoanConcessionDialog } from '../hooks/use-loan-home-loan-concession-dialog'

import { LoanHomeHistoryCard } from './loan-home-history-card'
import { LoanHomeInfoCards } from './loan-home-info-cards'
import { LoanHomeLoanCards } from './loan-home-loan-cards'
import { LoanHomeNotificationsBanners } from './loan-home-notifications-banners'
import { LoanHomeProposalBanner } from './loan-home-proposal-banner'

const FallbackComponent = () => <></>

export type LoanConcessionModalProps = {
  offerId?: string
  proposalId?: string
}

const containerStyle = {
  width: '100%',
  maxWidth: '1024px',
  margin: '0px auto',
  padding: '40px'
}

export function LoanHome(props: WithPageConfigProps) {
  const queryParams = BlackBird.getQuery()
  const hasOfferId = Object.prototype.hasOwnProperty.call(queryParams, 'offerId')

  const { loanConcessionModalIsOpen, toggleLoanConcessionModal, loanConcessionModalProps } =
    useLoanHomeLoanConcessionDialog()
  const { entity } = props
  const document = entity?.document
  const organizationId = entity?.id

  const {
    isLoading: loadingOffer,
    selectedOfferId,
    selectedProposalId,
    showLoanConcessionModal
  } = useGoToOffers({ ...queryParams, document }, { enabled: hasOfferId })

  const canRenderPage = !hasOfferId || !loadingOffer

  useEffect(() => {
    if (showLoanConcessionModal) toggleLoanConcessionModal(true)
  }, [showLoanConcessionModal, toggleLoanConcessionModal])

  const {
    data: loans,
    isLoading,
    isFetching: isLoansFetching
  } = useLoanHistoryCard({ document }, { enabled: canRenderPage })

  const { data: loanHomeData, isFetching: isLoanHomeFetching } = useLoanHome({ document }, { enabled: canRenderPage })

  useEffectOnce(() => analitica.events.home.view())

  if (isLoansFetching || isLoanHomeFetching) {
    return <LoanHomeLoading />
  }
  const notifications = loanHomeData?.content?.notifications
  const sections = loanHomeData?.content?.sections
  const loansSection = sections?.find(section => section.id === 'loans')?.elements || []
  const revolvingSection = sections?.find(section => section.id === 'revolving')?.elements || []

  const hasActiveLoanOrRevolvingCredit = !!loans?.length || loansSection.length > 0 || revolvingSection.length > 0
  const mainContent = []
  const sidebar = []

  const opportunities = (sections?.find(section => section.id === 'opportunities')?.elements ??
    []) as OpportunitySectionElement[]

  if (opportunities?.length > 0) {
    const categorizedOpportunities = opportunities.reduce(
      (acc, opportunity) => {
        const type = translateOpportunityType(opportunity)
        if (type === 'RevolvingCredit') {
          acc.revolving.push(opportunity)
        } else if (['Renegotiation', 'Offer', 'Proposal'].includes(type)) {
          acc.loans.push(opportunity)
        }
        return acc
      },
      { revolving: [] as OpportunitySectionElement[], loans: [] as OpportunitySectionElement[] }
    )

    if (categorizedOpportunities.revolving.length > 0) {
      const RevolvingOpportunity = withCreditGuard(
        ({ opportunities }: { opportunities: OpportunitySectionElement[] }) =>
          opportunities.map(opportunity => <LoanHomeProposalBanner key={opportunity.id} opportunity={opportunity} />),
        {
          productKey: 'revolving',
          document,
          organizationId,
          redirectWhenIsInMaintenance: false,
          onlyConfigurationsRequest: true,
          fallbackComponent: <FallbackComponent />
        }
      )

      mainContent.push({
        key: 'revolving-opportunities',
        element: <RevolvingOpportunity opportunities={categorizedOpportunities.revolving} />
      })
    }

    if (categorizedOpportunities.loans.length > 0) {
      const LoanOpportunity = withCreditGuard(
        ({ opportunities }: { opportunities: OpportunitySectionElement[] }) =>
          opportunities.map(opportunity => <LoanHomeProposalBanner key={opportunity.id} opportunity={opportunity} />),
        {
          productKey: 'loans',
          document,
          organizationId,
          redirectWhenIsInMaintenance: false,
          onlyConfigurationsRequest: true,
          fallbackComponent: <FallbackComponent />
        }
      )

      mainContent.push({
        key: 'loan-opportunities',
        element: <LoanOpportunity opportunities={categorizedOpportunities.loans} />
      })
    }
  }

  if (hasActiveLoanOrRevolvingCredit) {
    const LoansSection = withCreditGuard(
      ({ loans }: { loans: LoanSection[] }) => {
        if (!loans || loans.length === 0) return null
        return <LoanHomeLoanCards loans={loans} />
      },
      {
        productKey: 'loans',
        document,
        organizationId,
        redirectWhenIsInMaintenance: false,
        onlyConfigurationsRequest: true,
        fallbackComponent: <FallbackComponent />
      }
    )

    const RevolvingSection = withCreditGuard(
      ({ revolving }: { revolving: LoanSection[] }) => {
        if (!revolving || revolving.length === 0) return null
        return <LoanHomeLoanCards loans={revolving} />
      },
      {
        productKey: 'revolving',
        document,
        organizationId,
        redirectWhenIsInMaintenance: false,
        onlyConfigurationsRequest: true,
        fallbackComponent: <FallbackComponent />
      }
    )
    if (notifications) {
      mainContent.unshift({
        key: 'main-content-notifications',
        element: <LoanHomeNotificationsBanners notifications={notifications} />
      })
    }

    mainContent.push({
      key: 'main-content-loans',
      element: <LoansSection loans={loansSection as LoanSection[]} />
    })
    mainContent.push({
      key: 'main-content-revolving',
      element: <RevolvingSection revolving={revolvingSection as LoanSection[]} />
    })

    sidebar.push({
      key: 'sidebar-history',
      order: 1,
      element: <LoanHomeHistoryCard historyList={loans || []} isLoading={isLoading} flexDirection="column" />
    })

    sidebar.push({
      key: 'sidebar-item-loan-home-info-cards',
      order: 2,
      element: <LoanHomeInfoCards flexDirection="column" />
    })
  } else {
    mainContent.push({
      key: 'loan-home-info-cards-without-history',
      element: <LoanHomeInfoCards flexDirection="row" />
    })
  }

  sidebar.sort((a, b) => a.order - b.order)

  return (
    <div style={containerStyle}>
      <Stack space="2rem">
        <If condition={loadingOffer}>
          <Dimmer isVisible>
            <Loader />
          </Dimmer>
        </If>
        <Heading as="h1" size="large" weight="bold">
          Crédito
        </Heading>
        {isLoansFetching || isLoanHomeFetching ? (
          <LoanHomeLoading />
        ) : (
          <Flex gap="1.5rem">
            <Stack space="1.6rem" style={{ width: hasActiveLoanOrRevolvingCredit ? '70%' : '100%' }}>
              {mainContent.map(obj => (
                <Fragment key={obj.key}>{obj.element}</Fragment>
              ))}
            </Stack>
            <If condition={!isLoading && hasActiveLoanOrRevolvingCredit}>
              {hasActiveLoanOrRevolvingCredit && (
                <Stack space="2rem" style={{ width: '30%' }}>
                  {sidebar.map(obj => (
                    <Fragment key={obj.key}>{obj.element}</Fragment>
                  ))}
                </Stack>
              )}
            </If>
          </Flex>
        )}

        <LoanConcessionModal
          isOpen={loanConcessionModalIsOpen}
          toggleModal={toggleLoanConcessionModal}
          offerId={selectedOfferId}
          proposalId={selectedProposalId}
          bannerProps={loanConcessionModalProps}
        />
      </Stack>
    </div>
  )
}

export const LoanHomeLoading = () => {
  return (
    <div style={containerStyle}>
      <Stack space="2rem">
        <Flex gap="1.5rem" flexDirection={'row'}>
          <Stack style={{ width: '70%' }} space="1rem">
            <Card aria-label="carregando área de crédito">
              <CardBody>
                <Skeleton width={'100%'} height="3rem" />
                <Skeleton width={'100%'} height="10rem" />
              </CardBody>
            </Card>
          </Stack>
          <Stack style={{ width: '30%' }} space="1rem">
            <Card>
              <CardBody>
                <Skeleton width={'100%'} height="3rem" />
                <Skeleton width={'100%'} height="10rem" />
              </CardBody>
            </Card>
          </Stack>
        </Flex>
      </Stack>
    </div>
  )
}
