import { useRouter } from 'next/router'
import { FlowStepper } from 'modules/credit/common/flow-stepper/src/components/flow-stepper'
import { type FlowStep } from 'modules/credit/common/flow-stepper/src/types'

import { type WithPageConfigProps } from '~/domains/platform/infra/page-enhancers/with-page-config'

import { RefinancingProvider } from '../../context/refinancing-context'
import { RefinancingSteps, renegotiationRefinancingFlowGuard } from '../../helpers/refinancing-flow-routes'

export function RefinancingFlow({ entity }: WithPageConfigProps<'stone_account' | 'banking' | 'stone_account_kyc'>) {
  // const { setContextFeedbackError } = useRefinancingContext()
  const { query } = useRouter()
  const { step }: { step?: string } = query

  if (typeof step !== 'string') return null

  const steps: FlowStep<RefinancingSteps>[] = [
    {
      key: RefinancingSteps.PROPOSALS_POOL,
      step: () => <>Pool de Proposta {entity.document}</>
    },
    {
      key: RefinancingSteps.SIMULATION,
      step: () => <>Simulação</>
    },
    {
      key: RefinancingSteps.PROPOSAL,
      step: () => <>Proposta</>
    },
    {
      key: RefinancingSteps.KYC,
      step: () => <>Revisão de dados do usuário</>
    },
    {
      key: RefinancingSteps.SUMMARY,
      step: () => <>Resumo do contrato</>
    },
    {
      key: RefinancingSteps.PIN,
      step: () => <>Pin/Senha</>
    },
    {
      key: RefinancingSteps.CONFIRMATION,
      step: () => <>Confirmação</>
    },
    {
      key: RefinancingSteps.SUCCESS,
      step: () => <>Sua renegociação está sendo processada</>
    }
  ]

  return (
    <RefinancingProvider>
      <FlowStepper steps={steps} initialStep={step} guard={renegotiationRefinancingFlowGuard} />
    </RefinancingProvider>
  )
}
