import { CreditBucket, getKeysFromStorage } from '@credit-web/common/browser-utils'
import { analiticaInstance } from 'shared/analitica'

export default analiticaInstance.eventFactory('loan concession', {
  modal: {
    /**
     * @analitica The user saw the loan concession modal
     */
    view: ({ offerAmount, offerId }: { offerAmount?: string; offerId?: string }) => ({
      label: 'credit - loan modal view',
      metadata: {
        'offer amount': offerAmount,
        'offer id': offerId
      }
    }),
    /**
     * @analitica Click on the loan concession modal.
     */
    click: (action: 'continuar' | 'fechar') => ({
      label: 'credit - loan modal clicked',
      metadata: {
        action
      }
    })
  },
  simulation: {
    /**
     * @analitica The user saw the loan simulation screen
     */
    view: ({ offerAmount, offerId }: { offerAmount?: string; offerId?: string }) => ({
      label: 'credit - simulation view',
      metadata: {
        'offer amount': offerAmount,
        'offer id': offerId,
        type: 'loan concession'
      }
    }),

    /**
     * @analitica Click on the simulation screen.
     */
    click: (action: string) => ({
      label: 'credit - simulation clicked',
      metadata: {
        action
      }
    })
  },
  finishFeedback: {
    /**
     * @analitica The user saw the finish feedback screen
     */
    view: ({ offerAmount }: { offerAmount?: string }) => ({
      label: 'credit - loan finish feedback view',
      metadata: {
        type: 'loan concession',
        amount: offerAmount
      }
    }),
    /**
     * @analitica Click on the finish feedback screen.
     */
    click: (action: 'voltar' | 'início') => ({
      label: 'credit - loan finish feedback clicked',
      metadata: {
        action
      }
    })
  },
  negativeFeedback: {
    /**
     * @analitica The user saw the negative feedback screen
     */
    view: ({ offerAmount }: { offerAmount?: string }) => ({
      label: 'credit - loan negative feedback view',
      metadata: {
        type: 'loan concession',
        amount: offerAmount
      }
    })
  },
  loanRequested: {
    /**
     * @analitica State change in the loan requested.
     */
    stateChange: (
      step: 'criando conta stone' | 'demora na criação da conta stone' | 'sucesso' | 'erro ao criar conta stone'
    ) => ({
      label: 'credit - loan requested',
      metadata: { step, 'initial path': CreditBucket.get('credit::shared::initial-path'), ...getKeysFromStorage() }
    })
  }
})
