import { PageContainer } from '@stone-payments/jade'
import { type MoveToStep } from 'modules/credit/common/flow-stepper/src/types'

import { SharedWithCustomNavbar } from '~/domains/credit/shared/components/with-custom-navbar'
import { Flex } from '~/domains/platform/design-system'

import { type UseCreditoffersReturn } from '../../shared'
import { type NegotiationsProposal } from '../entities'
import analitica from '../helpers/analitica'
import { useLoanConcessionSimulationActions } from '../hooks/use-loan-concession-simulation'

import { LoanConcessionLoading } from './loan-concession-loading'
import { LoanConcessionSimulationContent } from './loan-concession-simulation-content'
import { LoanConcessionSimulationInput } from './loan-concession-simulation-input'

type Props = {
  creditOffer: UseCreditoffersReturn
  proposalAvailable?: NegotiationsProposal
  proposalProcessing?: NegotiationsProposal
  moveToStep: MoveToStep
}

export function LoanConcessionSimulation({ creditOffer, proposalAvailable, proposalProcessing, moveToStep }: Props) {
  const { isViewSmall, proposalData, isLoading, inputSimulateIsLoading, canSimulate, onSimulate, onContinue } =
    useLoanConcessionSimulationActions({
      creditOffer,
      moveToStep,
      proposalAvailable,
      proposalProcessing
    })

  if (isLoading) return <LoanConcessionLoading />

  return (
    <SharedWithCustomNavbar
      navigationBarTitle="Simular pedido"
      navigationBarNavigateButtonTarget={'/'}
      navigationBarNavigateButtonType="back"
      backgroundColor="body"
      onNavigateEvent={() => analitica.events.simulation.click('voltar')}
    >
      <PageContainer size="wide">
        <Flex gap="1.5rem" justifyContent="space-between" alignItems="flex-start">
          <Flex width={isViewSmall ? 'auto' : '280px'}>
            <LoanConcessionSimulationInput
              onSimulate={onSimulate}
              proposalData={proposalData}
              inputSimulateIsLoading={inputSimulateIsLoading}
              canSimulate={canSimulate}
            />
          </Flex>
          <Flex flexGrow={5}>
            <LoanConcessionSimulationContent
              onContinue={onContinue}
              proposalData={proposalData}
              moveToStep={moveToStep}
              inputSimulateIsLoading={inputSimulateIsLoading}
            />
          </Flex>
        </Flex>
      </PageContainer>
    </SharedWithCustomNavbar>
  )
}
