import { useEffectOnce } from 'react-use'
import { Box } from '@dlpco/fluid-layout'
import { Button, IconShape, PageContainer, Text } from '@stone-payments/jade'
import jadeTheme from '@stone-payments/jade-design-tokens/cjs/stone'
import { flowStepperEventBus } from 'modules/credit/common/flow-stepper/src/helpers/flow-stepper-event-bus'

import { SharedWithCustomNavbar } from '~/domains/credit/shared/components/with-custom-navbar'
import { Flex } from '~/domains/platform/design-system'
import BlackBird from '~/domains/platform/lib/blackbird'
import { stringFormat } from '~/lib/helpers'

import { type NegotiationsProposal, LoanConcessionSteps } from '../entities'
import analitica from '../helpers/analitica'
import { useLoanConcessionStore } from '../store'

type Props = {
  proposalProcessing?: NegotiationsProposal
}

export function LoanConcessionFeedback({ proposalProcessing }: Props) {
  const { requestedAmount } = useLoanConcessionStore()
  const { currency } = stringFormat

  useEffectOnce(() => {
    const amount = proposalProcessing ? proposalProcessing.loanConcession?.requestedAmount : requestedAmount

    analitica.events.finishFeedback.view({
      offerAmount: currency((amount || 0) * 100, true)
    })
  })

  const handleClickBack = () => {
    analitica.events.finishFeedback.click('voltar')
    if (proposalProcessing) BlackBird.travelTo('/')
    else {
      flowStepperEventBus.emit('triggerMoveState', {
        stepKey: LoanConcessionSteps.SIMULATION,
        direction: 'backward'
      })
    }
  }
  return (
    /*
     * TODO: Jest-axe error
     * 1. "landmark-unique": Ensures landmarks are unique (landmark-unique)
     */
    <Box
      style={{ height: '100%', backgroundColor: jadeTheme.theme.color.background.surface, justifyContent: 'center' }}
    >
      <SharedWithCustomNavbar
        navigationBarTitle="Simular pedido"
        backgroundColor="surface"
        navigationBarNavigateButtonTarget={handleClickBack}
        navigationBarNavigateButtonType="back"
      >
        <PageContainer size="narrow">
          <Flex
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: 'calc(100vh - 70px - 80px - 15px)',
              margin: '30% 0 30% 0'
            }}
            role="region"
          >
            <Flex
              style={{ display: 'flex', justifyContent: 'center' }}
              role="alert"
              aria-live="assertive"
              aria-label="Alerta de círculo neutro"
            >
              <IconShape size="large" use="money-hand" variant="neutral" />
            </Flex>
            <Flex
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                margin: '2rem'
              }}
              role="region"
            >
              <Text role="heading" aria-level={1} aria-label="Informação" color="high" variant="display-small">
                Falta pouco! Finalize seu pedido de empréstimo pelo aplicativo
              </Text>
              <br />
              <Text
                role="note"
                aria-label="Descrição de como continuar a simulação"
                color="medium"
                variant="text-medium"
                weight="regular"
              >
                Para finalizar seu pedido de forma rápida e segura, acesse o aplicativo Stone.
              </Text>
            </Flex>
            <Flex style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                role="button"
                onClick={() => {
                  BlackBird.travelTo('/')
                  analitica.events.finishFeedback.click('início')
                }}
                aria-label="Botão de voltar para o início"
              >
                Voltar para o início
              </Button>
            </Flex>
          </Flex>
        </PageContainer>
      </SharedWithCustomNavbar>
    </Box>
  )
}
