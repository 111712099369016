import React from 'react'
import { getSagaKey } from '@credit-web/common/browser-utils'
import { Box } from '@dlpco/fluid-layout'
import { Button, IconShape, PageContainer, Text } from '@stone-payments/jade'
import jadeTheme from '@stone-payments/jade-design-tokens/cjs/stone'
import styled from 'styled-components'

import { SharedCustomCard } from '~/domains/credit/shared/components/credit-custom-card'
import { SharedWithCustomNavbar } from '~/domains/credit/shared/components/with-custom-navbar'
import { Flex } from '~/domains/platform/design-system'
import { chat } from '~/domains/platform/infra/chat/ports/chat'
import BlackBird from '~/domains/platform/lib/blackbird'
import { appEnv } from '~/lib/helpers'

export type LoanGenericFeedbackProps = {
  title?: string
  description?: string
  action?: () => void
  actionButtonText?: string
  backLink?: string
  navigationBarTitle: string
  hasSagaKey?: boolean
  isPositiveFeedback?: boolean
  onlyActionButton?: boolean
  showButtons?: boolean
}

export const BorderBox = styled(Box)`
  display: flex;
  flex-direction: column;
  border: 1px solid ${jadeTheme.theme.color.border.low};
  border-radius: 0.5rem;
  text-align: left;
  padding: ${jadeTheme.theme.space.medium};
  gap: ${jadeTheme.theme.space['2xsmall']};
  width: 100%;
`

const defaultString = {
  actionButtonText: 'Voltar para o início',
  description: 'Por enquanto, não vai ser possível continuar. Se precisar de ajuda, converse com a gente.',
  title: 'Algo não deu certo por aqui'
}

export const SharedLoanGenericFeedbackCard: React.FC<
  Omit<LoanGenericFeedbackProps, 'navigationBarTitle' | 'backLink'> & { fill?: boolean }
> = React.memo(
  ({
    title,
    description,
    action,
    actionButtonText,
    hasSagaKey = false,
    isPositiveFeedback = false,
    onlyActionButton = false,
    showButtons = true,
    fill = false
  }) => {
    const sagaKey = getSagaKey()
    const centeredContentStyle: React.CSSProperties = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      flex: fill ? 1 : undefined
    }

    /*
     * TODO: Jest-axe error
     * 1. "landmark-no-duplicate-main": Document should not have more than one main landmark (landmark-no-duplicate-main)
     */
    return (
      <SharedCustomCard style={centeredContentStyle}>
        <Flex
          style={centeredContentStyle}
          gap={jadeTheme.theme.space.xlarge}
          height={fill ? 'auto' : 'calc(100vh - 70px - 80px - 40px - 40px)'}
          padding={[jadeTheme.theme.space.medium, 0, 0, 0]}
        >
          <Flex gap={jadeTheme.global.dimension[24]} alignItems="center" justifyContent="center" flexDirection="column">
            <Box>
              {isPositiveFeedback === true ? (
                <IconShape variant="positive" size="large" use="circle-check" />
              ) : (
                <IconShape variant="negative" size="large" use="circle-error" />
              )}
            </Box>
            <Flex gap={jadeTheme.theme.space.medium} textAlign="center" flexDirection="column">
              <Text variant="display-small">{title || defaultString.title}</Text>
              <Text color="medium">{description || defaultString.description}</Text>
            </Flex>

            {showButtons && (
              <Flex gap={jadeTheme.theme.space.xsmall} width="100%" justifyContent="center">
                {onlyActionButton ? (
                  <Button
                    size="medium"
                    style={{ width: '100%' }}
                    onClick={() => {
                      if (action !== undefined) action()
                      else BlackBird.travelTo('/')
                    }}
                  >
                    {actionButtonText || defaultString.actionButtonText}
                  </Button>
                ) : (
                  <>
                    <Button
                      size="medium"
                      onClick={() => {
                        appEnv.isProduction() && chat.open()
                      }}
                    >
                      Conversar com a Stone
                    </Button>
                    <Button
                      size="medium"
                      variant="primary-solid"
                      onClick={() => {
                        if (action !== undefined) action()
                        else BlackBird.travelTo('/')
                      }}
                    >
                      {actionButtonText || defaultString.actionButtonText}
                    </Button>
                  </>
                )}
              </Flex>
            )}
          </Flex>

          {Boolean(sagaKey) && hasSagaKey && (
            <BorderBox>
              <Text variant="text-medium" color="high" weight="medium">
                Código do erro: {sagaKey}
              </Text>
              <Text variant="text-small" color="medium">
                Informe o código para o time de atendimento ao conversar com a Stone.
              </Text>
            </BorderBox>
          )}
        </Flex>
      </SharedCustomCard>
    )
  }
)

export function SharedLoanGenericFeedback(props: LoanGenericFeedbackProps) {
  return (
    <Box
      style={{ height: '100%', backgroundColor: jadeTheme.theme.color.background.surface, justifyContent: 'center' }}
    >
      <SharedWithCustomNavbar
        navigationBarTitle={props.navigationBarTitle}
        navigationBarNavigateButtonTarget={props.backLink || '/'}
        navigationBarNavigateButtonType="close"
        backgroundColor="surface"
      >
        <PageContainer size="narrow">
          <SharedLoanGenericFeedbackCard {...props} />
        </PageContainer>
      </SharedWithCustomNavbar>
    </Box>
  )
}
