import { useCallback, useState } from 'react'
import { useToggle } from 'react-use'
import { Stack } from '@dlpco/fluid-layout'
import { Box, Button, Divider, GroupHeader, IconShape, InputField, ListItem, Text } from '@stone-payments/jade'

import { Flex } from '~/domains/platform/design-system'
import { Choose, If } from '~/domains/platform/lib/utilities-components'
import { applyDecimal } from '~/lib/helpers/credit/loans'
import { stringFormat } from '~/lib/helpers/utils/string-format'

import { type UniversalProposalData } from '../entities'
import analitica from '../helpers/analitica'
import { useLoanConcessionStore } from '../store'

type Props = {
  proposalData: UniversalProposalData
  onSimulate(value: number): void
  inputSimulateIsLoading: boolean
  canSimulate: boolean
}

const { currency, onlyNumbers } = stringFormat
const DECIMALS = 100

export function LoanConcessionSimulationInput({
  proposalData,
  onSimulate,
  inputSimulateIsLoading,
  canSimulate
}: Props) {
  const { maxValue, minValue, inputValue } = proposalData
  const { setIsEditingLoanValue } = useLoanConcessionStore()
  const [showField, toggleField] = useToggle(false)
  const [value, setValue] = useState<number>(inputValue || 0)
  const [error, setError] = useState<string>('')
  const [hasEditedValue, setHasEditedValue] = useState(false)

  const clearError = () => setError('')

  const fixDecimal = (v: number) => Math.floor(v * 100) / 100

  const currencyMinValue = fixDecimal(minValue) * DECIMALS
  const currencyMaxValue = fixDecimal(maxValue) * DECIMALS

  const makeMinValueError = useCallback(() => {
    setError(`O valor mínimo é de ${currency(currencyMinValue, true)}`)
  }, [currencyMinValue])

  const makeMaxValueError = useCallback(() => {
    setError(`O valor máximo é de ${currency(currencyMaxValue, true)}`)
  }, [currencyMaxValue])

  const handleInput = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const normalizedValue = +onlyNumbers(e.currentTarget.value)
      const value = normalizedValue / DECIMALS
      setIsEditingLoanValue(true)
      setHasEditedValue(true)
      setValue(value)
      clearError()

      if (value < minValue) makeMinValueError()
      if (value > maxValue) makeMaxValueError()
    },
    [maxValue, minValue, makeMaxValueError, makeMinValueError, setIsEditingLoanValue]
  )

  const handleSubmit = useCallback(() => {
    if (showField) {
      if (value < minValue) {
        makeMinValueError()
        return
      }

      if (value > maxValue) {
        makeMaxValueError()
        return
      }
      clearError()
      setIsEditingLoanValue(false)
      onSimulate(value)
    } else {
      analitica.events.simulation.click('alterar valor')
    }
    toggleField()
  }, [
    value,
    onSimulate,
    makeMaxValueError,
    makeMinValueError,
    maxValue,
    minValue,
    toggleField,
    setIsEditingLoanValue,
    showField
  ])

  return (
    <Box css={{ width: '100%' }}>
      <Stack>
        <If condition={hasEditedValue || showField}>
          <Flex>
            <ListItem
              content={
                <ListItem.Content
                  label="Limite de crédito disponível"
                  type="display"
                  value={currency(applyDecimal(maxValue))}
                />
              }
              leading={<IconShape size="medium" use="money-hand" variant="brand" />}
            />
          </Flex>
          <Divider />
        </If>
        <Choose>
          <Choose.When condition={showField}>
            <GroupHeader variant="heading" title="Quanto você precisa?" />

            <Flex padding="var(--global-space-100) var(--global-space-250) 0">
              <InputField
                leadingItem={{
                  content: 'R$',
                  type: 'text'
                }}
                label="informe o valor"
                hideLabel
                supportText={error ? error : `Simule até ${currency(applyDecimal(maxValue))}`}
                onChange={handleInput}
                value={currency(value * DECIMALS, true)}
                error={Boolean(error)}
              />
            </Flex>
          </Choose.When>
          <Choose.Otherwise>
            <Flex
              padding={`var(--global-space-250) var(--global-space-250) ${
                canSimulate ? '0' : 'var(--global-space-250)'
              }`}
              flexDirection="column"
            >
              <Text variant="text-small" color="medium" weight="medium">
                Você quer pedir
              </Text>
              <Text variant="display-small" color="positive">
                {currency(applyDecimal(value))}
              </Text>
            </Flex>
          </Choose.Otherwise>
        </Choose>

        <If condition={canSimulate}>
          <Flex padding="var(--global-space-250)">
            <Button
              disabled={Boolean(error)}
              onClick={handleSubmit}
              loading={inputSimulateIsLoading}
              css={{ width: '100%' }}
            >
              Alterar valor
            </Button>
          </Flex>
        </If>
      </Stack>
    </Box>
  )
}
