import { httpClientCredit } from '~/domains/credit/shared/infra/http-client-credit'
import { useQueryRequest } from '~/ui/hooks/utils'
import { type UseQueryRequestOptions } from '~/ui/hooks/utils/service/use-query-request'

import { type StatementResponse } from '../entities'

interface UseStatementListProps {
  loanId: string
  installmentNumber?: string
  pageNumber?: number
}

function fetchStatementList({ installmentNumber, loanId, pageNumber = 1 }: UseStatementListProps) {
  return httpClientCredit<StatementResponse>({
    url: `/experience-bff-service/management/api/web/v1/loans/${loanId}/installments/${installmentNumber}/statements`,
    method: 'GET',
    params: { PageNumber: pageNumber, PageSize: 2 }
  })
}

export function useStatementList(
  { installmentNumber, loanId, pageNumber = 1 }: UseStatementListProps,
  queryOptions?: UseQueryRequestOptions<StatementResponse>
) {
  const { data: response, ...restQuery } = useQueryRequest<StatementResponse>(
    ['useStatementList', installmentNumber, pageNumber],
    () => fetchStatementList({ installmentNumber, loanId, pageNumber }),
    {
      ...queryOptions,
      enabled: Boolean(installmentNumber && pageNumber)
    }
  )

  const data = response?.data.content
  return {
    data,
    ...restQuery
  }
}
