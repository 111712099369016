import { analiticaInstance } from 'shared/analitica'

type OnSaleSimulateProps = {
  cardBrand: string
  paymentMethod: string
  installmentsNumber: string
  antecipation: string
  simulationType: string
}

const antecipationLabel = {
  none: 'sem antecipação',
  spot: 'antecipação pontual',
  automatic: 'antecipação automática diária'
}

const simulationLabel = {
  merchant: 'quero cobrar',
  cardholder: 'quero receber'
}

const saleSimulatorInstance = analiticaInstance.eventFactory('Acquirer sale simulator', {
  simulator: {
    /**
     * @analitica When customer clicks on simulate sales
     */
    onClickSimulate: ({
      cardBrand,
      paymentMethod,
      installmentsNumber,
      antecipation,
      simulationType
    }: OnSaleSimulateProps) => ({
      label: 'sales - simulate sales selected',
      metadata: {
        'property type': 'Event',
        cardBrand,
        paymentMethod,
        installmentsNumber,
        antecipation: antecipationLabel[antecipation as keyof typeof antecipationLabel],
        simulationType: simulationLabel[simulationType as keyof typeof simulationLabel]
      }
    }),
    /**
     * @analitica When clicks on antecipation tooltip
     */
    onTooltipSimulate: () => ({
      label: 'sales - simulate sales antecipacion tooltip clicked',
      metadata: {
        'property type': 'Event'
      }
    }),
    /**
     * @analitica When clicks on second antecipation tooltip
     */
    onSecondTooltipSimulate: () => ({
      label: 'sales - simulate sales second anticipacion tooltip clicked',
      metadata: {
        'property type': 'Event'
      }
    })
  }
})

export { saleSimulatorInstance }
