import { type PropsWithChildren, createContext, useContext, useState } from 'react'

import { uuid } from '~/domains/platform/lib/crypto'

import { For } from '../../lib/utilities-components'

import { Toast } from './toast'

type ProviderToast = {
  id: string
  message: string
  type: 'success' | 'info' | 'error'
}

export const Context = createContext<{
  addToast: (toast: Omit<ProviderToast, 'id'>) => void
}>({
  addToast() {}
})

export function Provider({ children }: PropsWithChildren<any>) {
  const [toasts, setToasts] = useState<ProviderToast[]>([])
  return (
    <Context.Provider
      value={{
        addToast(toast) {
          setToasts([...toasts, { id: uuid(), ...toast }])
        }
      }}
    >
      {children}
      <For
        of={toasts}
        render={(toast, index) => {
          return <Toast {...toast} key={index} remove={() => setToasts(toasts.filter(t => t.id !== toast.id))} />
        }}
      />
    </Context.Provider>
  )
}

/**
 * @deprecated Use `useToast` from `@stone-payments/jade` instead.
 */
export function useToast() {
  const { addToast } = useContext(Context)
  return { addToast }
}
