import { authenticator } from '~/domains/platform/core/authenticator/authenticator'
import { Bucket } from '~/domains/platform/lib/bucket'
import { clientSideOnly } from '~/domains/platform/lib/client-side-only'
import { isomorphicCookies } from '~/lib/helpers'

import { httpClientStore } from './http-client-store'
import { type ChallengerRequired, type HttpClientResponse } from './types'

export function getDefaultHeaders() {
  const deviceGeneratedId = clientSideOnly(() =>
    Bucket.local.get('device::generated::id')
  )
  const platformId = clientSideOnly(() => Bucket.local.get('platform::id'))
  const { token } = isomorphicCookies.getAll()

  const headers = new Headers()
  headers.set('Content-Type', 'application/json')
  headers.set('Authorization', `Bearer ${token}`)
  if (deviceGeneratedId) {
    headers.set('device-generated-id', deviceGeneratedId)
  }
  if (platformId) {
    headers.set('platform-id', platformId)
  }

  return headers
}

type EncryptPayloadParams = {
  challengeId: string
  passwordType: 'otp' | 'pin' | 'password'
  password: string
}

export async function encryptPayload({
  challengeId,
  passwordType,
  password
}: EncryptPayloadParams) {
  const challengeAnswerPayload = {
    challenge_id: challengeId,
    totp: passwordType === 'otp' ? password : null,
    pin: passwordType === 'pin' ? password : null,
    login_password: passwordType === 'password' ? password : null
  } as const

  const challengeAnswer = await authenticator.encryptWithPublickey(
    challengeAnswerPayload
  )
  return challengeAnswer
}

export async function handlePinChallenge(
  url: URL,
  options: RequestInit,
  challenge: ChallengerRequired
) {
  const { setRequest, isOpen, openModal, closeModal, setErrorMessage } =
    httpClientStore.getState()

  return new Promise<Response>(resolve => {
    // @ts-expect-error - resolve returns a void, but it should return a Response
    setRequest(async (challengerType, answer) => {
      const challengeAnswer = await encryptPayload({
        challengeId: challenge.id,
        passwordType: challenge.required_types[0],
        password: answer
      })

      const headers = new Headers(options.headers)
      headers.set('X-Stone-Challenge-Solution', challengeAnswer)

      const response = await fetch(url, { ...options, headers })

      if (!response.ok) {
        const error = await response.json()
        if (
          response.status === 403 &&
          error.type === 'srn:error:bad_challenge_solution'
        ) {
          const remainingAttempts = error?.details?.remainingAttempts
          const errorMessage = remainingAttempts
            ? `Você errou a senha e só restam ${remainingAttempts} tentativas.`
            : 'Você errou a senha.'
          return setErrorMessage(errorMessage)
        }
      }

      if (response.ok) {
        closeModal()
        return resolve(response)
      }
    })

    if (!isOpen) {
      openModal(challenge.required_types[0])
    }
  })
}

export function isHttpClientResponse<T>(
  value: unknown
): value is HttpClientResponse<T> {
  return (
    typeof value === 'object' &&
    value !== null &&
    'data' in value &&
    'status' in value &&
    typeof (value as HttpClientResponse<T>).status === 'number'
  )
}
