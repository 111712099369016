import { type ChangeEvent, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { GroupHeader, Icon, IconShape, InputField, ListItem, Tag, Text } from '@stone-payments/jade'
import jadeTheme from '@stone-payments/jade-design-tokens/cjs/stone'
import { analiticaInstance } from 'shared/analitica'
// eslint-disable-next-line import/no-unresolved
import { getAllExperiment } from 'shared/experiment'

import { Box } from '~/domains/platform/design-system'
import { type Entity, type Person, type Subject } from '~/domains/platform/infra/deus-ex-machina/types'
import BlackBird, { type Routes } from '~/domains/platform/lib/blackbird'
import { Choose, For, If } from '~/domains/platform/lib/utilities-components'
import { entitiesListFactory } from '~/lib/helpers/auth'
import { isomorphicCookies, stringFormat } from '~/lib/helpers/utils'

import analitica from '../analitica'
export function sidebarSortSwitchAccountByPriority(arr: Person[]) {
  return arr.sort((a, b) => (a.documentType === 'CPF' ? -1 : 1) - (b.documentType === 'CPF' ? -1 : 1))
}

export function SidebarActionButtonSwitchAccount({
  entity,
  callback
}: {
  entity: Entity<'acquirer' | 'banking'>
  callback: () => void
}) {
  function handleClick() {
    analitica.events.profileMenu.click()
    callback()
  }

  return (
    <Box py={jadeTheme.global.space[50]}>
      <ListItem
        navigation
        content={
          <ListItem.Content
            description={stringFormat.document(entity.document)}
            label={entity.displayName}
            clampLine={['label', 'description']}
          />
        }
        onClick={handleClick}
      />
    </Box>
  )
}

export function SidebarSwitchAccount({
  entity,
  subject,
  callback
}: {
  entity: Entity<'acquirer' | 'banking'>
  subject: Subject<'stone_account_resources'>
  callback: () => void
}) {
  const { users, organizations } = subject

  const accountFilter = 'signin_show_account_filter'
  const { asPath } = useRouter()

  const query = BlackBird.getQuery()
  const route = BlackBird.getPathname()

  const [inputValue, setInputValue] = useState<string>('')
  const [isAccountFilter, setIsAccountFilter] = useState<boolean>(false)

  const entities = entitiesListFactory({ subject, users, organizations })
  useEffect(() => {
    const fetchStoreConfig = async () => {
      if (!entity?.document || !subject?.id) return

      const experimentData = await getAllExperiment(subject.id, true, true, {
        swh_account_document: entity?.document
      })
      if (experimentData?.[accountFilter]?.key === 'on') {
        setIsAccountFilter(true)
      }
    }
    fetchStoreConfig()
  }, [entity, subject?.id])
  const handleGoToSwitchAccount = (item: Person) => {
    return function eventCallback() {
      BlackBird.replace({ pathname: route, query: { ...query } }, asPath as Routes, {
        shallow: true
      })

      isomorphicCookies.set('identityId', item.id)

      analiticaInstance.updateMetadata({ identity: item.id })

      callback()
    }
  }
  const [filteredResults, setFilteredResults] = useState(sidebarSortSwitchAccountByPriority(entities))

  const normalizeText = (text: string) =>
    text
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\W/g, '')

  function handleSearch(searchTerm: string) {
    const filtered = sidebarSortSwitchAccountByPriority(entities).filter(item => {
      return (
        normalizeText(item.displayName).indexOf(normalizeText(searchTerm)) !== -1 ||
        normalizeText(item.document).indexOf(normalizeText(searchTerm)) !== -1
      )
    })

    setFilteredResults(filtered)
  }

  const clearSearch = () => {
    setInputValue('')
    handleSearch('')
  }
  function highlightText(text: string, searchTerm: string) {
    if (!searchTerm) return text

    const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'))
    return parts.map((part, index) =>
      part.toLowerCase() === searchTerm.toLowerCase() ? <strong key={index}>{part}</strong> : part
    )
  }
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setInputValue(value)
    handleSearch(value)
  }
  const accountsList = () => (
    <For
      of={filteredResults}
      render={(item, index) => (
        <ListItem
          key={index}
          data-testid={`entity-group:${item.id}`}
          leading={
            <IconShape
              size="small"
              use={stringFormat.documentType(item.document || '') === 'CPF' ? 'person' : 'shop'}
              variant="neutral"
            />
          }
          content={
            <ListItem.Content
              description={highlightText(stringFormat.document(item.document), inputValue) as string}
              label={highlightText(item.displayName, inputValue) as string}
              clampLine={['label', 'description']}
            />
          }
          onClick={handleGoToSwitchAccount(item)}
          navigation={item.id === entity.id ? false : true}
          trailing={
            !item.roles.banking && !item.roles.acquirer ? (
              <Tag size="small" variant="neutral">
                Pendente
              </Tag>
            ) : item.id === entity.id ? (
              <Tag size="small" variant="positive" solid>
                Ativa
              </Tag>
            ) : null
          }
          variant={item.id === entity.id ? 'active' : 'neutral'}
        />
      )}
    />
  )
  return (
    <>
      <Box py={jadeTheme.global.space[50]}>
        <ListItem
          content={<ListItem.Content label="Voltar" />}
          leading={<Icon size="medium" use="arrow-left" />}
          onClick={() => callback()}
          data-testid="icon-button-container"
        />
      </Box>
      <GroupHeader title="Selecione a conta" />
      <If condition={isAccountFilter}>
        <Box padding={'8px 10px 10px'}>
          <InputField
            label={''}
            value={inputValue}
            placeholder={'Buscar Conta'}
            size={'medium'}
            hideLabel={true}
            onChange={onChange}
            leadingItem={{
              content: 'action-search',
              type: 'icon'
            }}
            trailingItem={{
              content: 'close',
              type: 'icon'
            }}
            onTrailingItemClick={clearSearch}
          />
        </Box>
      </If>

      <Choose>
        <Choose.When condition={Object.keys(filteredResults)?.length === 0 && isAccountFilter}>
          <Box alignItems="center" textAlign="center" pb={20} pt={20}>
            <IconShape
              size="medium"
              use="action-search"
              variant="neutral"
              style={{ display: 'inline-flex', flexShrink: 0 }}
            />
          </Box>
          <Box alignItems="center" textAlign="center">
            <Text variant="heading-small" color="low">
              Nenhum resultado encontrado
            </Text>
            <Text variant="text-medium" color="low">
              Corrija ou tente uma nova busca
            </Text>
          </Box>
        </Choose.When>
        <Choose.Otherwise>{accountsList()}</Choose.Otherwise>
      </Choose>
    </>
  )
}
