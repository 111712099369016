import { useState } from 'react'
import { type AlertProps, Alert } from '@stone-payments/jade'

import { chat } from '~/domains/platform/infra/chat/ports/chat'
import { For } from '~/domains/platform/lib/utilities-components'

import { type LoanDetails } from '../entities'

import { OverdueConsequencesDrawer } from './loan-details-jade-drawers'

export function LoanDetailsJadeNotifications({ notifications }: { notifications: LoanDetails['notifications'] }) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <For
        of={notifications}
        render={(item, index) => {
          const getAction = () => {
            if (item.button) {
              if (item.button?.type === 'overdue_drawer') return () => setIsOpen(true)
              if (item.button?.type === 'link') return () => window.open(item.button?.action)
              if (item.button?.type === 'contact_us') return () => chat.open('LOANS')
            }
          }

          return (
            <Alert
              key={'notification' + index}
              variant={item.variant as AlertProps['variant']}
              title={item.title}
              description={item.subtitle}
              showIcon
              link={item.button ? { text: item.button?.text || '', onClick: getAction() } : undefined}
            />
          )
        }}
      />
      <OverdueConsequencesDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  )
}
