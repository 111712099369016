import { useEffect, useState } from 'react'
import { useEffectOnce } from 'react-use'
import { type MoveToStep } from 'modules/credit/common/flow-stepper/src/types'

import { stringFormat } from '~/lib/helpers'
import {
  type UseQueryRequestOptions,
  useMutationRequest,
  useQueryRequest
} from '~/ui/hooks/utils/service/use-query-request'
import { useViewSize } from '~/ui/hooks/utils/ui/use-view-size'

import { type UseCreditoffersReturn } from '../../shared'
import {
  type NegotiationsProposal,
  type NegotiationsSimulationResult,
  type UniversalProposalData,
  LoanConcessionSteps
} from '../entities'
import analitica from '../helpers/analitica'
import { formatSimulationData, hasProposalData } from '../helpers/loan-concession-simulation-data'
import { createProposal, loanConcessionSimulate, offerSimulate } from '../services/proposal'
import { useLoanConcessionStore } from '../store'

const { currency } = stringFormat

type useLoanConcessionSimulationProps = {
  creditOffer: UseCreditoffersReturn
  amount: number
  proposalAvailable?: NegotiationsProposal
  proposalProcessing?: NegotiationsProposal
}

export function useLoanConcessionSimulation(
  { creditOffer, amount, proposalAvailable, proposalProcessing }: useLoanConcessionSimulationProps,
  queryOptions?: UseQueryRequestOptions<UniversalProposalData>
) {
  const hasNoProposal = Boolean(!proposalAvailable) && Boolean(!proposalProcessing)
  const simulateProps = { creditOffer: creditOffer?.offer, requestedAmount: amount }

  const { data: response, ...restQuery } = useQueryRequest<UniversalProposalData>(
    ['useLoanConcessionSimulation'],
    () => offerSimulate(simulateProps),
    { ...queryOptions, enabled: hasNoProposal }
  )

  const proposalData = hasNoProposal
    ? (formatSimulationData({
        creditOffer,
        simulationProposal: response?.data as unknown as NegotiationsSimulationResult
      }) as UniversalProposalData)
    : formatSimulationData({ creditOffer, proposal: proposalAvailable })
  return {
    data: proposalData,
    ...restQuery,
    canSimulate: hasNoProposal || proposalAvailable?.origin?.userType === 'Customer'
  }
}

export function useLoanConcessionSimulationActions({
  creditOffer,
  moveToStep,
  proposalAvailable,
  proposalProcessing
}: {
  creditOffer: UseCreditoffersReturn
  moveToStep: MoveToStep
  proposalAvailable?: NegotiationsProposal
  proposalProcessing?: NegotiationsProposal
}) {
  const { isViewSmall } = useViewSize()
  const { handleError, setRequestedAmount } = useLoanConcessionStore()
  const [currentProposalId, setCurrentProposalId] = useState<string | undefined>(proposalAvailable?.id)
  const [proposalSimulationData, setLoanConcessionSimulationData] = useState<UniversalProposalData>()

  const {
    data: proposal,
    isLoading: isloadingSimulate,
    isError,
    canSimulate
  } = useLoanConcessionSimulation({
    creditOffer,
    amount: creditOffer?.maxCreditValue || 0,
    proposalAvailable,
    proposalProcessing
  })

  useEffectOnce(() => {
    analitica.events.simulation.view({
      offerId: creditOffer?.offer?.id,
      offerAmount: currency((creditOffer.maxCreditValue || 0) * 100, true)
    })
  })

  const {
    mutate,
    isLoading: inputSimulateIsLoading,
    isError: isMutationError
  } = useMutationRequest(['useLoanConcesionSimulate'], loanConcessionSimulate, {
    onSuccess: ({ data }) => {
      setCurrentProposalId(undefined)
      setLoanConcessionSimulationData(formatSimulationData({ creditOffer, simulationProposal: data }))
    }
  })

  const proposalData = proposalSimulationData ? { ...proposalSimulationData } : proposal
  const hasToCreateProposal = currentProposalId !== proposalAvailable?.id || currentProposalId === undefined

  const onSimulate = async (amount: number) => {
    mutate({
      creditOffer: creditOffer?.offer,
      requestedAmount: amount,
      proposalId: proposalData?.proposalId
    })
    setRequestedAmount(amount || 0)
    setCurrentProposalId(proposalData?.proposalId)
  }

  const onContinue = async () => {
    const shouldCreateNewProposal = hasToCreateProposal && creditOffer.offer
    if (shouldCreateNewProposal) {
      const createProposalProps = {
        offerId: creditOffer?.offer?.id || '',
        requestedAmount: proposalData?.creditAmount || 0,
        numberOfInstallments: proposalData?.numberOfInstallments || 0
      }
      const { data: savedProposalInfo } = await createProposal(createProposalProps)
      setCurrentProposalId(savedProposalInfo?.id)
    }
    setRequestedAmount(
      shouldCreateNewProposal ? proposalData?.creditAmount || 0 : proposalAvailable?.loanConcession.requestedAmount || 0
    )
    moveToStep({ stepKey: LoanConcessionSteps.FEEDBACK, direction: 'foward' })
  }

  useEffect(() => handleError([isError, isMutationError]), [isError, isMutationError, handleError])

  return {
    isViewSmall,
    proposalData,
    isLoading: !hasProposalData(proposalData) || isloadingSimulate,
    inputSimulateIsLoading,
    canSimulate,
    onSimulate,
    onContinue
  }
}
