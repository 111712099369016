import { useToggle } from 'react-use'
import { formatDate } from '@credit-web/common/date-utils'
import { rateUtils } from '@credit-web/common/number-utils'
import { Stack } from '@dlpco/fluid-layout'
import { Box, Button, Divider, GroupHeader, Icon, ListItem, Text, Tooltip } from '@stone-payments/jade'
import { type MoveToStep } from 'modules/credit/common/flow-stepper/src/types'

import { Box as BoxDiv, Flex } from '~/domains/platform/design-system'
import { applyDecimal } from '~/lib/helpers/credit/loans'
import { stringFormat } from '~/lib/helpers/utils/string-format'

import { type UniversalProposalData, LoanConcessionSteps } from '../entities'
import analitica from '../helpers/analitica'
import { useLoanConcessionStore } from '../store'

type Props = {
  moveToStep: MoveToStep
  proposalData: UniversalProposalData
  onContinue?: () => void
  inputSimulateIsLoading: boolean
}

export function LoanConcessionSimulationContent({
  moveToStep,
  proposalData,
  onContinue,
  inputSimulateIsLoading
}: Props) {
  const [isLoading, toggleLoading] = useToggle(false)

  const { isEditingLoanValue } = useLoanConcessionStore()

  const { currency } = stringFormat

  const handleClick = async () => {
    toggleLoading()
    analitica.events.simulation.click('continuar')
    onContinue && (await onContinue())
    moveToStep({ stepKey: LoanConcessionSteps.FEEDBACK, direction: 'foward' })
  }

  return (
    <Box hasPadding css={{ width: '100%' }}>
      <Stack space="var(--global-space-250)">
        <BoxDiv>
          <GroupHeader title="Detalhes do pagamento" />
          <ListItem
            content={
              <Text color="medium" variant="text-medium" weight="medium">
                Total a pagar
              </Text>
            }
            trailing={
              <Text color="high" weight="semibold">
                {currency(applyDecimal(proposalData?.totalEstimatedAmountToBePaid))}
              </Text>
            }
          />
          <ListItem
            content={
              <Flex justifyContent="space-between" alignItems="center" gap="var(--global-space-50)">
                <Text color="medium" variant="text-medium" weight="medium">
                  Pagamento automático com
                </Text>
                <Tooltip
                  placement="top-start"
                  text="O pagamento é automático e direciona parte dos seus recebimentos todos os dias até quitar a parcela do mês."
                  id="tooltip-payment-info"
                  aria-label="O pagamento é automático e direciona parte dos seus recebimentos todos os dias até quitar a parcela do mês."
                  trigger={<Icon size="small" use="circle-info" aria-describedby="tooltip-payment-info" />}
                />
              </Flex>
            }
            trailing={
              <Text color="high" weight="semibold">
                {`${rateUtils.formatToLimitedDecimalPlaces(proposalData.retentionRate)}% dos recebimentos diários`}
              </Text>
            }
          />
          {proposalData.numberOfInstallments && proposalData.installmentValue && (
            <ListItem
              content={
                <Text color="medium" variant="text-medium" weight="medium">
                  Parcelado em
                </Text>
              }
              trailing={
                <Text color="high" weight="semibold">
                  {`${proposalData.numberOfInstallments}x de ${currency(applyDecimal(proposalData.installmentValue))}`}
                </Text>
              }
            />
          )}
          <Divider />

          <GroupHeader title="Taxas e vencimento do contrato" />

          <ListItem
            content={
              <Text color="medium" variant="text-medium" weight="medium">
                Contrato vence em
              </Text>
            }
            trailing={
              <Text color="high" weight="semibold">
                {formatDate(proposalData?.contractDueDate)}
              </Text>
            }
          />
          <ListItem
            content={
              <Text color="medium" variant="text-medium" weight="medium">
                Juros ao mês
              </Text>
            }
            trailing={
              <Text color="high" weight="semibold">
                {`${rateUtils.formatToLimitedDecimalPlaces(proposalData.interestRate)}%`}
              </Text>
            }
          />

          <ListItem
            content={
              <Flex justifyContent="space-between" alignItems="center" gap="var(--global-space-50)">
                <Text color="medium" variant="text-medium" weight="medium">
                  IOF financiado
                </Text>
                <Tooltip
                  placement="top-start"
                  text="Imposto federal aplicado sobre operações financeiras."
                  id="tooltip-iof"
                  aria-label="Imposto federal aplicado sobre operações financeiras."
                  trigger={<Icon size="small" use="circle-info" aria-describedby="tooltip-iof" />}
                />
              </Flex>
            }
            trailing={
              <Text color="high" weight="semibold">
                {currency(proposalData?.taxAmount * 100)}
              </Text>
            }
          />

          <ListItem
            content={
              <Text color="medium" variant="text-medium" weight="medium">
                Juros ao ano
              </Text>
            }
            trailing={
              <Text color="high" weight="semibold">
                {`${rateUtils.formatToLimitedDecimalPlaces(proposalData.annualInterestRate)}%`}
              </Text>
            }
          />
          <ListItem
            content={
              <Flex justifyContent="space-between" alignItems="center" gap="var(--global-space-50)">
                <Text color="medium" variant="text-medium" weight="medium">
                  Custo Efetivo Total (CET)
                </Text>
                <Tooltip
                  placement="top-start"
                  text="O CET é a soma de todos os custos da operação, ou seja, juros e IOF."
                  id="tooltip-cet"
                  aria-label="O CET é a soma de todos os custos da operação, ou seja, juros e IOF."
                  trigger={<Icon size="small" use="circle-info" aria-describedby="tooltip-cet" />}
                />
              </Flex>
            }
            trailing={
              <Text color="high" weight="semibold">
                {`${rateUtils.formatToLimitedDecimalPlaces(proposalData.annualTotalEffectiveCostRate)}% ao ano`}
              </Text>
            }
          />
        </BoxDiv>
        <BoxDiv>
          <Button
            variant="primary-solid"
            css={{ width: '100%', marginTop: 'var(--global-space-250)' }}
            loading={isLoading}
            onClick={handleClick}
            disabled={isEditingLoanValue || inputSimulateIsLoading}
            aria-label="Continuar pedido"
          >
            Continuar pedido
          </Button>
        </BoxDiv>
      </Stack>
    </Box>
  )
}
