import { type moveToStepProps } from '../types'

type EventKey = string | symbol
type EventHandler<T = any> = (payload: T) => void
type EventMap = Record<EventKey, EventHandler>

interface EventBus<T extends EventMap> {
  on<Key extends keyof T>(key: Key, handler: T[Key], identifier: string): void
  emit<Key extends keyof T>(key: Key, ...payload: Parameters<T[Key]>): void
  clearBus(): void
}

type HandlerWithidentifier<T> = {
  handler: T
  identifier: string
}

type Bus<E> = Record<keyof E, HandlerWithidentifier<E[keyof E]>[]>

function eventBus<E extends EventMap>(): EventBus<E> {
  const bus: Partial<Bus<E>> = {}

  const on: EventBus<E>['on'] = (key, handler, identifier) => {
    if (bus[key] === undefined) {
      bus[key] = []
    }

    const handlerExistsIndex = bus[key]?.findIndex(
      existingHandler => existingHandler.handler.name === handler.name && existingHandler.identifier === identifier
    )

    if (handlerExistsIndex !== -1) {
      bus[key][handlerExistsIndex] = { handler, identifier }
      return
    }

    bus[key]?.push({ handler, identifier })
  }

  const emit: EventBus<E>['emit'] = (key, payload) => {
    bus[key]?.forEach(({ handler }) => {
      handler(payload)
    })
  }

  const clearBus: EventBus<E>['clearBus'] = () => {
    Object.keys(bus).forEach(key => {
      bus[key as keyof Bus<E>] = []
    })
  }

  return { on, emit, clearBus }
}

export const flowStepperEventBus = eventBus<{
  triggerMoveState: (payload: moveToStepProps) => void
  triggerHistoryBack: () => void
}>()
