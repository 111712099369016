import { type FlagProps } from '../../types/mock-flags-types'

import { loanDetailsFlag, loanInstallmentStatementsFlag, loanInstalmentDetailsFlag } from './loans/loan-details'
import { loanSpotOfferFlags } from './loans/spot-offer'
import { creditHomeFlags } from './home'

const handlers: FlagProps[] = [
  ...loanSpotOfferFlags,
  ...creditHomeFlags,
  loanDetailsFlag,
  loanInstalmentDetailsFlag,
  loanInstallmentStatementsFlag
]

export const creditHandlers = handlers

export const creditMockFlags: { domain: string; mocks: FlagProps[] } = {
  domain: 'Crédito',
  mocks: creditHandlers
}
