import { type FormEvent, useState } from 'react'
import { InputPin, Modal } from '@stone-payments/jade'

import BlackBird from '~/domains/platform/lib/blackbird'

import { useHttpClient } from './http-client-provider'

export const BAD_CHALLENGE_SOLUTION_ERROR = 'srn:error:bad_challenge_solution'
export const CHALLENGE_LOCKED = 'srn:error:challenge_locked'

export function HttpClientModal() {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [answer, setAnswer] = useState('')

  const isOpen = useHttpClient(state => state.isOpen)
  const challengerType = useHttpClient(state => state.challengerType)
  const request = useHttpClient(state => state.request)
  const errorMessage = useHttpClient(state => state.errorMessage)

  async function handleSubmit(event: FormEvent) {
    event.preventDefault()
    setIsSubmitting(true)

    if (!(request && challengerType)) {
      setIsSubmitting(false)
      return
    }

    try {
      await request(challengerType, answer)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Modal
      open={isOpen}
      // TODO: escondendo o botão de fechar por enquanto
      hideCloseButton
      toggle={() => {}}
      title="Digite a senha de 6 números"
      actionsOrientation="horizontal"
      actions={[
        {
          text: 'Confirmar',
          type: 'submit',
          form: 'pin-form',
          variant: 'primary-solid',
          disabled: !answer || answer.length < 6,
          loading: isSubmitting
        },
        {
          text: 'Esqueci minha senha',
          onClick: () => {
            BlackBird.travelTo('/troca-de-pin')
          },
          variant: 'neutral-subtle',
          loading: isSubmitting
        }
      ]}
      zIndex={999999999999}
    >
      <form id="pin-form" onSubmit={handleSubmit}>
        <InputPin
          size={6}
          error={!!errorMessage}
          supportText={
            errorMessage ?? 'Use sua senha de 6 dígitos para continuar'
          }
          onChange={setAnswer}
          masked
          inputMode="numeric"
          pattern={undefined}
        />
      </form>
    </Modal>
  )
}
