import { useQueryClient } from '@tanstack/react-query'

import { type CreditPaging } from '~/domains/credit/shared/entities'
import { stringFormat } from '~/lib/helpers/utils/string-format'
import { type IntegrationsResponse } from '~/lib/types'

import { type BffLoanHome, type OpportunitySectionElement } from '../../loan-home/entities'
import { type CreditOffer } from '../../shared/entities'
import { minMaxCreditValue } from '../../shared/hooks/use-credit-offers'
type Props = {
  offerId: string
  offerOrigin: 'useLoanHome' | 'useGoToOffers'
}

export function useLoanConcessionModal({ offerId, offerOrigin }: Props) {
  const { currency } = stringFormat
  const queryClient = useQueryClient()
  if (offerOrigin === 'useLoanHome') {
    const { data } = queryClient.getQueryData([offerOrigin]) as IntegrationsResponse<BffLoanHome>
    const opportunities =
      data?.content?.sections?.find(({ id }: { id: string }) => id === 'opportunities')?.elements ?? []

    const opportunity = (opportunities as unknown as OpportunitySectionElement[]).find(
      ({ offerId: id }: { offerId: string }) => id === offerId
    )
    return { offerId, offerAmount: currency(opportunity?.amount, true) }
  } else if (offerOrigin === 'useGoToOffers' && Boolean(offerId)) {
    const { data } = queryClient.getQueryData([offerOrigin]) as IntegrationsResponse<CreditPaging<CreditOffer[]>>
    const selectedOffer: CreditOffer | undefined = data?.items.find(({ id }) => id === offerId)
    const { maxCreditValue } = minMaxCreditValue({ roles: selectedOffer?.roles || [] })

    return { offerId, offerAmount: currency((maxCreditValue || 0) * 100, true) }
  }

  return { offerId }
}
