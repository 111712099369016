import { createStore } from 'zustand'

type ChallengerType = 'pin' | 'otp' | 'password'

type HttpClientState = {
  isOpen: boolean
  request:
    | ((challengerType: ChallengerType, answer: string) => Promise<Response>)
    | null
  challengerType: ChallengerType | null
  errorMessage: string | null
}

type HttpClientActions = {
  setRequest: (
    req: (challengerType: ChallengerType, answer: string) => Promise<Response>
  ) => void
  openModal: (challengerType: ChallengerType) => void
  closeModal: () => void
  setErrorMessage: (message: string) => void
}

export type HttpClientStore = HttpClientState & HttpClientActions

export const defaultHttpClientStore: HttpClientState = {
  isOpen: false,
  request: null,
  challengerType: null,
  errorMessage: null
}

export function createHttpClientStore(
  initialState: HttpClientState = defaultHttpClientStore
) {
  return createStore<HttpClientStore>()(set => ({
    ...initialState,
    setRequest: req => set(() => ({ request: req })),
    openModal: challengerType => set(() => ({ isOpen: true, challengerType })),
    closeModal: () =>
      set(() => ({
        isOpen: false,
        challengerType: null,
        request: null,
        errorMessage: null
      })),
    setErrorMessage: message => set(() => ({ errorMessage: message }))
  }))
}

export const httpClientStore = createHttpClientStore()
