import { type FlagProps } from '~/scaffold/mocks/types/mock-flags-types'

import { loanDetails } from './loan-details.mock'
import { loanInstallmentDetails } from './loan-installment-details.mock'
import { loanInstallmentStatementsDetails } from './loan-installment-details-statements.mock'

export const loanDetailsFlag: FlagProps = {
  name: 'loan-details',
  groupName: 'Acompanhamento de Capital de Giro/Empréstimo',
  scenarios: [
    'Empréstimo com renegociação em andamento /  empréstimo com atraso múltiplo',
    'Empréstimo com renegociação que não aconteceu',
    'Empréstimo renegociado',
    'Empréstimo quitado',
    'Empréstimo aberto com pagamento em dia',
    'Empréstimo em dia sem nenhum pagamento',
    'Empréstimo com atraso multiplo',
    'Empréstimo com uma parcela em atraso',
    'Empréstimo com uma parcela em quitada'
  ],
  initialScenario: 'Empréstimo aberto com pagamento em dia',
  handlers: loanDetails
}

export const loanInstalmentDetailsFlag: FlagProps = {
  name: 'loan-installment-details',
  groupName: 'Acompanhamento da parcela de Capital de Giro/Empréstimo',
  scenarios: [
    'Parcela futura sem pagamento',
    'Parcela futura com pagamento',
    'Parcela aberta com pagamento',
    'Parcela aberta sem pagamento',
    'Parcela aberta com vence em breve',
    'Parcela atrasada',
    'Parcela quitada'
  ],
  initialScenario: 'Parcela futura com pagamento',
  handlers: loanInstallmentDetails
}

export const loanInstallmentStatementsFlag: FlagProps = {
  name: 'loan-installment-statements',
  groupName: 'Extrato da parcela de Capital de Giro/Empréstimo',
  scenarios: ['Variação 1', 'Variação 2', 'Variação 3'],
  initialScenario: 'Variação 1',
  handlers: loanInstallmentStatementsDetails
}
