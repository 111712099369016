import { type StatementResponse } from 'modules/credit/loans/src/lib/loan-installment-details/entities'

export const variation1: StatementResponse = {
  content: {
    items: [
      {
        date: {
          description: '19 dez 2024',
          value: '2024-12-19',
          type: 'date'
        },
        amount: {
          description: '(-) R$ 3.805,63',
          value: -380563,
          type: 'money'
        },
        items: [
          {
            type: 'LatePaymentInterestAdjustment',
            isStrikeThrough: false,
            amount: {
              description: '(-) R$ 0,25',
              value: -25,
              type: 'money'
            },
            description: 'Devolução de juros',
            name: 'Reembolso',
            icon: 'arrow-curve-cycle',
            variant: 'positive'
          },
          {
            type: 'PenaltyInterestAdjustment',
            isStrikeThrough: false,
            amount: {
              description: '(-) R$ 0,07',
              value: -7,
              type: 'money'
            },
            description: 'Devolução de juros de mora',
            name: 'Reembolso',
            icon: 'arrow-curve-cycle',
            variant: 'positive'
          },
          {
            type: 'Discount',
            isStrikeThrough: false,
            amount: {
              description: '(-) R$ 3.809,17',
              value: -380917,
              type: 'money'
            },
            description: 'Lançado pela Stone',
            name: 'Desconto',
            icon: 'brand-pix',
            variant: 'positive'
          },
          {
            type: 'LatePaymentInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 2,66',
              value: 266,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros remuneratórios',
            icon: 'percent-box',
            variant: 'negative'
          },
          {
            type: 'PenaltyInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 1,20',
              value: 120,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros de mora',
            icon: 'percent-box',
            variant: 'negative'
          }
        ]
      },
      {
        date: {
          description: '18 dez 2024',
          value: '2024-12-18',
          type: 'date'
        },
        amount: {
          description: '(+) R$ 3,54',
          value: 354,
          type: 'money'
        },
        items: [
          {
            type: 'LatePaymentInterestAdjustment',
            isStrikeThrough: false,
            amount: {
              description: '(-) R$ 0,25',
              value: -25,
              type: 'money'
            },
            description: 'Devolução de juros',
            name: 'Reembolso',
            icon: 'arrow-curve-cycle',
            variant: 'positive'
          },
          {
            type: 'PenaltyInterestAdjustment',
            isStrikeThrough: false,
            amount: {
              description: '(-) R$ 0,07',
              value: -7,
              type: 'money'
            },
            description: 'Devolução de juros de mora',
            name: 'Reembolso',
            icon: 'arrow-curve-cycle',
            variant: 'positive'
          },
          {
            type: 'LatePaymentInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 2,66',
              value: 266,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros remuneratórios',
            icon: 'percent-box',
            variant: 'negative'
          },
          {
            type: 'PenaltyInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 1,20',
              value: 120,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros de mora',
            icon: 'percent-box',
            variant: 'negative'
          }
        ]
      },
      {
        date: {
          description: '17 dez 2024',
          value: '2024-12-17',
          type: 'date'
        },
        amount: {
          description: '(+) R$ 3,54',
          value: 354,
          type: 'money'
        },
        items: [
          {
            type: 'LatePaymentInterestAdjustment',
            isStrikeThrough: false,
            amount: {
              description: '(-) R$ 0,25',
              value: -25,
              type: 'money'
            },
            description: 'Devolução de juros',
            name: 'Reembolso',
            icon: 'arrow-curve-cycle',
            variant: 'positive'
          },
          {
            type: 'PenaltyInterestAdjustment',
            isStrikeThrough: false,
            amount: {
              description: '(-) R$ 0,07',
              value: -7,
              type: 'money'
            },
            description: 'Devolução de juros de mora',
            name: 'Reembolso',
            icon: 'arrow-curve-cycle',
            variant: 'positive'
          },
          {
            type: 'LatePaymentInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 2,66',
              value: 266,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros remuneratórios',
            icon: 'percent-box',
            variant: 'negative'
          },
          {
            type: 'PenaltyInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 1,20',
              value: 120,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros de mora',
            icon: 'percent-box',
            variant: 'negative'
          }
        ]
      },
      {
        date: {
          description: '16 dez 2024',
          value: '2024-12-16',
          type: 'date'
        },
        amount: {
          description: '(+) R$ 3,54',
          value: 354,
          type: 'money'
        },
        items: [
          {
            type: 'LatePaymentInterestAdjustment',
            isStrikeThrough: false,
            amount: {
              description: '(-) R$ 0,25',
              value: -25,
              type: 'money'
            },
            description: 'Devolução de juros',
            name: 'Reembolso',
            icon: 'arrow-curve-cycle',
            variant: 'positive'
          },
          {
            type: 'PenaltyInterestAdjustment',
            isStrikeThrough: false,
            amount: {
              description: '(-) R$ 0,07',
              value: -7,
              type: 'money'
            },
            description: 'Devolução de juros de mora',
            name: 'Reembolso',
            icon: 'arrow-curve-cycle',
            variant: 'positive'
          },
          {
            type: 'LatePaymentInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 2,66',
              value: 266,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros remuneratórios',
            icon: 'percent-box',
            variant: 'negative'
          },
          {
            type: 'PenaltyInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 1,20',
              value: 120,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros de mora',
            icon: 'percent-box',
            variant: 'negative'
          }
        ]
      },
      {
        date: {
          description: '15 dez 2024',
          value: '2024-12-15',
          type: 'date'
        },
        amount: {
          description: '(+) R$ 3,54',
          value: 354,
          type: 'money'
        },
        items: [
          {
            type: 'LatePaymentInterestAdjustment',
            isStrikeThrough: false,
            amount: {
              description: '(-) R$ 0,25',
              value: -25,
              type: 'money'
            },
            description: 'Devolução de juros',
            name: 'Reembolso',
            icon: 'arrow-curve-cycle',
            variant: 'positive'
          },
          {
            type: 'PenaltyInterestAdjustment',
            isStrikeThrough: false,
            amount: {
              description: '(-) R$ 0,07',
              value: -7,
              type: 'money'
            },
            description: 'Devolução de juros de mora',
            name: 'Reembolso',
            icon: 'arrow-curve-cycle',
            variant: 'positive'
          },
          {
            type: 'LatePaymentInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 2,66',
              value: 266,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros remuneratórios',
            icon: 'percent-box',
            variant: 'negative'
          },
          {
            type: 'PenaltyInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 1,20',
              value: 120,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros de mora',
            icon: 'percent-box',
            variant: 'negative'
          }
        ]
      },
      {
        date: {
          description: '14 dez 2024',
          value: '2024-12-14',
          type: 'date'
        },
        amount: {
          description: '(+) R$ 3,54',
          value: 354,
          type: 'money'
        },
        items: [
          {
            type: 'LatePaymentInterestAdjustment',
            isStrikeThrough: false,
            amount: {
              description: '(-) R$ 0,25',
              value: -25,
              type: 'money'
            },
            description: 'Devolução de juros',
            name: 'Reembolso',
            icon: 'arrow-curve-cycle',
            variant: 'positive'
          },
          {
            type: 'PenaltyInterestAdjustment',
            isStrikeThrough: false,
            amount: {
              description: '(-) R$ 0,07',
              value: -7,
              type: 'money'
            },
            description: 'Devolução de juros de mora',
            name: 'Reembolso',
            icon: 'arrow-curve-cycle',
            variant: 'positive'
          },
          {
            type: 'LatePaymentInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 2,66',
              value: 266,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros remuneratórios',
            icon: 'percent-box',
            variant: 'negative'
          },
          {
            type: 'PenaltyInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 1,20',
              value: 120,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros de mora',
            icon: 'percent-box',
            variant: 'negative'
          }
        ]
      },
      {
        date: {
          description: '13 dez 2024',
          value: '2024-12-13',
          type: 'date'
        },
        amount: {
          description: '(+) R$ 3,53',
          value: 353,
          type: 'money'
        },
        items: [
          {
            type: 'LatePaymentInterestAdjustment',
            isStrikeThrough: false,
            amount: {
              description: '(-) R$ 0,25',
              value: -25,
              type: 'money'
            },
            description: 'Devolução de juros',
            name: 'Reembolso',
            icon: 'arrow-curve-cycle',
            variant: 'positive'
          },
          {
            type: 'PenaltyInterestAdjustment',
            isStrikeThrough: false,
            amount: {
              description: '(-) R$ 0,07',
              value: -7,
              type: 'money'
            },
            description: 'Devolução de juros de mora',
            name: 'Reembolso',
            icon: 'arrow-curve-cycle',
            variant: 'positive'
          },
          {
            type: 'LatePaymentInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 2,65',
              value: 265,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros remuneratórios',
            icon: 'percent-box',
            variant: 'negative'
          },
          {
            type: 'PenaltyInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 1,20',
              value: 120,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros de mora',
            icon: 'percent-box',
            variant: 'negative'
          }
        ]
      },
      {
        date: {
          description: '12 dez 2024',
          value: '2024-12-12',
          type: 'date'
        },
        amount: {
          description: '(+) R$ 3,53',
          value: 353,
          type: 'money'
        },
        items: [
          {
            type: 'LatePaymentInterestAdjustment',
            isStrikeThrough: false,
            amount: {
              description: '(-) R$ 0,25',
              value: -25,
              type: 'money'
            },
            description: 'Devolução de juros',
            name: 'Reembolso',
            icon: 'arrow-curve-cycle',
            variant: 'positive'
          },
          {
            type: 'PenaltyInterestAdjustment',
            isStrikeThrough: false,
            amount: {
              description: '(-) R$ 0,07',
              value: -7,
              type: 'money'
            },
            description: 'Devolução de juros de mora',
            name: 'Reembolso',
            icon: 'arrow-curve-cycle',
            variant: 'positive'
          },
          {
            type: 'LatePaymentInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 2,65',
              value: 265,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros remuneratórios',
            icon: 'percent-box',
            variant: 'negative'
          },
          {
            type: 'PenaltyInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 1,20',
              value: 120,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros de mora',
            icon: 'percent-box',
            variant: 'negative'
          }
        ]
      },
      {
        date: {
          description: '11 dez 2024',
          value: '2024-12-11',
          type: 'date'
        },
        amount: {
          description: '(+) R$ 3,53',
          value: 353,
          type: 'money'
        },
        items: [
          {
            type: 'LatePaymentInterestAdjustment',
            isStrikeThrough: false,
            amount: {
              description: '(-) R$ 0,25',
              value: -25,
              type: 'money'
            },
            description: 'Devolução de juros',
            name: 'Reembolso',
            icon: 'arrow-curve-cycle',
            variant: 'positive'
          },
          {
            type: 'PenaltyInterestAdjustment',
            isStrikeThrough: false,
            amount: {
              description: '(-) R$ 0,07',
              value: -7,
              type: 'money'
            },
            description: 'Devolução de juros de mora',
            name: 'Reembolso',
            icon: 'arrow-curve-cycle',
            variant: 'positive'
          },
          {
            type: 'LatePaymentInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 2,65',
              value: 265,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros remuneratórios',
            icon: 'percent-box',
            variant: 'negative'
          },
          {
            type: 'PenaltyInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 1,20',
              value: 120,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros de mora',
            icon: 'percent-box',
            variant: 'negative'
          }
        ]
      },
      {
        date: {
          description: '10 dez 2024',
          value: '2024-12-10',
          type: 'date'
        },
        amount: {
          description: '(+) R$ 3,53',
          value: 353,
          type: 'money'
        },
        items: [
          {
            type: 'LatePaymentInterestAdjustment',
            isStrikeThrough: false,
            amount: {
              description: '(-) R$ 0,25',
              value: -25,
              type: 'money'
            },
            description: 'Devolução de juros',
            name: 'Reembolso',
            icon: 'arrow-curve-cycle',
            variant: 'positive'
          },
          {
            type: 'PenaltyInterestAdjustment',
            isStrikeThrough: false,
            amount: {
              description: '(-) R$ 0,07',
              value: -7,
              type: 'money'
            },
            description: 'Devolução de juros de mora',
            name: 'Reembolso',
            icon: 'arrow-curve-cycle',
            variant: 'positive'
          },
          {
            type: 'LatePaymentInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 2,65',
              value: 265,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros remuneratórios',
            icon: 'percent-box',
            variant: 'negative'
          },
          {
            type: 'PenaltyInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 1,20',
              value: 120,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros de mora',
            icon: 'percent-box',
            variant: 'negative'
          }
        ]
      }
    ],
    paging: {
      totalItems: 178,
      currentPage: 1,
      pageSize: 10,
      pageCount: 18
    }
  },
  traceKey: 'jrbzzxaz3ewb7ks7vkgk4rp93',
  isValid: true
}

export const variation2: StatementResponse = {
  content: {
    items: [
      {
        date: {
          description: '08 out 2024',
          value: '2024-10-08',
          type: 'date'
        },
        amount: {
          description: '(-) R$ 1.249,99',
          value: -124999,
          type: 'money'
        },
        items: [
          {
            type: 'Discount',
            isStrikeThrough: false,
            amount: {
              description: '(-) R$ 1.249,99',
              value: -124999,
              type: 'money'
            },
            description: 'Lançado pela Stone',
            name: 'Desconto',
            icon: 'brand-pix',
            variant: 'positive'
          }
        ]
      },
      {
        date: {
          description: '07 out 2024',
          value: '2024-10-07',
          type: 'date'
        },
        amount: {
          description: '(-) R$ 1.001,00',
          value: -100100,
          type: 'money'
        },
        items: [
          {
            type: 'RepaymentBoleto',
            isStrikeThrough: false,
            amount: {
              description: '(-) R$ 1.001,00',
              value: -100100,
              type: 'money'
            },
            description: 'Feito por boleto',
            name: 'Pagamento extra',
            icon: 'barcode',
            variant: 'neutral'
          }
        ]
      }
    ],
    paging: {
      totalItems: 2,
      currentPage: 1,
      pageSize: 10,
      pageCount: 1
    }
  },
  traceKey: 'jrc1qefr9ra9dx6s6px57n9q8',
  isValid: true
}

export const variation3: StatementResponse = {
  content: {
    items: [
      {
        date: {
          description: '18 mar 2025',
          value: '2025-03-18',
          type: 'date'
        },
        amount: {
          description: '(-) R$ 3,02',
          value: -302,
          type: 'money'
        },
        items: [
          {
            type: 'RepaymentPix',
            isStrikeThrough: false,
            amount: {
              description: '(-) R$ 1,00',
              value: -100,
              type: 'money'
            },
            description: 'Feito por Pix',
            name: 'Pagamento extra',
            icon: 'brand-pix',
            variant: 'neutral'
          },
          {
            type: 'RepaymentAccountDebit',
            isStrikeThrough: false,
            amount: {
              description: '(-) R$ 2,02',
              value: -202,
              type: 'money'
            },
            description: 'Feito com saldo da conta',
            name: 'Pagamento extra',
            icon: 'brand-ton',
            variant: 'neutral'
          },
          {
            type: 'LatePaymentInterestAdjustment',
            isStrikeThrough: false,
            amount: {
              description: '(-) R$ 0,01',
              value: -1,
              type: 'money'
            },
            description: 'Devolução de juros',
            name: 'Reembolso',
            icon: 'arrow-curve-cycle',
            variant: 'positive'
          },
          {
            type: 'PenaltyInterestAdjustment',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 0,00',
              value: 0,
              type: 'money'
            },
            description: 'Devolução de juros de mora',
            name: 'Reembolso',
            icon: 'arrow-curve-cycle',
            variant: 'positive'
          },
          {
            type: 'LatePaymentInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 0,01',
              value: 1,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros remuneratórios',
            icon: 'percent-box',
            variant: 'negative'
          },
          {
            type: 'PenaltyInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 0,00',
              value: 0,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros de mora',
            icon: 'percent-box',
            variant: 'negative'
          }
        ]
      },
      {
        date: {
          description: '17 mar 2025',
          value: '2025-03-17',
          type: 'date'
        },
        amount: {
          description: '(-) R$ 3.905,09',
          value: -390509,
          type: 'money'
        },
        items: [
          {
            type: 'RepaymentPix',
            isStrikeThrough: false,
            amount: {
              description: '(-) R$ 3.900,00',
              value: -390000,
              type: 'money'
            },
            description: 'Feito por Pix',
            name: 'Pagamento extra',
            icon: 'brand-pix',
            variant: 'neutral'
          },
          {
            type: 'RepaymentPix',
            isStrikeThrough: false,
            amount: {
              description: '(-) R$ 1,00',
              value: -100,
              type: 'money'
            },
            description: 'Feito por Pix',
            name: 'Pagamento extra',
            icon: 'brand-pix',
            variant: 'neutral'
          },
          {
            type: 'RepaymentBoleto',
            isStrikeThrough: false,
            amount: {
              description: '(-) R$ 7,78',
              value: -778,
              type: 'money'
            },
            description: 'Feito por boleto',
            name: 'Pagamento extra',
            icon: 'barcode',
            variant: 'neutral'
          },
          {
            type: 'LatePaymentInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 2,48',
              value: 248,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros remuneratórios',
            icon: 'percent-box',
            variant: 'negative'
          },
          {
            type: 'PenaltyInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 1,21',
              value: 121,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros de mora',
            icon: 'percent-box',
            variant: 'negative'
          }
        ]
      },
      {
        date: {
          description: '16 mar 2025',
          value: '2025-03-16',
          type: 'date'
        },
        amount: {
          description: '(+) R$ 3,68',
          value: 368,
          type: 'money'
        },
        items: [
          {
            type: 'LatePaymentInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 2,47',
              value: 247,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros remuneratórios',
            icon: 'percent-box',
            variant: 'negative'
          },
          {
            type: 'PenaltyInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 1,21',
              value: 121,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros de mora',
            icon: 'percent-box',
            variant: 'negative'
          }
        ]
      },
      {
        date: {
          description: '15 mar 2025',
          value: '2025-03-15',
          type: 'date'
        },
        amount: {
          description: '(+) R$ 3,68',
          value: 368,
          type: 'money'
        },
        items: [
          {
            type: 'LatePaymentInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 2,47',
              value: 247,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros remuneratórios',
            icon: 'percent-box',
            variant: 'negative'
          },
          {
            type: 'PenaltyInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 1,21',
              value: 121,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros de mora',
            icon: 'percent-box',
            variant: 'negative'
          }
        ]
      },
      {
        date: {
          description: '14 mar 2025',
          value: '2025-03-14',
          type: 'date'
        },
        amount: {
          description: '(+) R$ 3,68',
          value: 368,
          type: 'money'
        },
        items: [
          {
            type: 'LatePaymentInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 2,47',
              value: 247,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros remuneratórios',
            icon: 'percent-box',
            variant: 'negative'
          },
          {
            type: 'PenaltyInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 1,21',
              value: 121,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros de mora',
            icon: 'percent-box',
            variant: 'negative'
          }
        ]
      },
      {
        date: {
          description: '13 mar 2025',
          value: '2025-03-13',
          type: 'date'
        },
        amount: {
          description: '(+) R$ 3,68',
          value: 368,
          type: 'money'
        },
        items: [
          {
            type: 'LatePaymentInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 2,47',
              value: 247,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros remuneratórios',
            icon: 'percent-box',
            variant: 'negative'
          },
          {
            type: 'PenaltyInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 1,21',
              value: 121,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros de mora',
            icon: 'percent-box',
            variant: 'negative'
          }
        ]
      },
      {
        date: {
          description: '12 mar 2025',
          value: '2025-03-12',
          type: 'date'
        },
        amount: {
          description: '(+) R$ 3,68',
          value: 368,
          type: 'money'
        },
        items: [
          {
            type: 'LatePaymentInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 2,47',
              value: 247,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros remuneratórios',
            icon: 'percent-box',
            variant: 'negative'
          },
          {
            type: 'PenaltyInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 1,21',
              value: 121,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros de mora',
            icon: 'percent-box',
            variant: 'negative'
          }
        ]
      },
      {
        date: {
          description: '11 mar 2025',
          value: '2025-03-11',
          type: 'date'
        },
        amount: {
          description: '(+) R$ 3,68',
          value: 368,
          type: 'money'
        },
        items: [
          {
            type: 'LatePaymentInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 2,47',
              value: 247,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros remuneratórios',
            icon: 'percent-box',
            variant: 'negative'
          },
          {
            type: 'PenaltyInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 1,21',
              value: 121,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros de mora',
            icon: 'percent-box',
            variant: 'negative'
          }
        ]
      },
      {
        date: {
          description: '10 mar 2025',
          value: '2025-03-10',
          type: 'date'
        },
        amount: {
          description: '(+) R$ 3,67',
          value: 367,
          type: 'money'
        },
        items: [
          {
            type: 'LatePaymentInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 2,46',
              value: 246,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros remuneratórios',
            icon: 'percent-box',
            variant: 'negative'
          },
          {
            type: 'PenaltyInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 1,21',
              value: 121,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros de mora',
            icon: 'percent-box',
            variant: 'negative'
          }
        ]
      },
      {
        date: {
          description: '09 mar 2025',
          value: '2025-03-09',
          type: 'date'
        },
        amount: {
          description: '(+) R$ 3,67',
          value: 367,
          type: 'money'
        },
        items: [
          {
            type: 'LatePaymentInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 2,46',
              value: 246,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros remuneratórios',
            icon: 'percent-box',
            variant: 'negative'
          },
          {
            type: 'PenaltyInterest',
            isStrikeThrough: false,
            amount: {
              description: '(+) R$ 1,21',
              value: 121,
              type: 'money'
            },
            description: 'Cobrança por atraso',
            name: 'Juros de mora',
            icon: 'percent-box',
            variant: 'negative'
          }
        ]
      }
    ],
    paging: {
      totalItems: 59,
      currentPage: 1,
      pageSize: 10,
      pageCount: 6
    }
  },
  traceKey: 'jrc7dawkaxwrckgx8nr7z1kpn',
  isValid: true
}
