import { useCallback, useEffect, useState } from 'react'

import { flowStepperEventBus } from '../helpers/flow-stepper-event-bus'
import { type FlowStep, type FlowStepItem, type MoveToStep, type moveToStepProps } from '../types'

export type FlowStepperGuard = (moveToStep: MoveToStep, currentStep?: FlowStep<any>, steps?: FlowStep<any>[]) => void

interface FlowStepperProps {
  steps: FlowStep<any>[]
  initialStep: string
  guard?: FlowStepperGuard
}

const MAX_STEP_HITORY_SIZE = 5

export function FlowStepper({ steps, initialStep, guard }: FlowStepperProps) {
  const [currentStep, setCurrentStep] = useState<FlowStep<any>>()
  const [stepHistoryStack, setStepHistoryStack] = useState<string[]>([])

  const addStepToStepHistoryStack = (step: FlowStep<any>) => {
    if (stepHistoryStack?.length === MAX_STEP_HITORY_SIZE) {
      setStepHistoryStack(stepHistoryStack.slice(1).concat([step.key]))
      return
    }

    setStepHistoryStack(stepHistoryStack.concat([step.key]))
  }

  const moveToStep = useCallback(
    ({ stepKey, direction }: moveToStepProps) => {
      const selectedStep = steps.find((item: FlowStep<any>) => item.key === stepKey)
      if (!(selectedStep && selectedStep !== currentStep)) return

      const builtCurrentStep: FlowStepItem | undefined = currentStep?.step(moveToStep)

      const stepLifecycleMap = {
        foward: (step: FlowStepItem) => {
          if ('onNext' in step && step.onNext) step?.onNext(selectedStep.key)
        },
        backward: (step: FlowStepItem) => {
          if ('onBack' in step && step.onBack) step?.onBack(selectedStep.key)
        },
        // eslint-disable-next-line prettier/prettier
        none: () => { }
      }

      if (builtCurrentStep) stepLifecycleMap[direction](builtCurrentStep)

      setCurrentStep(selectedStep)
      addStepToStepHistoryStack(selectedStep)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStep, steps]
  )

  const historyBack = useCallback(() => {
    const stepHistoryLastIndex = stepHistoryStack.length - 1
    const previousStepKeyIndex = stepHistoryLastIndex - 1

    if (previousStepKeyIndex >= 0 && stepHistoryStack[previousStepKeyIndex] !== currentStep?.key)
      moveToStep({ stepKey: stepHistoryStack[previousStepKeyIndex], direction: 'backward' })
  }, [currentStep, stepHistoryStack, moveToStep])

  useEffect(() => {
    moveToStep({ stepKey: initialStep, direction: 'none' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    flowStepperEventBus.on('triggerHistoryBack', () => historyBack(), 'flowStepperOnHistoryBack')
  }, [historyBack])

  useEffect(() => {
    flowStepperEventBus.on('triggerMoveState', (props: moveToStepProps) => moveToStep(props), 'flowStepperOnMoveState')
  }, [moveToStep])

  useEffect(() => {
    if (guard) guard(moveToStep, currentStep, steps)
  }, [currentStep, guard, moveToStep, steps])

  const builtCurrentStep: FlowStepItem | undefined = currentStep?.step(moveToStep)
  const StepElement = builtCurrentStep && 'render' in builtCurrentStep ? builtCurrentStep?.render : builtCurrentStep

  return StepElement || <></>
}
