import { type FlowStep, type MoveToStep } from 'modules/credit/common/flow-stepper/src/types'

import { Loader } from '~/domains/platform/design-system/loader/loader'

// TODO: Success Page
/* export const RefinancingSuccess = dynamic(
  () => import('../components/refinancing-success').then(mod => mod.RefinancingSuccess),
  {
    loading: () => <RefinancingLoading />
  }
)
 */
export enum RefinancingSteps {
  ROOT = '/',
  PROPOSALS_POOL = 'condicoes',
  SIMULATION = 'simulacao',
  PROPOSAL = 'proposta',
  KYC = 'dados-pessoais',
  SUMMARY = 'resumo',
  PIN = 'pin',
  CONFIRMATION = 'confirmacao',
  SUCCESS = 'sucesso'
}

export function renegotiationRefinancingFlowGuard(
  moveToStep: MoveToStep,
  currentStep?: FlowStep<any>,
  steps?: FlowStep<any>[]
) {
  if (currentStep && steps) {
    const selectedStep = steps.find(steps => steps.key === currentStep)

    if (selectedStep) {
      moveToStep({ stepKey: selectedStep.key, direction: 'none' })
      return <Loader />
    }
  }
}
