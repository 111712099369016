import { useEffect } from 'react'
import { FlowStepper } from 'modules/credit/common/flow-stepper/src/components/flow-stepper'
import { type FlowStep, type MoveToStep } from 'modules/credit/common/flow-stepper/src/types'

import { type WithPageConfigProps } from '~/domains/platform/infra/page-enhancers/with-page-config'
import BlackBird from '~/domains/platform/lib/blackbird'
import { stringFormat } from '~/lib/helpers'

import { SharedLoanGenericFeedback } from '../../shared/components/shared-loan-generic-feedback'
import { LoanConcessionSteps } from '../entities'
import analitica from '../helpers/analitica'
import { useLoanConcessionFlow } from '../hooks/use-negotiations-proposal'
import { useLoanConcessionStore } from '../store'

import { LoanConcessionFeedback } from './loan-concession-feedback'
import { LoanConcessionLoading } from './loan-concession-loading'
import { LoanConcessionSimulation } from './loan-concession-simulation'

const { currency } = stringFormat

export function LoanConcessionFlow({ entity }: WithPageConfigProps<'stone_account' | 'banking' | 'stone_account_kyc'>) {
  const { proposalId, offerId } = BlackBird.getQuery()
  const { hasNavigatedToProcessing, incrementNavigatedToProcessing, handleError, hasError } = useLoanConcessionStore()
  const { data, isLoading, isError } = useLoanConcessionFlow({
    proposalId,
    offerId,
    document: entity?.document || ''
  })
  const { proposalProcessing, proposalAvailable, creditOffer } = data

  useEffect(() => handleError([isError]), [isError, handleError])

  const steps: FlowStep<LoanConcessionSteps>[] = [
    {
      key: LoanConcessionSteps.SIMULATION,
      step: (moveToStep: MoveToStep) => (
        <LoanConcessionSimulation
          creditOffer={creditOffer}
          proposalAvailable={proposalAvailable}
          proposalProcessing={proposalProcessing}
          moveToStep={moveToStep}
        />
      )
    },
    {
      key: LoanConcessionSteps.FEEDBACK,
      step: () => <LoanConcessionFeedback proposalProcessing={proposalProcessing} />
    }
  ]

  if (isLoading) return <LoanConcessionLoading />

  if (hasError) {
    analitica.events.negativeFeedback.view({ offerAmount: currency((creditOffer.maxCreditValue || 0) * 100, true) })
    return (
      <SharedLoanGenericFeedback
        navigationBarTitle="Simular pedido"
        action={BlackBird.reload}
        actionButtonText="Tentar de novo"
        hasSagaKey
      />
    )
  }

  const guard = (moveToStep: MoveToStep, currentStep?: FlowStep<LoanConcessionSteps>) => {
    if (currentStep?.key !== LoanConcessionSteps.FEEDBACK && proposalProcessing && !hasNavigatedToProcessing) {
      moveToStep({ stepKey: LoanConcessionSteps.FEEDBACK, direction: 'foward' })
      incrementNavigatedToProcessing()
      return null
    }
  }

  return <FlowStepper steps={steps} initialStep={LoanConcessionSteps.SIMULATION} guard={guard} />
}
