import { type ReactNode } from 'react'
import FocusLock from 'react-focus-lock'
import { useLockBodyScroll } from 'react-use'
import { Cover, Sidebar, StackSplit } from '@dlpco/fluid-layout'
import { IconButton } from '@dlpco/ginga-stone'
import { useKeyPress } from 'shared/utilities/utility-hooks'

import { Box, Text } from '~/domains/platform/design-system'
import { If } from '~/domains/platform/lib/utilities-components'
import { useTheme } from '~/ui/hooks/utils/ui/use-theme'
import { useViewSize } from '~/ui/hooks/utils/ui/use-view-size'
import { theme } from '~/ui/theme'

import { ContentWrapper, Fixed, FullscreenDialog } from './styles'

export interface FullscreenProps {
  isOpen: boolean
  onClose?: () => void
  title?: string
  toolbar?: ReactNode
  aside?: ReactNode
  content?: ReactNode
  centerContent?: boolean
  growContent?: boolean
  noPadding?: boolean
  noPaddingMobile?: boolean
  singleContent?: boolean
  withoutHeader?: boolean
  useBackArrow?: boolean
  hiddenCloseButton?: boolean
}

export const Fullscreen = ({
  aside: Aside,
  content: Content,
  centerContent,
  isOpen,
  title,
  onClose,
  growContent,
  toolbar: Toolbar,
  noPadding,
  noPaddingMobile,
  singleContent,
  withoutHeader,
  useBackArrow,
  hiddenCloseButton
}: FullscreenProps) => {
  const {
    sizes: {
      header: { height: headerHeight }
    }
  } = useTheme()

  useLockBodyScroll(isOpen)
  const padding = noPadding ? null : '3rem'

  const { isViewSmall } = useViewSize()

  useKeyPress({
    keyPressItems: [
      {
        keys: ['Escape'],
        event: () => onClose?.()
      }
    ]
  })

  return (
    <If
      condition={isOpen}
      render={() => {
        return (
          <FocusLock disabled={!isOpen}>
            <Fixed>
              <FullscreenDialog id="fullscreen_dialog" headerHeight={!withoutHeader ? headerHeight : '0'}>
                <Sidebar contentSide="left" asideWidth="33vw" style={{ minHeight: '100%', display: 'contents' }}>
                  <Box minHeight="100%">
                    <Sidebar
                      asideWidth="1rem"
                      gutter="2rem"
                      contentMinWidth="85%"
                      contentSide="right"
                      showOverflow
                      noStretch
                      style={{ minHeight: '100%' }}
                    >
                      <Box maxHeight="100%">
                        <If condition={!hiddenCloseButton}>
                          <Box p="1rem 2rem 0 1rem">
                            <IconButton
                              color="neutral"
                              variant="contentOnly"
                              size="large"
                              icon={useBackArrow ? 'direction-arrow-left-outline' : 'close-outline'}
                              type="button"
                              onClick={onClose}
                              aria-label="Fechar"
                            />
                          </Box>
                        </If>

                        <Box p={noPaddingMobile ? '0' : '1rem 2rem'}>
                          <Cover
                            centered=".content"
                            gutter="0.5rem"
                            space={noPaddingMobile ? '0' : '0 2rem 0 0'}
                            minHeight={`calc(100vh - 4rem - ${headerHeight})`}
                            onlyBottom={!centerContent}
                          >
                            <Text color="stoneGreen" fontSize={4} fontWeight={theme.fontWeights.bold}>
                              {title}
                            </Text>

                            <ContentWrapper
                              className="content"
                              fullWidth={Boolean(singleContent)}
                              grow={Boolean(growContent)}
                            >
                              {Content}
                            </ContentWrapper>

                            <If condition={Boolean(Toolbar)} render={() => <StackSplit>{Toolbar}</StackSplit>} />
                          </Cover>
                        </Box>
                      </Box>
                    </Sidebar>

                    {Aside && !isViewSmall && (
                      <Box className="hide-when-print" bg="lightGray" p={padding}>
                        {Aside}
                      </Box>
                    )}
                  </Box>
                </Sidebar>
              </FullscreenDialog>
            </Fixed>
          </FocusLock>
        )
      }}
    />
  )
}
