import { Box } from '@dlpco/fluid-layout'
import { PageContainer, Spinner, Text } from '@stone-payments/jade'
import jadeTheme from '@stone-payments/jade-design-tokens/cjs/stone'

import { SharedCustomCard } from '~/domains/credit/shared/components/credit-custom-card'
import { Flex } from '~/domains/platform/design-system'
import { If } from '~/domains/platform/lib/utilities-components'

export function LoanConcessionLoading({ message }: { message?: string }) {
  return (
    <Flex style={{ background: jadeTheme.theme.color.background.surface }} justifyContent="center">
      <PageContainer size="narrow">
        <SharedCustomCard>
          <Flex
            flexDirection="column"
            gap={jadeTheme.global.space[50]}
            alignItems="center"
            justifyContent="center"
            height="calc(100vh - 70px - 80px - 15px)"
            backgroundColor={jadeTheme.theme.color.background.surface}
          >
            <Box>
              <Spinner color="brand" size="xlarge" />
            </Box>
            <If
              condition={!!message}
              render={() => (
                <Text variant="text-large" weight="semibold" color="medium">
                  {message}
                </Text>
              )}
            />
          </Flex>
        </SharedCustomCard>
      </PageContainer>
    </Flex>
  )
}
