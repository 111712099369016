import Intl from 'intl'

import { unmask } from '~/lib/helpers/utils/unmask'

import 'intl/locale-data/jsonp/pt'

/**
 * @deprecated Use `toCurrency` from `shared/utilities/utils-formatters` instead.
 */
const currency = (amount = 0, hideSymbol?: boolean) => {
  const isNegative = amount < 0
  const sign = isNegative ? '-' : ''
  const positiveAmount = Math.abs(amount)
  const normalizedAmount = positiveAmount / 100

  const localizedAmount = new Intl.NumberFormat('pt-BR', {
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(normalizedAmount)

  return `${sign}${hideSymbol ? '' : 'R$ '}${localizedAmount}`
}

const documentType = (document: string) => {
  switch (document?.length) {
    case 11:
      return 'CPF'
    case 14:
      return 'CNPJ'
    default:
      return 'Desconhecido'
  }
}

const document = (document: string, obfuscated = false) => {
  const NOT_A_DIGIT__REGEX = /\D/g
  const NOT_A_DIGIT_OR_STAR__REGEX = /[^0-9*]/g
  const CPF_OBFUSCATED__REGEX = /(\d{3}|\*{3})(\d{3})(\d{3})(\d{2}|\*{2})/g
  const CNPJ_OBFUSCATED__REGEX = /^(\d{2}|\*{2})(\d{3}|\*{3})?(\d{3}|\*{3})?(\d{4}|\*{4})?(\d{2}|\*{2})?/

  const _document = document?.replace(obfuscated ? NOT_A_DIGIT_OR_STAR__REGEX : NOT_A_DIGIT__REGEX, '')
  const type = documentType(_document)
  const formatCNPJ = (CNPJ?: string) => CNPJ?.replace(CNPJ_OBFUSCATED__REGEX, '$1.$2.$3/$4-$5') || ''

  const formatCPF = (CPF?: string) => CPF?.replace(CPF_OBFUSCATED__REGEX, '$1.$2.$3-$4') || ''

  if (type === 'CNPJ') {
    return formatCNPJ(_document)
  }

  if (type === 'CPF') {
    return formatCPF(_document)
  }

  return document
}

const usernameFormatter = (str: string) => {
  if (unmask(str).length <= 11) return document(str)
  return str
}

const phoneNumber = (number: string) => number.replace(/(?:(\d{2}))?(\d{2})(\d{4,5})(\d{4})/g, '$1 ($2) $3-$4')

const removeCountryCode = (value: string) => (value.length >= 14 ? value.substring(3) : value)

const accountNumber = (str = '') => (str?.length > 1 ? `${str.slice(0, -1)}-${str.slice(-1)}` : str)

const onlyNumbers = (str: string): string => str.replace(/\D/g, '')

const stringToCurrency = (amount: string): number => {
  if (amount === '') return 0
  const withoutPrefix = amount.replace('R$', '')
  const [integer, fraction] = withoutPrefix.split(',')
  const formatedInteger = integer.split('.').join('')
  return +[formatedInteger, fraction].join('.')
}

const boletoMask = (boleto: string) =>
  boleto.replace(/(\d{5})(\d{5})(\d{5})(\d{6})(\d{5})(\d{6})(\d{1})(\d{14})/g, '$1.$2 $3.$4 $5.$6 $7 $8')

const convenioMask = (convenio: string) =>
  convenio.replace(/(\d{11})(\d{1})(\d{11})(\d{1})(\d{11})(\d{1})(\d{11})(\d{1})/g, '$1-$2 $3-$4 $5-$6 $7-$8')

/**
 * @deprecated This helper function is being deprecated because it implements domain-specific logic
 * that should not reside in global utility helpers. Such logic should be implemented within the
 * corresponding domain module where it is most relevant and specific.
 *
 * ### Why this function is deprecated:
 * - **Domain-specific logic**: The current implementation serves a specific domain, making it unsuitable
 *   for reuse across other areas of the application.
 * - **Lack of generality**: Utility functions in the global helpers should remain generic, supporting
 *   broad usage across the application without embedding domain-specific rules or requirements.
 *
 * ### What you should do:
 * 1. **Move to domain module**: If the logic applies only to a particular module, move it to the appropriate
 *    module, component or service.
 * 2. **Redefine helper purpose**: If a generalized helper is still required, refactor it to remove
 *    domain-specific rules and ensure it serves a broader purpose.
 */
const withBarcodeMask = (barcode: string) => (barcode.startsWith('8') ? convenioMask(barcode) : boletoMask(barcode))

const normalizeAmount = (amount: string, toFixed = 2): number => {
  return Number(Number(stringToCurrency(amount)).toFixed(toFixed).replace(/\D/g, ''))
}

const onlyDigits = (str: string) => str.replace(/[^0-9]/g, '')

/**
 * @deprecated This helper function is being deprecated because it implements domain-specific logic
 * that should not reside in global utility helpers. Such logic should be implemented within the
 * corresponding domain module where it is most relevant and specific.
 *
 * ### Why this function is deprecated:
 * - **Domain-specific logic**: The current implementation serves a specific domain, making it unsuitable
 *   for reuse across other areas of the application.
 * - **Lack of generality**: Utility functions in the global helpers should remain generic, supporting
 *   broad usage across the application without embedding domain-specific rules or requirements.
 *
 * ### What you should do:
 * 1. **Move to domain module**: If the logic applies only to a particular module, move it to the appropriate
 *    module, component or service.
 * 2. **Redefine helper purpose**: If a generalized helper is still required, refactor it to remove
 *    domain-specific rules and ensure it serves a broader purpose.
 */
const formatCurrency = (value: number) => (value === 0 ? 'Grátis' : currency(value))

const replaceWithAsterisk = (indices: number[], str: string) => {
  let res = ''
  res = indices
    .reduce((acc, val) => {
      acc[val] = '*'
      return acc
    }, str.split(''))
    .join('')
  return res
}

/**
 * @deprecated This helper function is being deprecated because it implements domain-specific logic
 * that should not reside in global utility helpers. Such logic should be implemented within the
 * corresponding domain module where it is most relevant and specific.
 *
 * ### Why this function is deprecated:
 * - **Domain-specific logic**: The current implementation serves a specific domain, making it unsuitable
 *   for reuse across other areas of the application.
 * - **Lack of generality**: Utility functions in the global helpers should remain generic, supporting
 *   broad usage across the application without embedding domain-specific rules or requirements.
 *
 * ### What you should do:
 * 1. **Move to domain module**: If the logic applies only to a particular module, move it to the appropriate
 *    module, component or service.
 * 2. **Redefine helper purpose**: If a generalized helper is still required, refactor it to remove
 *    domain-specific rules and ensure it serves a broader purpose.
 */
const maskPixKey = (key: string) =>
  key.replace(
    new RegExp(/([0-9A-F]{8})-?([0-9A-F]{4})-?([0-9A-F]{4})-?([0-9A-F]{4})-?([0-9A-F]{12})/i),
    '$1-$2-$3-$4-$5'
  )

const capitalizeFirstLetter = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1)

const hideDocument = (document: string) => {
  const indicesToBury = documentType(document) === 'CPF' ? [0, 1, 2, 12, 13] : [0, 1, 3, 16, 17]
  const indicesToBuryFormatted = stringFormat.replaceWithAsterisk(indicesToBury, stringFormat.document(document))

  return indicesToBuryFormatted
}

const postalCode = (number: string) => number?.substring(0, 5) + '-' + number?.substring(5)

/**
 * @deprecated This helper function is being deprecated because it implements domain-specific logic
 * that should not reside in global utility helpers. Such logic should be implemented within the
 * corresponding domain module where it is most relevant and specific.
 *
 * ### Why this function is deprecated:
 * - **Domain-specific logic**: The current implementation serves a specific domain, making it unsuitable
 *   for reuse across other areas of the application.
 * - **Lack of generality**: Utility functions in the global helpers should remain generic, supporting
 *   broad usage across the application without embedding domain-specific rules or requirements.
 *
 * ### What you should do:
 * 1. **Move to domain module**: If the logic applies only to a particular module, move it to the appropriate
 *    module, component or service.
 * 2. **Redefine helper purpose**: If a generalized helper is still required, refactor it to remove
 *    domain-specific rules and ensure it serves a broader purpose.
 */
const identityId = (id: string) => id.split(':')[1]

/**
 * @deprecated Use `String.prototype.trimEnd` instead.
 */
const trimEnd = (string = '', char = ' '): string => {
  if (string.endsWith(char)) {
    return trimEnd(string.slice(0, -1), char)
  }
  return string
}

const truncate = (string: string, n: number): string => {
  return string.length > n ? string.slice(0, n - 1) + '...' : string
}

export const stringFormat = {
  accountNumber,
  currency,
  document,
  usernameFormatter,
  phoneNumber,
  removeCountryCode,
  documentType,
  normalizeAmount,
  onlyNumbers,
  withBarcodeMask,
  onlyDigits,
  formatCurrency,
  replaceWithAsterisk,
  capitalizeFirstLetter,
  hideDocument,
  maskPixKey,
  postalCode,
  identityId,
  trimEnd,
  truncate
}
