import md5Lib from 'md5'
import sjcl, { type SjclCipherEncrypted } from 'sjcl'
import { v4 } from 'uuid'

/**
 * @deprecated Use `uuid` from `shared/crypto` instead.
 */
export function uuid() {
  return v4()
}

/**
 * @deprecated Use `isUUID` from `shared/crypto` instead.
 */
export function isUuid(uuid: string) {
  const uuidRegex = /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
  return uuidRegex.test(uuid)
}

/**
 * @deprecated Use `uuidToHex` from `shared/crypto` instead.
 */
export function uuidToHex(uuid: string, addLeadingZero = false) {
  if (!isUuid(uuid)) {
    throw new Error('Invalid UUID string.')
  }

  const hexString = uuid.replace(/-/g, '')

  return addLeadingZero ? `0x${hexString}` : hexString
}

/**
 * @deprecated Use `md5` from `shared/crypto` instead.
 */
export function md5(plaintext: string) {
  return md5Lib(plaintext)
}

/**
 * @deprecated Use `encrypt` from `shared/crypto` instead.
 */
export function encrypt(plaintext: string, salt = '') {
  return sjcl.encrypt(salt, plaintext)
}

/**
 * @deprecated Use `decrypt` from `shared/crypto` instead.
 */
export function decrypt(ciphertext: string | SjclCipherEncrypted, salt = '') {
  return sjcl.decrypt(salt, ciphertext)
}
