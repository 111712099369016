export const INVESTMENTS_ROUTES = [
  '/rendimentos/quer-guardar-dinheiro',
  '/rendimentos/sem-ofertas-disponiveis',
  '/rendimentos/como-quer-guardar',
  '/rendimentos/guardar-dinheiro/por-valor',
  '/rendimentos/guardar-dinheiro/por-valor/confira-os-dados',
  '/rendimentos/guardar-dinheiro/por-valor/informe-seu-pin',
  '/rendimentos/guardar-dinheiro/por-recebimentos',
  '/rendimentos/guardar-dinheiro/por-recebimentos/confira-os-dados',
  '/rendimentos/guardar-dinheiro/por-recebimentos/informe-seu-pin',
  '/rendimentos/informacoes/fgc',
  '/rendimentos/informacoes/rentabilidade',
  '/rendimentos/informacoes/condicoes-de-resgate',
  '/rendimentos/informacoes/tributacao',
  '/rendimentos/produtos/alterar-produto',
  '/rendimentos/produtos/:pid',
  '/rendimentos',
  '/rendimentos/objetivos/criar',
  '/rendimentos/objetivos/:bid',
  '/rendimentos/objetivos/:bid/produtos/:pid',
  '/rendimentos/objetivos/:bid/produtos',
  '/rendimentos/objetivos/:bid/extrato',
  '/rendimentos/guardar-dinheiro/selecione-um-objetivo',
  '/rendimentos/objetivos/:bid/alterar',
  '/rendimentos/resgatar-dinheiro',
  '/rendimentos/resgatar-dinheiro/selecione-um-produto',
  '/rendimentos/resgatar-dinheiro/selecione-um-objetivo',
  '/rendimentos/resgatar-dinheiro/informe-seu-pin',
  '/rendimentos/regras',
  '/rendimentos/regras',
  '/rendimentos/regras/alterar/por-valor',
  '/rendimentos/regras/alterar/por-recebimentos'
] as const
