import React from 'react'
import { Stack } from '@dlpco/fluid-layout'
import { CardContainer, Divider, ListItem, PageContainer } from '@stone-payments/jade'
import jadeTheme from '@stone-payments/jade-design-tokens/cjs/stone'

import { Flex } from '~/domains/platform/design-system'
import { Skeleton } from '~/domains/platform/design-system/skeleton/skeleton'

import { maxWidth, SharedPageContainerWrapper } from '../../../shared/components/shared-page-container-wrapper'

const { space } = jadeTheme.theme

const RefinancingSkeleton: React.FC<{ withBreadcrumbSkeleton?: boolean }> = React.memo(({ withBreadcrumbSkeleton }) => {
  return (
    <Flex flexDirection="column">
      {withBreadcrumbSkeleton && (
        <Flex width="100%" height="58px" gap={space['xsmall']} alignItems="center">
          <Skeleton width="12ch" height="20px" intensity={2} aria-label="Início" />
          <Skeleton width="16ch" height="20px" intensity={2} aria-label="Carregando página" />
        </Flex>
      )}

      <PageContainer
        style={{ maxWidth, width: '100%', padding: 0 }}
        size="wide"
        aside={
          <CardContainer safeArea={true} variant="elevated">
            <Flex flexDirection="column" gap={space.xsmall}>
              <Skeleton
                width="100%"
                height="20px"
                mb={space['2xsmall']}
                intensity={2}
                aria-label="Você está renegociando"
              />
              <Flex flexDirection="column" gap={space.xsmall}>
                <Skeleton width="12ch" height="10px" intensity={1} mt={space['2xsmall']} />
                <Skeleton width="12ch" height="20px" intensity={1} />
              </Flex>
            </Flex>
          </CardContainer>
        }
      >
        <CardContainer safeArea={true} variant="elevated">
          <Flex flexDirection="column" gap={space.xsmall}>
            {/* percentage label */}
            <Skeleton width="8ch" intensity={1} height="12px" aria-label="0% completo" />
            {/* percentage bar */}
            <Skeleton width="100%" intensity={1} height="4px" aria-label="" />
          </Flex>

          <Flex flexDirection="column" gap={space.xsmall} mt={space.large} mb={space.large}>
            {/* Title */}
            <Skeleton width="40ch" intensity={2} height="28px" aria-label="Carregando título" />

            {/* Title description */}
            <Skeleton width="20ch" intensity={2} height="16px" aria-label="carregando descrição" />
          </Flex>
          <Stack space={space.large}>
            <CardContainer variant="outlined">
              <ListItem
                content={
                  <Flex flexDirection="column" gap={space.xsmall}>
                    <Skeleton width="12ch" height="16px" aria-label="Carregando parcelas" />
                    <Skeleton width="10ch" height="28px" aria-label="Carregando valor" />
                  </Flex>
                }
                trailing={<Skeleton width="10ch" height="28px" />}
              />
              <Divider />
              <ListItem content={<Skeleton width="10ch" height="20px" />} />
            </CardContainer>
            <CardContainer variant="outlined">
              <ListItem
                content={
                  <Flex flexDirection="column" gap={space.xsmall}>
                    <Skeleton width="12ch" height="16px" aria-label="Carregando parcelas" />
                    <Skeleton width="10ch" height="28px" aria-label="Carregando valor" />
                  </Flex>
                }
                trailing={<Skeleton width="10ch" height="28px" />}
              />
              <Divider />
              <ListItem
                content={
                  <Flex flexDirection="column" gap={space.xsmall}>
                    <Skeleton width="20ch" height="28px" aria-label="Carregando parcelas" />
                  </Flex>
                }
                trailing={<Skeleton width="7ch" height="28px" />}
              />
              <Divider />
              <ListItem content={<Skeleton width="10ch" height="20px" />} />
            </CardContainer>
          </Stack>
        </CardContainer>
      </PageContainer>
    </Flex>
  )
})

export function RefinancingFlowLoading({
  withPageWrapper,
  withNavigationBarSkeleton,
  withBreadcrumbSkeleton
}: {
  withPageWrapper?: boolean
  withNavigationBarSkeleton?: boolean
  withBreadcrumbSkeleton?: boolean
}) {
  if (withPageWrapper === true)
    return (
      <>
        <Flex flexDirection="column">
          {withNavigationBarSkeleton && (
            <Skeleton
              width="100%"
              height="65px"
              intensity={1}
              borderBottom={`var(--comp-navigation-bar-border-bottom-width) var(--comp-navigation-bar-border-bottom-style) var(--comp-navigation-bar-border-bottom-color)`}
              style={{ borderRadius: 0 }}
            />
          )}
        </Flex>
        <SharedPageContainerWrapper>
          <RefinancingSkeleton withBreadcrumbSkeleton={withBreadcrumbSkeleton} />
        </SharedPageContainerWrapper>
      </>
    )
  return <RefinancingSkeleton withBreadcrumbSkeleton={withBreadcrumbSkeleton} />
}
