import { useEffect, useState } from 'react'
import { Stack } from '@dlpco/fluid-layout'
import { Divider, GroupHeader, IconButton, ListItem, Text } from '@stone-payments/jade'
import jadeTheme from '@stone-payments/jade-design-tokens/cjs/stone'
import styled from 'styled-components'

import { Box, Flex } from '~/domains/platform/design-system'

import { boldTextParser } from '../../shared/helpers/bold-text-parser'
import { StatusTag } from '../../shared/jade-status-color-map'
import { type LoanDetailsInstallment } from '../entities'

const themeColors = jadeTheme.theme.color

const InstallmentNumber = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${themeColors.background['neutral-subtle']};
`

type LoanDetailsJadeInstallmentListProps = {
  isLoanSettled: boolean
  currentInstallment?: LoanDetailsInstallment
  setCurrentInstallment: (installment: LoanDetailsInstallment) => void
  installmentList: LoanDetailsInstallment[]
}

export function LoanDetailsJadeInstallmentList({
  isLoanSettled,
  currentInstallment,
  setCurrentInstallment,
  installmentList
}: LoanDetailsJadeInstallmentListProps) {
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 5
  const totalPages = Math.ceil(installmentList.length / itemsPerPage)
  const installmentSlice = installmentList.slice(itemsPerPage * (currentPage - 1), itemsPerPage * currentPage)

  useEffect(() => {
    if (!isLoanSettled) {
      const installmentToSet = installmentList.find(item => item.isCurrentInstallment) as LoanDetailsInstallment

      setCurrentInstallment(installmentToSet)
      setCurrentPage(Math.ceil(installmentToSet.number / itemsPerPage))
    }
  }, [])

  return (
    <>
      <Stack space="0px">
        <GroupHeader variant="overline" title="Lista de parcelas" />
        {installmentSlice.map((item, index) => (
          <Box key={'installment' + item.number.toString() + index}>
            <ListItem
              leading={<InstallmentNumber>{item.number}</InstallmentNumber>}
              content={
                <Flex flexDirection="column">
                  <Text variant="text-medium">{item.initialAmount.description}</Text>
                  <Text variant="text-small" color="medium">
                    {boldTextParser(item.information || '')}
                  </Text>
                </Flex>
              }
              trailing={<StatusTag status={item.status.value} description={item.status.description} />}
              variant={currentInstallment?.number === item.number ? 'active' : 'neutral'}
              onClick={() => setCurrentInstallment(item)}
            />
            <Divider />
          </Box>
        ))}
        <Divider />
      </Stack>

      <Flex justifyContent="center" p="0.5rem 0 1rem" gap="1rem">
        <IconButton
          icon="chevron-left"
          variant="neutral-subtle"
          size="medium"
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        />
        <IconButton
          icon="chevron-right"
          variant="neutral-subtle"
          size="medium"
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage(currentPage + 1)}
        />
      </Flex>
    </>
  )
}
