import { useEffect } from 'react'

const Keys = {
  Backspace: 'Backspace',
  Tab: 'Tab',
  Enter: 'Enter',
  Shift: 'Shift',
  Control: 'Control',
  Alt: 'Alt',
  Pause: 'Pause',
  CapsLock: 'CapsLock',
  Escape: 'Escape',
  Space: ' ',
  PageUp: 'PageUp',
  PageDown: 'PageDown',
  End: 'End',
  Home: 'Home',
  LeftArrow: 'ArrowLeft',
  UpArrow: 'ArrowUp',
  RightArrow: 'ArrowRight',
  DownArrow: 'ArrowDown',
  Insert: 'Insert',
  Delete: 'Delete',
  Meta: 'Meta',
  Key0: '0',
  Key1: '1',
  Key2: '2',
  Key3: '3',
  Key4: '4',
  Key5: '5',
  Key6: '6',
  Key7: '7',
  Key8: '8',
  Key9: '9',
  KeyA: 'a',
  KeyB: 'b',
  KeyC: 'c',
  KeyD: 'd',
  KeyE: 'e',
  KeyF: 'f',
  KeyG: 'g',
  KeyH: 'h',
  KeyI: 'i',
  KeyJ: 'j',
  KeyK: 'k',
  KeyL: 'l',
  KeyM: 'm',
  KeyN: 'n',
  KeyO: 'o',
  KeyP: 'p',
  KeyQ: 'q',
  KeyR: 'r',
  KeyS: 's',
  KeyT: 't',
  KeyU: 'u',
  KeyV: 'v',
  KeyW: 'w',
  KeyX: 'x',
  KeyY: 'y',
  KeyZ: 'z',
  LeftMeta: 'Meta',
  RightMeta: 'Meta',
  Select: 'Select',
  Numpad0: '0',
  Numpad1: '1',
  Numpad2: '2',
  Numpad3: '3',
  Numpad4: '4',
  Numpad5: '5',
  Numpad6: '6',
  Numpad7: '7',
  Numpad8: '8',
  Numpad9: '9',
  Multiply: '*',
  Add: '+',
  Subtract: '-',
  Decimal: '.',
  Divide: '/',
  F1: 'F1',
  F2: 'F2',
  F3: 'F3',
  F4: 'F4',
  F5: 'F5',
  F6: 'F6',
  F7: 'F7',
  F8: 'F8',
  F9: 'F9',
  F10: 'F10',
  F11: 'F11',
  F12: 'F12',
  NumLock: 'NumLock',
  ScrollLock: 'ScrollLock',
  Semicolon: ';',
  Equals: '=',
  Comma: ',',
  Dash: '-',
  Period: '.',
  ForwardSlash: '/',
  GraveAccent: '`',
  OpenBracket: '[',
  BackSlash: '\\',
  CloseBracket: ']',
  Quote: "'"
}

/**
 * Represents a key press item containing keys to listen for and the corresponding event to trigger.
 */
export interface KeyPressItem {
  keys: Array<keyof typeof Keys>
  event: (event: KeyboardEvent) => void
  preventDefault?: boolean
}

/**
 * Checks if the combination of keys matches the current event.
 * @param event - The keyboard event to check against.
 * @param keys - The keys to compare against.
 * @returns Whether the combination of keys matches the event.
 */
function checkCombination(
  event: KeyboardEvent,
  keys: Array<keyof typeof Keys>
): boolean {
  return keys.every(key => {
    if (key === 'Meta') return event.metaKey
    if (key === 'Control') return event.ctrlKey
    if (key === 'Shift') return event.shiftKey
    if (key === 'Alt') return event.altKey
    return event.key === Keys[key]
  })
}

/**
 * A hook for handling key presses.
 * @param keyPressItems - The array of key press items to listen for.
 * @param tagsToIgnore - Tags to ignore key presses on.
 * @param triggerOnContentEditable - Whether to trigger key presses on content editable elements.
 */
export function useKeyPress({
  keyPressItems,
  tagsToIgnore = ['INPUT', 'TEXTAREA', 'SELECT'],
  triggerOnContentEditable = false
}: {
  keyPressItems: KeyPressItem[]
  tagsToIgnore?: string[]
  triggerOnContentEditable?: boolean
}) {
  useEffect(() => {
    const keydownListener = (event: KeyboardEvent) => {
      for (const keyPressItem of keyPressItems) {
        const {
          keys,
          event: triggerEvent,
          preventDefault = true
        } = keyPressItem
        if (
          checkCombination(event, keys) &&
          shouldFireEvent(event, tagsToIgnore, triggerOnContentEditable)
        ) {
          if (preventDefault) {
            event.preventDefault()
          }

          triggerEvent(event)
        }
      }
    }

    window.document.addEventListener('keydown', keydownListener)
    return () => window.document.removeEventListener('keydown', keydownListener)
  }, [keyPressItems, tagsToIgnore, triggerOnContentEditable])
}

/**
 * Determines whether an event should trigger based on the element it's targeting and the provided conditions.
 * @param event - The keyboard event.
 * @param tagsToIgnore - Tags to ignore key presses on.
 * @param triggerOnContentEditable - Whether to trigger key presses on content editable elements.
 * @returns Whether the event should trigger.
 */
function shouldFireEvent(
  event: KeyboardEvent,
  tagsToIgnore: string[],
  triggerOnContentEditable: boolean
): boolean {
  const target = event.target as HTMLElement
  return !(
    (target.isContentEditable && !triggerOnContentEditable) ||
    tagsToIgnore.includes(target.tagName)
  )
}
