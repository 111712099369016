import { type ScenarioMock } from '~/scaffold/mocks/types/mock-flags-types'

import {
  dueSoonInstallmentMock,
  futureInstallmentWithoutPaymentMock,
  futureInstallmentWithPaymentMock,
  openInstallmentWithoutPaymentMock,
  openInstallmentWithPaymentMock,
  overdueInstalmentMock,
  settledInstallmentMock
} from './loan-installment-details-mock-content'

export const loanInstallmentDetails: ScenarioMock[] = [
  {
    scenario: 'Parcela futura sem pagamento',
    mocks: [
      {
        method: 'GET',
        url: '**/experience-bff-service/management/api/web/v1/loans/**/installments/**/details',
        status: 200,
        delay: 200,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(futureInstallmentWithoutPaymentMock)
      }
    ]
  },
  {
    scenario: 'Parcela futura com pagamento',
    mocks: [
      {
        method: 'GET',
        url: '**/experience-bff-service/management/api/web/v1/loans/**/installments/**/details',
        status: 200,
        delay: 200,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(futureInstallmentWithPaymentMock)
      }
    ]
  },
  {
    scenario: 'Parcela aberta com pagamento',
    mocks: [
      {
        method: 'GET',
        url: '**/experience-bff-service/management/api/web/v1/loans/**/installments/**/details',
        status: 200,
        delay: 200,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(openInstallmentWithPaymentMock)
      }
    ]
  },
  {
    scenario: 'Parcela aberta sem pagamento',
    mocks: [
      {
        method: 'GET',
        url: '**/experience-bff-service/management/api/web/v1/loans/**/installments/**/details',
        status: 200,
        delay: 200,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(openInstallmentWithoutPaymentMock)
      }
    ]
  },
  {
    scenario: 'Parcela aberta com vence em breve',
    mocks: [
      {
        method: 'GET',
        url: '**/experience-bff-service/management/api/web/v1/loans/**/installments/**/details',
        status: 200,
        delay: 200,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(dueSoonInstallmentMock)
      }
    ]
  },
  {
    scenario: 'Parcela atrasada',
    mocks: [
      {
        method: 'GET',
        url: '**/experience-bff-service/management/api/web/v1/loans/**/installments/**/details',
        status: 200,
        delay: 200,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(overdueInstalmentMock)
      }
    ]
  },
  {
    scenario: 'Parcela quitada',
    mocks: [
      {
        method: 'GET',
        url: '**/experience-bff-service/management/api/web/v1/loans/**/installments/**/details',
        status: 200,
        delay: 200,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(settledInstallmentMock)
      }
    ]
  }
]
