import { type ButtonColor } from '@dlpco/ginga-components/cjs/components/button/props/types'

import { SharedCreditBanner } from '~/domains/credit/shared/components/credit-banner'
import BlackBird from '~/domains/platform/lib/blackbird'

import { type NotificationMaintenance } from '../entities'

const LoanHomeNotificationsBanners = ({ notifications }: { notifications: NotificationMaintenance[] }) => {
  return notifications.map(notification => {
    const notificationButton = notification?.button
    const notificationTitle = notification?.title ?? ''
    const notificationSubtitle = notification?.subtitle ?? ''
    return (
      <SharedCreditBanner
        key={notification.id}
        showButton={!!notification?.button}
        buttonText={notificationButton?.text}
        buttonAction={() => BlackBird.travelTo(notificationButton?.action as any)}
        title={notificationTitle}
        description={notificationSubtitle}
        iconName="round-info-outline"
        buttonColor={(notification?.variant || 'neutral') as ButtonColor}
        iconColor={notification?.variant || 'neutral'}
      />
    )
  })
}
export { LoanHomeNotificationsBanners }
