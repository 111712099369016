import { type ExperimentValues } from '@stone-payments/experiment/src/lib/types'
import { getAllExperiment } from 'shared/experiment'

import { type Entity } from '../../infra/deus-ex-machina/ports'
import { type MenuItem } from '../../layout/shared/entities'
import { sortMenuByPriority } from '../../layout/shared/helpers'

import { acquirerMenu } from './acquirer.menu'
import { bankingMenu, bankingMenuHighlights } from './banking.menu'
import { conversionMenu } from './conversion.menu'
import { creditMenu } from './credit.menu'
import { homeMenu } from './home.menu'
import { stoneAccountMenu } from './stone-account.menu'
import { swhMenu } from './swh-menu'

async function nav(entity: Entity<'acquirer'>, subjectId: string) {
  return sortMenuByPriority(await menuItems())

  async function menuItems() {
    const experimentData = await getAllExperiment(subjectId, true, true, {
      swh_account_document: entity?.document
    })

    return Promise.allSettled([
      bankingMenu(entity) as Promise<MenuItem[]>,
      homeMenu() as Promise<MenuItem[]>,
      acquirerMenu(entity, experimentData as ExperimentValues) as Promise<MenuItem[]>,
      creditMenu(entity) as Promise<MenuItem[]>,
      stoneAccountMenu(entity) as Promise<MenuItem[]>,
      conversionMenu(entity) as Promise<MenuItem[]>,
      swhMenu(entity, experimentData as ExperimentValues) as Promise<MenuItem[]>
    ]).then(results =>
      results
        .filter(
          (x: PromiseRejectedResult | PromiseSettledResult<MenuItem[]>): x is PromiseFulfilledResult<MenuItem[]> =>
            x.status === 'fulfilled'
        )
        .map(x => x.value)
        .flat()
    )
  }
}

export const menuConfig = { nav, highlights: bankingMenuHighlights }
