import React, { type ReactNode } from 'react'

import { type ExtractFirstParam, type Mutable, type TypeFromArray } from '~/lib/types'

type Condition = boolean | (() => boolean)

interface Conditional {
  (props: { condition: Condition; children?: ReactNode; render?: () => ReactNode }): JSX.Element | null
}

interface Choose {
  (props: { children: JSX.Element | JSX.Element[] }): JSX.Element | null
  When: Conditional
  Otherwise(props: Omit<ExtractFirstParam<Conditional>, 'condition'>): JSX.Element | null
}

const shouldPass = (condition: Condition) => (typeof condition === 'function' ? condition() : condition)

const childrenWrapper = (children: ReactNode | null) => React.createElement(React.Fragment, null, children)

/**
 * **Deprecated**: This component is now deprecated due to potential issues with TypeScript safety.
 *
 * If you think this component makes the code cleaner:
 * - Consider breaking the logic into **microfunctions** or **microcomponents** for better modularity and reusability.
 *
 * If you think this component is more beautiful than using ternaries:
 * - Please note that this component is **not TypeScript safe**. Using ternaries or explicit `if` statements with type checks is recommended to ensure better safety and clarity.
 *
 * If you want to avoid repeating conditional logic:
 * - Create **hooks** or **HOCs** to encapsulate conditional logic in a reusable way, keeping type safety and clarity.
 *
 * If you're already using this component:
 * - Consider refactoring to ensure conditions are explicitly verified and to avoid type casting.
 *
 * @deprecated Use ternaries or create conditional hooks for better readability and type safety.
 */
export const If: Conditional = ({ children, condition, render }) => {
  if (!shouldPass(condition)) return null

  return render ? childrenWrapper(render()) : childrenWrapper(children) ?? null
}

/**
 * **Deprecated**: This component is deprecated due to similar concerns with TypeScript safety and readability.
 *
 * If you think this component makes the code cleaner:
 * - Consider breaking the logic into **microfunctions** or **microcomponents** for better modularity and reusability.
 *
 * If you think this component is more beautiful than using ternaries:
 * - This component is **not TypeScript safe**. Prefer using ternaries or explicit `if` statements with type checks.
 *
 * If you want to avoid repeating conditional logic:
 * - Create **hooks** or **HOCs** to encapsulate conditional logic, keeping type safety and clarity.
 *
 * If you're already using this component:
 * - Refactor to avoid casting and ensure explicit type verification.
 *
 * @deprecated Use ternaries or create conditional hooks for better readability and type safety.
 */
export const Choose: Choose = ({ children }) => {
  let when: ReactNode | null = null
  let otherwise: ReactNode | null = null

  React.Children.forEach(children, children => {
    if (children.props.condition === undefined) {
      otherwise = children
    } else if (!when && shouldPass(children.props.condition)) {
      when = children
    }
  })
  return childrenWrapper(when || otherwise)
}

Choose.When = If

Choose.Otherwise = ({ children, render }) => (render ? childrenWrapper(render()) : childrenWrapper(children ?? null))

/**
 * @deprecated Use a simple map instead. It's more readable, easier to understand and typescript safe.
 * @example
 * ```tsx
 * {array.map((item, index) => <Component key={index} />)}
 * ```
 * @example
 * ```tsx
 * {array.map((item, index) => <Component key={index} {...item} />)}
 * ```
 */
export const For = <T extends readonly any[]>({
  render,
  of
}: {
  render(item: TypeFromArray<Mutable<T>>, index: number): JSX.Element
  of: T
}) => childrenWrapper(of.map(render))
