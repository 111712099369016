import { type ClipboardEvent, forwardRef } from 'react'
import { Text } from '@dlpco/ginga-stone'

import { If } from '../../lib/utilities-components'
import { Box, Flex } from '../index'

import { type InputProps } from './interface'
import { Adornment, InputContainer, StyledInput } from './styles'

/**
 *
 * @deprecated reference the InputField component of the jade design system.
 * @see [InputField](https://jade-web.pagar.me/docs/?path=/docs/data-entry-inputfield--docs)`
 */
export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    label,
    labelBottom,
    hasError,
    hasWarning,
    error,
    name,
    moreInfo,
    labelAdornment,
    labelColor,
    trimOnPaste,
    startAdornment,
    endAdornment,
    adornmentBackground,
    adornmentBorderless,
    lessAdormentPadding = false,
    limitCounter = false,
    onAdornmentClick,
    onPaste,
    ...restProps
  } = props

  return (
    <Flex flexDirection="column">
      <Box color={hasError ? 'danger' : 'darkGray'}>
        <Text
          as="label"
          color={labelColor ? labelColor : undefined}
          inheritColor={labelColor ? false : true}
          htmlFor={name}
          data-testid="label"
        >
          <Flex alignItems="center">
            {label}
            {labelAdornment}
          </Flex>
        </Text>
      </Box>
      <InputContainer>
        {startAdornment && (
          <Adornment
            backgroundColor={adornmentBackground}
            adornmentBorderless={adornmentBorderless}
            data-testid="start-adornment"
            onClick={onAdornmentClick}
            lessAdormentPadding={lessAdormentPadding}
          >
            {startAdornment}
          </Adornment>
        )}
        <StyledInput
          data-testid="input"
          fontSize={2}
          ref={ref}
          hasWarning={hasWarning}
          hasError={hasError}
          error={error}
          id={name}
          name={name}
          label={label}
          onPaste={(e: ClipboardEvent<HTMLInputElement>) => {
            if (trimOnPaste) {
              e.preventDefault()
              let pastedText = ''
              if ((window as any).clipboardData && (window as any).clipboardData.getData) {
                // FOR IE ONLY
                pastedText = (window as any).clipboardData.getData('Text')
              } else if (e.clipboardData && e.clipboardData.getData) {
                pastedText = e.clipboardData.getData('text/plain')
              }

              e.currentTarget.value = pastedText.trim()
            }
            if (onPaste) onPaste(e)
          }}
          {...restProps}
        />

        {endAdornment && (
          <Adornment
            adornmentBackground={adornmentBackground}
            adornmentBorderless={adornmentBorderless}
            data-testid="end-adornment"
            onClick={onAdornmentClick}
            hasError={hasError}
          >
            {endAdornment}
          </Adornment>
        )}
      </InputContainer>
      <Text color="neutral" size="xSmall">
        {moreInfo}
      </Text>
      <Flex justifyContent="space-between" marginTop="4px">
        <Box flex={1}>
          {error && (
            <Box color="red" pt={0}>
              <Text role="alert" size="small" style={hasWarning ? { color: '#D95E00' } : { color: 'inherit' }}>
                {error && error}
              </Text>
            </Box>
          )}
          {!error && labelBottom && (
            <Box pt={0}>
              <Text color="neutral" size="small">
                {labelBottom}
              </Text>
            </Box>
          )}
        </Box>
        <If condition={Boolean(limitCounter)}>
          <Text color="neutral" size="small">
            {restProps.value?.toString().length}/{limitCounter}
          </Text>
        </If>
      </Flex>
    </Flex>
  )
})
