import { httpClientCredit } from '~/domains/credit/shared/infra/http-client-credit'
import { useQueryRequest } from '~/ui/hooks/utils'
import { type UseQueryRequestOptions } from '~/ui/hooks/utils/service/use-query-request'

import { type LoanDetailsResponse } from '../entities'

function fetchLoanDetails({ loanId }: { loanId: string }) {
  return httpClientCredit<LoanDetailsResponse>({
    url: `/experience-bff-service/management/api/web/v1/loans/${loanId}/details`,
    method: 'GET'
  })
}

export function useLoanDetails(
  { loanId }: { loanId?: string },
  queryOptions?: UseQueryRequestOptions<LoanDetailsResponse>
) {
  const { data: response, ...restQuery } = useQueryRequest<LoanDetailsResponse>(
    ['useLoanDetails', loanId],
    () => fetchLoanDetails({ loanId: loanId || '' }),
    {
      ...queryOptions,
      enabled: Boolean((queryOptions?.enabled || queryOptions?.enabled === undefined) && loanId)
    }
  )

  const data = response?.data.content

  return {
    data,
    ...restQuery
  }
}
