import FocusLock from 'react-focus-lock'
import { useKey } from 'react-use'
import { IconButton, Text } from '@dlpco/ginga-stone'

import { Box, Flex } from '~/domains/platform/design-system'
import { If } from '~/domains/platform/lib/utilities-components'
import { useClickAway } from '~/ui/hooks/utils/ui/use-click-away'

import { type SideModalProps } from './interface'
import { ChildrenWrapper, HeaderWrapper, SideModalTitle, Wrapper } from './styles'

/**
 *
 * @deprecated reference the Drawer component of the jade design system.
 * @see [Drawer](https://jade-web.pagar.me/docs/?path=/docs/layout-structure-drawer--docs)
 */

export const SideModal = (props: SideModalProps) => {
  const {
    children,
    isOpen,
    toggle,
    notDismissible,
    title,
    subtitle,
    ignoreClickAway,
    clickAwayAmplitudeEvent,
    position = 'right',
    animation = 'slide_from_right',
    ariaLabel = 'Título',
    headerBorder
  } = props

  const close = () => toggle && toggle(false)

  useKey('Escape', close)

  const handleClickAway = () => {
    clickAwayAmplitudeEvent && clickAwayAmplitudeEvent()
    close()
  }

  const clickAwayRef = useClickAway(handleClickAway)

  return (
    <If
      condition={isOpen}
      render={() => {
        const visibleClassName = isOpen ? 'visible' : ''

        return (
          <FocusLock disabled={!isOpen}>
            <Wrapper
              ref={!ignoreClickAway ? clickAwayRef : undefined}
              className={visibleClassName}
              data-testid="side-modal"
              position={position}
              animation={animation}
            >
              <Flex flexDirection="column" width="100%" overflowY="auto" maxHeight="fill-avaible">
                <Flex height="100%" flexDirection="column">
                  <HeaderWrapper border={headerBorder}>
                    <Box width={1}>
                      <SideModalTitle aria-label={ariaLabel} data-testid="title">
                        {title}
                        <If condition={Boolean(subtitle)}>
                          <Box mt="0.25rem">
                            <Text color="neutral" size="small">
                              {subtitle}
                            </Text>
                          </Box>
                        </If>
                      </SideModalTitle>
                    </Box>
                    <If condition={Boolean(!notDismissible)}>
                      <Box width={1 / 8} mt="0.3rem" mr="0.3rem">
                        <IconButton
                          color="neutral"
                          icon="close-outline"
                          variant="contentOnly"
                          size="small"
                          shape="pill"
                          aria-label="Fechar"
                          onClick={() => close()}
                        />
                      </Box>
                    </If>
                  </HeaderWrapper>

                  <ChildrenWrapper>{children}</ChildrenWrapper>
                </Flex>
              </Flex>
            </Wrapper>
          </FocusLock>
        )
      }}
    />
  )
}
