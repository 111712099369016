import { type CSSProperties, type PropsWithChildren } from 'react'
import FocusLock from 'react-focus-lock'
import { useKey, useLockBodyScroll } from 'react-use'

import { Overlay } from '~/domains/platform/design-system/overlay/overlay'
import { If } from '~/domains/platform/lib/utilities-components'
import { useClickAway } from '~/ui/hooks/utils/ui/use-click-away'
import { theme } from '~/ui/theme'

import { ClientPortal } from '../../utils/client-portal'

import * as S from './styles'

/**
 *
 * @deprecated reference the Modal component of the jade design system.
 * @see [Modal](https://jade-web.pagar.me/docs/?path=/docs/layout-structure-modal--docs)`
 */

const Modal = ({
  children,
  isOpen,
  onClose,
  width,
  height,
  modalStyles
}: PropsWithChildren<{
  isOpen: boolean
  onClose?(): void
  width?: string
  height?: string
  modalStyles?: CSSProperties
}>) => {
  useLockBodyScroll(isOpen)
  useKey('Escape', onClose)

  const noop = () => {}

  const clickAwayRef = useClickAway(onClose ? onClose : noop)

  return (
    <ClientPortal selector="#modal">
      <If condition={isOpen}>
        <FocusLock disabled={!isOpen}>
          <Overlay zIndex={theme.zIndices.topMenu} />
          <S.Modal ref={clickAwayRef} width={width} height={height} style={modalStyles}>
            {children}
          </S.Modal>
        </FocusLock>
      </If>
    </ClientPortal>
  )
}

Modal.Header = (props: { children: JSX.Element | Array<JSX.Element> }) => {
  return <header>{props.children}</header>
}

Modal.Content = (props: { children: JSX.Element | Array<JSX.Element> }) => {
  return <main>{props.children}</main>
}

Modal.Footer = (props: { children: JSX.Element | Array<JSX.Element> }) => {
  return <footer>{props.children}</footer>
}

export { Modal }
