'use client'

import { type ReactNode, createContext, useContext, useRef } from 'react'
import { useStore } from 'zustand'

import { HttpClientModal } from './http-client-modal'
import { type HttpClientStore, httpClientStore } from './http-client-store'

type HttpClientStoreApi = typeof httpClientStore

const HttpClientContext = createContext<HttpClientStoreApi | undefined>(
  undefined
)

export function HttpClientProvider({ children }: { children: ReactNode }) {
  const storeRef = useRef<HttpClientStoreApi>(httpClientStore)
  return (
    <HttpClientContext.Provider value={storeRef.current}>
      {children}
      <HttpClientModal />
    </HttpClientContext.Provider>
  )
}

export function useHttpClient<T>(selector: (store: HttpClientStore) => T): T {
  const httpClientContext = useContext(HttpClientContext)

  if (!httpClientContext) {
    throw new Error('useHttpClient must be used within HttpClientProvider')
  }

  return useStore(httpClientContext, selector)
}
