import { httpClientCredit } from '~/domains/credit/shared/infra/http-client-credit'
import { useQueryRequest } from '~/ui/hooks/utils'
import { type UseQueryRequestOptions } from '~/ui/hooks/utils/service/use-query-request'

import { type InstallmentDetailsResponse } from '../entities'

interface UseInstallmentDetailsProps {
  loanId: string
  installmentNumber?: string
}

function fetchInstallmentDetails({ installmentNumber, loanId }: UseInstallmentDetailsProps) {
  return httpClientCredit<InstallmentDetailsResponse>({
    url: `/experience-bff-service/management/api/web/v1/loans/${loanId}/installments/${installmentNumber}/details`,
    method: 'GET'
  })
}

export function useInstallmentDetails(
  { installmentNumber, loanId }: UseInstallmentDetailsProps,
  queryOptions?: UseQueryRequestOptions<InstallmentDetailsResponse>
) {
  const { data: response, ...restQuery } = useQueryRequest<InstallmentDetailsResponse>(
    ['useInstallmentDetails', installmentNumber],
    () => fetchInstallmentDetails({ installmentNumber, loanId }),
    {
      ...queryOptions,
      enabled: Boolean(installmentNumber && loanId)
    }
  )

  const data = response?.data.content
  return {
    data,
    ...restQuery
  }
}
