import { saveAs } from 'file-saver'
import { type Promisable } from 'type-fest'

interface FileBlob {
  data: Promisable<any>
  name: string
}

/**
 * @deprecated Use functions from `shared/utilities/utility-file-download` instead.
 */
export const exportFile = async (file: FileBlob, saver = saveAs) => {
  const { name } = file

  let { data } = file
  data = await data

  saver(data, name)
}
