import { type SpotOffer, type SpotOfferCreationParams, httpClientCreditNegotiations } from '@credit-web/loans'

import { type UseQueryRequestOptions, useQueryRequest } from '~/ui/hooks/utils/service/use-query-request'

import { type ContentWrapped } from '../entities'

export type FetchSpotOfferReturn = ContentWrapped<SpotOffer>

export function fetchSpotOffer(spotOfferId: string) {
  return httpClientCreditNegotiations<FetchSpotOfferReturn>({
    url: `/experience-bff-service/api/web/v1/spot-offers/${spotOfferId}`,
    method: 'GET'
  })
}

export type SaveSpotOfferReturn = ContentWrapped<Partial<SpotOffer>>

export async function saveSpotOffer(spotOffer: SpotOfferCreationParams) {
  return await httpClientCreditNegotiations<SaveSpotOfferReturn>({
    url: `/experience-bff-service/api/web/v1/spot-offers`,
    method: 'POST',
    data: spotOffer
  })
}

export async function getSpotOfferById(spotOfferId: string) {
  const response = await fetchSpotOffer(spotOfferId)
  const { data: spotOffer } = response
  return spotOffer
}

export function useGetSpotOfferById(
  { spotOfferId }: { spotOfferId: string },
  queryOptions?: UseQueryRequestOptions<FetchSpotOfferReturn>
) {
  const { data: response, ...restQuery } = useQueryRequest<FetchSpotOfferReturn>(
    ['useGetSpotOfferByIdV2', spotOfferId],
    () => getSpotOfferById(spotOfferId),
    { ...queryOptions }
  )
  return { data: response?.data, ...restQuery }
}
