import { useEffect, useMemo, useRef, useState } from 'react'
import FocusLock from 'react-focus-lock'
import { Controller, useForm } from 'react-hook-form'
import { useToggle } from 'react-use'
import dynamic from 'next/dynamic'
import NextLink from 'next/link'
import { Cluster, Grid, Stack } from '@dlpco/fluid-layout'
import { Button, Icon, Radio } from '@dlpco/ginga-stone'
import camelCase from 'lodash.camelcase'

import { useMerchantFee } from '~/domains/acquirer/ports/hooks/use-merchant-fee'
import { usePrepayCondition } from '~/domains/acquirer/ports/hooks/use-prepay-condition'
import { type MerchantsContentProps } from '~/domains/acquirer/shared/entities/merchants'
import { useCustomerPrepayCondition } from '~/domains/acquirer/shared/hooks/use-customer-prepay-condition'
import { useFinancialPosition } from '~/domains/acquirer/shared/hooks/use-financial-position'
import { Heimdall } from '~/domains/platform/core/heimdall'
import { Box, Flex, Link, Text } from '~/domains/platform/design-system'
import { CustomSelect } from '~/domains/platform/design-system/custom-select/custom-select'
import { Dimmer } from '~/domains/platform/design-system/dimmer'
import { Divider } from '~/domains/platform/design-system/divider/divider'
import { FieldSet } from '~/domains/platform/design-system/fieldset/fieldset'
import { Loader } from '~/domains/platform/design-system/loader/loader'
import { MoneyInput } from '~/domains/platform/design-system/money-input/money-input'
import { Select } from '~/domains/platform/design-system/select/select'
import { type Entity } from '~/domains/platform/infra/deus-ex-machina/types'
import { logger } from '~/domains/platform/infra/monitoring/logger'
import { withInjectedProps } from '~/domains/platform/infra/page-enhancers/compositional/with-injected-props'
import { Choose, For, If } from '~/domains/platform/lib/utilities-components'
import { stringFormat } from '~/lib/helpers/utils/string-format'
import { saleSimulator } from '~/lib/services/acquirer/sales/sales'
import { type PurchaseStoneCode, type SimulateSales, type TerminalDevices } from '~/lib/types'
import { useScrollTo } from '~/ui/hooks/utils/ui/use-scroll-to'

import { saleSimulatorInstance } from './helper/analitica'
import { MoneyResume, StonecodeItem } from './partials'

const BrandIcon = dynamic<any>(() => import('@dlpco/brand').then(mod => mod.BrandIcon) as any)

type LabelType = { cardBrandId: number; brandName: string; product: string[] }

interface ContentFormData {
  amount: string
  label: string
  product: string
  prepaymentMethod: string
  installments?: string
  feesOn: string
}

interface ContentProps {
  labels: LabelType[]
  toggle: () => void
  handleSelect(selected?: PurchaseStoneCode): void
  currentStoneCode: PurchaseStoneCode
  terminalDevices?: TerminalDevices[]
  currentAccount: Entity
  merchants?: MerchantsContentProps[]
}

const initialInstallments = ['1x', '2x', '3x', '4x', '5x', '6x', '7x', '8x', '9x', '10x', '11x', '12x']
const higherInstallments = ['13x', '14x', '15x', '16x', '17x', '18x']
const brandWithHigherInstallments = ['MasterCard', 'Visa', 'Elo']

export function sanitizeBrandName(brandName: string) {
  const parsedBrandName = brandName === 'Hipercard' ? 'hiper' : brandName

  return camelCase(parsedBrandName)
}

const productDisabled = (labels: LabelType[], product: string, label?: string) => {
  if (labels?.length <= 0 || !label) {
    return false
  }

  const foundLabel = labels.find(l => l.brandName === label)

  return Boolean(foundLabel && !foundLabel.product.includes(product))
}

const cardFeeMap = new Map([
  ['1x', 1],
  ['2x', 2],
  ['3x', 2],
  ['4x', 2],
  ['5x', 2],
  ['6x', 2],
  ['7x', 3],
  ['8x', 3],
  ['9x', 3],
  ['10x', 3],
  ['11x', 3],
  ['12x', 3],
  ['13x', 6],
  ['14x', 6],
  ['15x', 6],
  ['16x', 6],
  ['17x', 6],
  ['18x', 6],
  ['debit', 5]
])

export function Content({
  labels,
  handleSelect,
  currentStoneCode,
  terminalDevices,
  currentAccount,
  merchants
}: ContentProps) {
  const {
    register,
    watch,
    control,
    handleSubmit,
    setValue,
    getValues,
    reset: resetProduct,
    formState: { isSubmitting, errors }
  } = useForm<ContentFormData>({
    defaultValues: {
      prepaymentMethod: 'none'
    }
  })

  const contentRef = useRef<HTMLFormElement>(null)

  const parentRef = contentRef.current?.parentElement?.parentElement || undefined

  const { scrollBottom } = useScrollTo(parentRef)

  const { fees } = useMerchantFee(currentStoneCode.stoneCode)

  const { customerPrepaymentCondition } = useCustomerPrepayCondition(currentStoneCode)
  const {
    data: financialPosition,
    isLoading: isFinancialPositionLoading,
    isFetched: isFinancialPositionLoadedWithSuccess
  } = useFinancialPosition(currentAccount.document, currentStoneCode.affiliationKey)

  const {
    data: prepayCondition,
    isLoading: isPrepayConditionLoading,
    isFetched: isPrepayConditionLoadedWithSuccess
  } = usePrepayCondition(currentAccount.document, currentStoneCode.affiliationKey)

  const isFinancialPositionReady = !isFinancialPositionLoading && isFinancialPositionLoadedWithSuccess
  const isPrepayConditionReady = !isPrepayConditionLoading && isPrepayConditionLoadedWithSuccess

  const isRegisterOn = Heimdall.pass(['prepay_register_on_stone_pf', 'prepay_register_on_stone_pj'], 'some')
  const hasHigherInstallmentsOn = Heimdall.pass(['18_installments_feature_pf', '18_installments_feature_pj'], 'some')

  const isBlockedByWarrantyRate = !isRegisterOn && financialPosition?.warrantyRate === 100

  const [simulation, setSimulation] = useState<SimulateSales>()
  const [installments, setInstallments] = useState<string[]>(initialInstallments)
  const [disableButton, setDisableButton] = useToggle(false)
  const [isStonefast, setIsStonefast] = useToggle(false)
  const { feesOn, prepaymentMethod, amount, label, product } = getValues()

  const values = simulation?.simulations?.find(
    simulation => simulation.feesOn === feesOn && simulation.prepaymentMethod === prepaymentMethod
  )

  /**@disclaimer This is necessary to BrandIcon work with [`withInjectedProps`](https://github.com/dlpco/dashboard/blob/master/src/ui/hocs/utils/compositional/with-injected-props.tsx)*/
  const BrandIconComponent = ({ id }: { id: number }) => {
    return <BrandIcon brandId={id} />
  }

  const BrandFactory = (id: number) => withInjectedProps({ id })(BrandIconComponent)

  const filteredLabels = labels.filter(l => Boolean(l.brandName))

  const labelsFields = filteredLabels.map(item => {
    return {
      text: item.brandName,
      value: item.brandName,
      icon: BrandFactory(item.cardBrandId)
    }
  })

  const StoneCodeInfo = (props: Pick<PurchaseStoneCode, 'fantasyName' | 'stoneCode'>) => (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" marginBottom="2rem">
        <StonecodeItem
          merchants={merchants}
          fantasyName={props.fantasyName}
          stoneCode={props.stoneCode}
          terminalDevices={terminalDevices}
        />
        <Button size="large" color="neutral" onClick={() => handleSelect(undefined)}>
          Trocar
        </Button>
      </Flex>
      <Divider />
    </Box>
  )

  const reset = () => {
    if (simulation) {
      setSimulation(undefined)
      setDisableButton(false)
    }
  }

  const watchLabel = watch('label')
  const watchPaymentMethod = watch('prepaymentMethod')
  const watchProduct = watch('product')
  const watchFeesOn = watch('feesOn')
  const selectedInstallments = watchProduct === 'debit' ? '1x' : watchProduct

  const isProductDebit = watchProduct === 'debit'
  const isFeesOnCardHolder = watchFeesOn === 'cardholder'
  const isFeesOnMerchant = watchFeesOn === 'merchant'
  const isPaymentMethodNone = watchPaymentMethod === 'none'
  const isPaymentMethodSpot = watchPaymentMethod === 'spot'
  const isPaymentMethodAutomatic = watchPaymentMethod === 'automatic'

  const hasPrepaidCredit = Heimdall.pass(['prepaid_246_pf', 'prepaid_246_pj'], 'some') && watchProduct === '1x'

  const moneyResumeFooterInfo = useMemo(() => {
    if (isPaymentMethodNone) {
      if (isProductDebit) {
        return 'Em 1 dia útil no débito'
      } else if (hasPrepaidCredit) {
        return 'Em 30 dias no crédito à vista ou 2 dias úteis no crédito pré-pago.'
      } else if (watchProduct === '1x') {
        return 'Em 30 dias no crédito à vista'
      } else {
        return 'Uma parcela a cada 30 dias no crédito parcelado'
      }
    } else if (isPaymentMethodSpot) {
      return 'Em 1 dia útil com antecipação pontual.'
    } else {
      return 'Em 1 dia útil com antecipação automática diária.'
    }
  }, [isPaymentMethodNone, isPaymentMethodSpot, isProductDebit, watchProduct])

  const isRavAllowed =
    !prepayCondition?.isBlocked && prepayCondition?.pricingType !== 'SalePlan' && !isBlockedByWarrantyRate

  useEffect(() => {
    if (brandWithHigherInstallments.includes(watchLabel) && hasHigherInstallmentsOn) {
      setInstallments(initialInstallments.concat(higherInstallments))
    } else {
      setInstallments(initialInstallments)
    }
  }, [labels, watchLabel, setValue, hasHigherInstallmentsOn])

  useEffect(() => {
    if (isProductDebit) {
      setValue('prepaymentMethod', 'none')
    }
  }, [setValue, isProductDebit])

  useEffect(() => {
    if (isStonefast) {
      setValue('prepaymentMethod', 'automatic')
    }
  }, [isStonefast, setValue])

  useEffect(() => {
    setIsStonefast(customerPrepaymentCondition?.pricingType === 'SalePlan')
  }, [])

  /**
   * @disclaimer in case to add the simulation in array of dependencies, it will results in a side effect that don"t display de result of simulation after api request
   */
  useEffect(() => {
    if (simulation) {
      setSimulation(undefined)
      setDisableButton(false)
    }
  }, [amount, label, product, feesOn, prepaymentMethod, setDisableButton])

  const onClickAntecipation = () => {
    saleSimulatorInstance.events.simulator.onTooltipSimulate()
  }

  const onClickSecondAntecipation = () => {
    saleSimulatorInstance.events.simulator.onSecondTooltipSimulate()
  }

  const onSubmit = async (payload: ContentFormData) => {
    const { amount, label, product: productIncoming } = payload
    const product = installments.includes(productIncoming) ? 'credit' : 'debit'

    saleSimulatorInstance.events.simulator.onClickSimulate({
      cardBrand: payload.label,
      paymentMethod: product,
      installmentsNumber: payload.product,
      antecipation: payload.prepaymentMethod,
      simulationType: payload.feesOn
    })

    const installmentsPayload = installments.includes(productIncoming) ? productIncoming.replace('x', '') : '1'

    try {
      const {
        data: { data }
      } = await saleSimulator({
        stoneCode: currentStoneCode?.stoneCode,
        amount: Number(stringFormat.normalizeAmount(amount) / 100),
        label: sanitizeBrandName(label),
        installments: installmentsPayload,
        product
      })
      setSimulation(data)
      setDisableButton(true)
      setTimeout(() => scrollBottom(), 500)
    } catch (error) {
      logger(error)
    }
  }
  const amountToCharge = values?.amountToCharge ? values?.amountToCharge * 100 : 0
  const amountToReceive = values?.amountToReceive ? values?.amountToReceive * 100 : 0
  const prepaymentFee = isPaymentMethodAutomatic
    ? simulation?.automaticPrepaymentRate || '0'
    : simulation?.spotPrepaymentRate || '0'
  const amountPerInstallment = stringFormat.currency(amountToCharge / Number(selectedInstallments?.replace('x', '')))

  const currentBrandId = labels.find(el => el.brandName === watchLabel)?.cardBrandId

  const transactionProfileId = cardFeeMap.get(String(watchProduct))

  const cardFee = fees
    .filter((el: any) => el.cardBrandId === currentBrandId)
    .find((el: any) => el.transactionProfileId === transactionProfileId)?.rate
  const amountWithCardFee = amountToCharge * (Number(cardFee) / 100)
  const amountWithPrepaymentFee = Math.abs(amountToCharge - amountToReceive) - amountWithCardFee

  const amountFormRegistration = register('amount', {
    required: 'Campo obrigatório',
    validate: value => stringFormat.normalizeAmount(value, 3) >= 1 || 'O valor mínimo é de R$ 0,01'
  })
  const productFormRegistration = register('product', { required: 'Campo obrigatório' })

  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={contentRef}>
      <If condition={isSubmitting || !isFinancialPositionReady || !isPrepayConditionReady}>
        <FocusLock>
          <Dimmer isVisible style={{ position: 'fixed' }}>
            <Loader />
          </Dimmer>
        </FocusLock>
      </If>
      <Flex flexDirection="column">
        <Box>
          <Stack space="2rem">
            <Text fontWeight="medium" color="darkGray" fontSize="xLarge">
              Descubra antes de vender quanto você vai receber por cada venda.
            </Text>
            <StoneCodeInfo fantasyName={currentStoneCode?.fantasyName} stoneCode={currentStoneCode?.stoneCode} />
            <Grid gutter="2rem" min="30ch">
              <Cluster space="1rem">
                <Flex flexDirection="column">
                  <MoneyInput
                    label="Valor da venda"
                    error={errors.amount?.message}
                    {...amountFormRegistration}
                    onBlur={event => {
                      amountFormRegistration.onBlur(event)
                      reset()
                    }}
                  />

                  <Controller
                    name="label"
                    control={control}
                    rules={{
                      required: 'Campo obrigatório'
                    }}
                    render={({ field }) => (
                      <CustomSelect
                        items={labelsFields}
                        label="Bandeira"
                        error={errors.label?.message}
                        {...field}
                        onChange={event => {
                          field.onChange(event)
                          reset()
                          resetProduct({
                            label: event.toString(),
                            product: ''
                          })
                        }}
                      />
                    )}
                  />

                  <Select
                    aria-label="Seleção de uma modalidade"
                    label="Modalidade"
                    error={errors.product && 'Campo obrigatório'}
                    {...productFormRegistration}
                    onChange={event => {
                      productFormRegistration.onChange(event)
                      reset()
                    }}
                  >
                    <option key="" value="" disabled selected hidden>
                      Selecione
                    </option>

                    <option
                      value="debit"
                      hidden={productDisabled(labels, 'debit', watchLabel)}
                      disabled={productDisabled(labels, 'debit', watchLabel)}
                    >
                      Débito
                    </option>

                    <For
                      of={installments}
                      render={(item, key) => (
                        <option
                          key={key}
                          hidden={productDisabled(labels, 'credit', watchLabel)}
                          disabled={productDisabled(labels, 'credit', watchLabel)}
                          value={item}
                        >{`Crédito ${item}`}</option>
                      )}
                    />
                  </Select>
                </Flex>
              </Cluster>
              <Cluster space="0">
                <Flex flexDirection="column">
                  <Text color="black" fontWeight="bold">
                    Recebimento
                  </Text>
                  <Text color={isStonefast || !isRavAllowed ? 'orange' : 'mediumGray2'}>
                    <Choose>
                      <Choose.When condition={isStonefast}>
                        Você é cliente Stonefast, por isso, seu recebimento é com antecipação automática.
                      </Choose.When>
                      <Choose.When condition={!isRavAllowed}>
                        No momento não é possível antecipar o recebimento nesse canal de vendas.
                      </Choose.When>
                      <Choose.Otherwise>
                        Indique se você quer antecipar o recebimento e descubra as taxas pra isso.
                      </Choose.Otherwise>
                    </Choose>
                  </Text>
                  <Box mt={4}>
                    <FieldSet active={isPaymentMethodAutomatic}>
                      <Flex gap="1rem">
                        <Radio
                          id="prepaymentMethod"
                          {...register('prepaymentMethod')}
                          disabled={isProductDebit || !isRavAllowed}
                          value="automatic"
                          aria-label="Simular com antecipação automática"
                        />
                        <Text fontWeight="bold" color="darkGray">
                          Antecipação automática diária
                        </Text>
                      </Flex>
                      <Text color="mediumGray2" fontSize={1} p={1}>
                        Você só consegue simular vendas com a antecipação automática diária para receber em 1 dia útil.
                      </Text>
                    </FieldSet>
                    <FieldSet active={isPaymentMethodSpot}>
                      <Flex gap="1rem">
                        <Radio
                          id="prepaymentMethod"
                          {...register('prepaymentMethod')}
                          disabled={isProductDebit || isStonefast || !isRavAllowed}
                          value="spot"
                          aria-label="Simular com antecipação pontual"
                        />

                        <Text fontWeight="bold" color="darkGray">
                          Antecipação pontual
                        </Text>
                      </Flex>
                      <Text color="mediumGray2" fontSize={1} p={1}>
                        Receba em até 1 dia útil.
                      </Text>
                    </FieldSet>
                    <FieldSet active={isPaymentMethodNone}>
                      <Flex gap="1rem">
                        <Radio
                          id="prepaymentMethod"
                          {...register('prepaymentMethod')}
                          disabled={isStonefast}
                          value="none"
                          defaultChecked
                          aria-label="Simular sem nenhuma modalidade de antecipação"
                        />
                        <Text fontWeight="bold" color="darkGray">
                          Sem antecipação
                        </Text>
                      </Flex>
                      <Text color="mediumGray2" fontSize={1} p={1}>
                        Receba no tempo padrão da modalidade escolhida.
                      </Text>
                    </FieldSet>
                    <Cluster space="1rem">
                      <Flex alignItems="center">
                        <Icon use="round-alert-outline" color="neutral" size="large" />
                        <Box>
                          <Text color="mediumGray2" maxWidth="40ch">
                            No simulador{' '}
                            <Text fontWeight="bold" display="inline">
                              não
                            </Text>{' '}
                            são feitas antecipações. Pra antecipar, vá em
                            <NextLink href="/antecipacao" passHref legacyBehavior>
                              <Link onClick={onClickAntecipation}>
                                <Box fontWeight="bold" ml={2} as="span" color="stoneGreen">
                                  Antecipação.
                                </Box>
                              </Link>
                            </NextLink>
                          </Text>
                        </Box>
                      </Flex>
                    </Cluster>
                  </Box>
                </Flex>
              </Cluster>
              <Cluster space="0">
                <Flex flexDirection="column">
                  <Text color="black" fontWeight="bold">
                    Como você quer simular?
                  </Text>
                  <Text color="mediumGray2" fontWeight="medium">
                    Escolha entre as opções pra simular sua venda.
                  </Text>
                  <Box mt={4}>
                    <FieldSet active={isFeesOnCardHolder}>
                      <Flex gap="1rem">
                        <Radio
                          id="feesOn"
                          {...register('feesOn')}
                          value="cardholder"
                          aria-label="Opção para recebimento do valor integral"
                        />
                        <Text fontWeight="bold" color="darkGray">
                          Quero receber
                        </Text>
                      </Flex>
                      <Text color="mediumGray2" fontSize={1} p={1}>
                        Confira quanto você precisa cobrar pra receber o valor simulado integral.
                      </Text>
                    </FieldSet>
                    <FieldSet active={isFeesOnMerchant}>
                      <Flex gap="1rem">
                        <Radio id="feesOn" {...register('feesOn')} value="merchant" defaultChecked />
                        <Text fontWeight="bold" color="darkGray">
                          Quero cobrar
                        </Text>
                      </Flex>
                      <Text color="mediumGray2" fontSize={1} p={1}>
                        Confira quanto você vai receber após os descontos se cobrar o valor simulado.
                      </Text>
                    </FieldSet>
                  </Box>
                </Flex>
              </Cluster>
            </Grid>

            <Flex width="35ch" flexDirection="column">
              <Button size="large" type="submit" disabled={disableButton}>
                Simular Venda
              </Button>
            </Flex>

            <If condition={Boolean(simulation)}>
              <Stack space="3rem">
                <Text fontWeight="medium" fontSize="xLarge" color="darkGray">
                  Sua simulação
                </Text>

                <Cluster space="5rem">
                  <Flex alignItems="center">
                    <Box>
                      <MoneyResume
                        title="Você vai receber"
                        value={amountToReceive}
                        footer={moneyResumeFooterInfo}
                        isPrimary
                      />

                      <If condition={isPaymentMethodAutomatic}>
                        <Box mt={2}>
                          <Cluster space="1rem">
                            <Flex alignItems="center">
                              <Icon use="round-alert-outline" color="neutral" size="large" />
                              <Text
                                color="mediumGray2"
                                maxWidth="45ch"
                                aria-label="Explicações sobre a atencipação automática"
                              >
                                <Choose>
                                  <Choose.When condition={hasPrepaidCredit}>
                                    Se sua antecipação automática ativa é{' '}
                                    <Text fontWeight="bold" display="inline">
                                      mensal ou semanal
                                    </Text>
                                    , os valores e o prazo pra recebimento podem ser diferentes desta simulação. Além
                                    disso, as vendas de crédito pré-pago são pagas em até 2 dias úteis.
                                  </Choose.When>
                                  <Choose.When condition={customerPrepaymentCondition?.frequencyType !== 'Daily'}>
                                    Se sua antecipação automática ativa é{' '}
                                    <Text fontWeight="bold" display="inline">
                                      {customerPrepaymentCondition?.frequencyType !== 'Monthly' ? 'mensal' : 'semanal'},{' '}
                                    </Text>
                                    então os valores e o prazo pra recebimento podem ser diferentes desta simulação.
                                  </Choose.When>
                                  <Choose.Otherwise>
                                    Se sua antecipação automática não for diária, os valores simulados podem ser
                                    diferentes
                                  </Choose.Otherwise>
                                </Choose>
                              </Text>
                            </Flex>
                          </Cluster>
                        </Box>
                      </If>
                    </Box>

                    <MoneyResume
                      title="Se cobrar"
                      value={amountToCharge}
                      footer={
                        selectedInstallments !== '1x' ? `${selectedInstallments} de ${amountPerInstallment}` : `à vista`
                      }
                    />
                  </Flex>
                </Cluster>

                <Text fontWeight="medium" fontSize="xLarge" color="darkGray">
                  Descontos
                </Text>

                <Cluster space="5rem">
                  <Flex alignItems="center">
                    <Choose>
                      <Choose.When condition={Heimdall.pass(['stone_cet_fee'])}>
                        <MoneyResume
                          title="Taxa da venda"
                          value={amountWithCardFee + amountWithPrepaymentFee}
                          footer=""
                        />
                      </Choose.When>
                      <Choose.Otherwise>
                        <If condition={!isPaymentMethodNone}>
                          <MoneyResume
                            title={`Taxa de antecipação ${isPaymentMethodSpot ? 'pontual' : 'diária'}`}
                            value={amountWithPrepaymentFee}
                            footer={`${Number(prepaymentFee)?.toFixed(2)}% a.m.`}
                          />
                        </If>

                        <MoneyResume
                          title="Taxa de cartão"
                          value={amountWithCardFee}
                          footer={`${cardFee?.toFixed(2)}%`}
                        />
                      </Choose.Otherwise>
                    </Choose>
                  </Flex>
                </Cluster>

                <Flex alignItems="center">
                  <Icon use="round-alert-outline" color="neutral" size="large" />
                  <Box ml={2}>
                    <Text color="mediumGray2" fontSize="normal">
                      Se tiver garantia e saldo devedor, esses valores vão ser descontados do que você vai receber.
                      Consulte
                      <NextLink href="/antecipacao" passHref legacyBehavior>
                        <Link onClick={onClickSecondAntecipation}>
                          <Box fontWeight="bold" fontSize="normal" ml={2} as="span" color="stoneGreen">
                            Antecipação.
                          </Box>
                        </Link>
                      </NextLink>
                    </Text>
                  </Box>
                </Flex>
              </Stack>
            </If>
          </Stack>
        </Box>
      </Flex>
    </form>
  )
}
