import { type LoanDetailsResponse } from 'modules/credit/loans/src/lib/loan-details/entities'

export const multipleOverdueWithRenegotiationMock: LoanDetailsResponse = {
  content: {
    retention: {
      status: {
        description: 'pausado',
        value: 'paused',
        type: 'enum'
      },
      rate: {
        description: '19,00%',
        value: 0.19,
        type: 'percentage'
      }
    },
    generalInformation: {
      status: 'renegotiation_in_process',
      totalPaid: {
        description: 'R$ 215,00',
        value: 21500,
        type: 'money'
      },
      creditAmount: {
        description: 'R$ 5.000,00',
        value: 500000,
        type: 'money'
      },
      remainingAmount: {
        description: 'R$ 6.987,57',
        value: 698757,
        type: 'money'
      },
      interestEconomyAmount: {
        description: 'R$ 13,36',
        value: 1336,
        type: 'money'
      },
      taxAndInterestAmount: {
        description: 'R$ 63,25',
        value: 6325,
        type: 'money'
      }
    },
    installments: [
      {
        number: 1,
        status: {
          description: 'Em atraso',
          value: 'multiple_overdue',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 215,00',
          value: 21500,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 4.169,07',
          value: 416907,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 939,50',
          value: 93950,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 639,41',
          value: 63941,
          type: 'money'
        },
        daysOverdue: 105,
        isCurrentInstallment: true,
        installmentTitle: '4 parcelas não pagas',
        information: 'Em atraso há *105* dias'
      },
      {
        number: 2,
        status: {
          description: 'Em atraso',
          value: 'multiple_overdue',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 4.169,07',
          value: 416907,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 939,50',
          value: 93950,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 639,41',
          value: 63941,
          type: 'money'
        },
        daysOverdue: 77,
        isCurrentInstallment: false,
        installmentTitle: '4 parcelas não pagas',
        information: 'Em atraso há *77* dias'
      },
      {
        number: 3,
        status: {
          description: 'Em atraso',
          value: 'multiple_overdue',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 4.169,07',
          value: 416907,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 939,50',
          value: 93950,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 639,41',
          value: 63941,
          type: 'money'
        },
        daysOverdue: 46,
        isCurrentInstallment: false,
        installmentTitle: '4 parcelas não pagas',
        information: 'Em atraso há *46* dias'
      },
      {
        number: 4,
        status: {
          description: 'Em atraso',
          value: 'multiple_overdue',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 4.169,07',
          value: 416907,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 939,50',
          value: 93950,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 639,41',
          value: 63941,
          type: 'money'
        },
        daysOverdue: 14,
        isCurrentInstallment: false,
        installmentTitle: '4 parcelas não pagas',
        information: 'Em atraso há *14* dias'
      },
      {
        number: 5,
        status: {
          description: 'Aberta',
          value: 'open',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 939,50',
          value: 93950,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 939,50',
          value: 93950,
          type: 'money'
        },
        interestEconomyAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        daysOverdue: 0,
        isCurrentInstallment: false,
        installmentTitle: 'Parcela 5 de 7',
        information: 'Vence em *24 mar. 2025*'
      },
      {
        number: 6,
        status: {
          description: 'Futura',
          value: 'future',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 939,50',
          value: 93950,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 939,50',
          value: 93950,
          type: 'money'
        },
        interestEconomyAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        daysOverdue: 0,
        isCurrentInstallment: false,
        installmentTitle: 'Parcela 6 de 7',
        information: 'Abre em *24 mar. 2025*'
      },
      {
        number: 7,
        status: {
          description: 'Futura',
          value: 'future',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 939,50',
          value: 93950,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 939,50',
          value: 93950,
          type: 'money'
        },
        interestEconomyAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        daysOverdue: 0,
        isCurrentInstallment: false,
        installmentTitle: 'Parcela 7 de 7',
        information: 'Abre em *24 abr. 2025*'
      }
    ],
    aboutLoan: {
      informations: [
        {
          title: 'Valor do pedido',
          value: {
            description: 'R$ 5.000,00',
            value: 500000,
            type: 'money'
          },
          hasTooltip: false
        },
        {
          title: 'Valor total das parcelas',
          value: {
            description: 'R$ 6.576,52',
            value: 657652,
            type: 'money'
          },
          hasTooltip: false
        },
        {
          title: '7 parcelas de',
          value: {
            description: 'R$ 939,50',
            value: 93950,
            type: 'money'
          },
          hasTooltip: false
        },
        {
          title: 'Juros ao mês',
          value: {
            description: '7,00%',
            value: 0.07,
            type: 'percentage'
          },
          hasTooltip: false
        },
        {
          title: 'Juros ao ano',
          value: {
            description: '125,22%',
            value: 1.2522,
            type: 'percentage'
          },
          hasTooltip: false
        },
        {
          title: 'Contrato vence em',
          value: {
            description: '23 mai 2025',
            value: '2025-05-23',
            type: 'date'
          },
          hasTooltip: false
        },
        {
          title: 'IOF financiado',
          value: {
            description: 'R$ 63,25',
            value: 6325,
            type: 'money'
          },
          hasTooltip: true,
          tooltipType: 'iof'
        },
        {
          title: 'Custo Efetivo Total',
          value: {
            description: '134,53% ao ano',
            value: 1.3453,
            type: 'percentage'
          },
          hasTooltip: true,
          tooltipType: 'cet'
        }
      ],
      documents: [
        {
          name: 'Contrato',
          description: '',
          url: 'https://credit-sandbox.stone.com.br/contracts-service/api/v1/contracts/jr8funbts3ar6t25fhjwmkb6r'
        },
        {
          name: 'Descritivo de Crédito',
          description:
            'O Documento Descritivo de Crédito (DDC) é um relatório que detalha as condições do seu contrato de crédito.',
          url: 'https://credit-sandbox.stone.com.br/experience-bff-service/api/web/v1/loans/jr8fupe89n1c5p5yn9gxh7xez/ddc-pdf'
        }
      ]
    },
    id: 'jr8fupe89n1c5p5yn9gxh7xez',
    title: 'Capital de Giro de R$ 5.000,00',
    loanAmount: {
      description: 'R$ 5.000,00',
      value: 500000,
      type: 'money'
    },
    warranties: [
      {
        name: 'Nome do CPF 57159440072',
        document: '57159440072',
        address: 'Avenida dos Esportes, 111 CEP 30730-070, Padre Eustáquio, Belo Horizonte - MG',
        maritalStatus: 'Single'
      }
    ],
    notifications: [
      {
        variant: 'warning',
        title: 'Evite negativação e cobranças',
        subtitle: 'Quite o valor em atraso para evitar a aplicação das garantias.'
      },
      {
        variant: 'info',
        title: 'Renegociação em processamento',
        subtitle: 'Vamos te dar o retorno até 23 out. 2024'
      }
    ]
  },
  traceKey: 'jrbzzsyd3zjc78dhaupuhqc2x',
  isValid: true
}

export const failedRenegotiationLoanMock: LoanDetailsResponse = {
  content: {
    retention: {
      status: {
        description: 'ativado',
        value: 'enabled',
        type: 'enum'
      },
      rate: {
        description: '6,00%',
        value: 0.06,
        type: 'percentage'
      }
    },
    generalInformation: {
      status: 'overdue',
      totalPaid: {
        description: 'R$ 0,00',
        value: 0,
        type: 'money'
      },
      creditAmount: {
        description: 'R$ 6.000,00',
        value: 600000,
        type: 'money'
      },
      remainingAmount: {
        description: 'R$ 7.713,08',
        value: 771308,
        type: 'money'
      },
      taxAndInterestAmount: {
        description: 'R$ 68,09',
        value: 6809,
        type: 'money'
      }
    },
    installments: [
      {
        number: 1,
        status: {
          description: 'Em atraso',
          value: 'multiple_overdue',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 6.570,93',
          value: 657093,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 860,15',
          value: 86015,
          type: 'money'
        },
        daysOverdue: 144,
        isCurrentInstallment: true,
        installmentTitle: '5 parcelas não pagas',
        information: 'Em atraso há *144* dias'
      },
      {
        number: 2,
        status: {
          description: 'Em atraso',
          value: 'multiple_overdue',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 6.570,93',
          value: 657093,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 860,15',
          value: 86015,
          type: 'money'
        },
        daysOverdue: 112,
        isCurrentInstallment: false,
        installmentTitle: '5 parcelas não pagas',
        information: 'Em atraso há *112* dias'
      },
      {
        number: 3,
        status: {
          description: 'Em atraso',
          value: 'multiple_overdue',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 6.570,93',
          value: 657093,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 860,15',
          value: 86015,
          type: 'money'
        },
        daysOverdue: 83,
        isCurrentInstallment: false,
        installmentTitle: '5 parcelas não pagas',
        information: 'Em atraso há *83* dias'
      },
      {
        number: 4,
        status: {
          description: 'Em atraso',
          value: 'multiple_overdue',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 6.570,93',
          value: 657093,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 860,15',
          value: 86015,
          type: 'money'
        },
        daysOverdue: 52,
        isCurrentInstallment: false,
        installmentTitle: '5 parcelas não pagas',
        information: 'Em atraso há *52* dias'
      },
      {
        number: 5,
        status: {
          description: 'Em atraso',
          value: 'multiple_overdue',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 6.570,93',
          value: 657093,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 860,15',
          value: 86015,
          type: 'money'
        },
        daysOverdue: 21,
        isCurrentInstallment: false,
        installmentTitle: '5 parcelas não pagas',
        information: 'Em atraso há *21* dias'
      },
      {
        number: 6,
        status: {
          description: 'Aberta',
          value: 'open',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        interestEconomyAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        daysOverdue: 0,
        isCurrentInstallment: false,
        installmentTitle: 'Parcela 6 de 6',
        information: 'Vence em *17 mar. 2025*'
      }
    ],
    aboutLoan: {
      informations: [
        {
          title: 'Valor do pedido',
          value: {
            description: 'R$ 6.000,00',
            value: 600000,
            type: 'money'
          },
          hasTooltip: false
        },
        {
          title: 'Valor total das parcelas',
          value: {
            description: 'R$ 6.852,94',
            value: 685294,
            type: 'money'
          },
          hasTooltip: false
        },
        {
          title: '6 parcelas de',
          value: {
            description: 'R$ 1.142,16',
            value: 114216,
            type: 'money'
          },
          hasTooltip: false
        },
        {
          title: 'Juros ao mês',
          value: {
            description: '3,59%',
            value: 0.0359,
            type: 'percentage'
          },
          hasTooltip: false
        },
        {
          title: 'Juros ao ano',
          value: {
            description: '52,69%',
            value: 0.5269,
            type: 'percentage'
          },
          hasTooltip: false
        },
        {
          title: 'Contrato vence em',
          value: {
            description: '17 mar 2025',
            value: '2025-03-17',
            type: 'date'
          },
          hasTooltip: false
        },
        {
          title: 'IOF financiado',
          value: {
            description: 'R$ 68,09',
            value: 6809,
            type: 'money'
          },
          hasTooltip: true,
          tooltipType: 'iof'
        },
        {
          title: 'Custo Efetivo Total',
          value: {
            description: '58,91% ao ano',
            value: 0.5891,
            type: 'percentage'
          },
          hasTooltip: true,
          tooltipType: 'cet'
        }
      ],
      documents: [
        {
          name: 'Contrato',
          description: '',
          url: 'https://credit-sandbox.stone.com.br/contracts-service/api/v1/contracts/jr7km4xf3jkmgnx5fc1z373ae'
        },
        {
          name: 'Descritivo de Crédito',
          description:
            'O Documento Descritivo de Crédito (DDC) é um relatório que detalha as condições do seu contrato de crédito.',
          url: 'https://credit-sandbox.stone.com.br/experience-bff-service/api/web/v1/loans/jr7km5kmmq2bf6st48kjya4j6/ddc-pdf'
        }
      ]
    },
    id: 'jr7km5kmmq2bf6st48kjya4j6',
    title: 'Capital de Giro de R$ 6.000,00',
    loanAmount: {
      description: 'R$ 6.000,00',
      value: 600000,
      type: 'money'
    },
    warranties: [
      {
        name: 'Tarcísio Mendes Pereira',
        document: '36670151861',
        address: 'Rua Tomás Coelho, 123 CEP 20540-110, Vila Isabel, Rio de Janeiro - RJ',
        maritalStatus: 'Single'
      }
    ],
    notifications: [
      {
        variant: 'warning',
        title: 'Evite negativação e cobranças',
        subtitle: 'Quite o valor em atraso para evitar a aplicação das garantias.'
      },
      {
        variant: 'negative',
        title: 'A renegociação não aconteceu',
        subtitle: 'Converse com a Stone para saber mais.',
        button: {
          text: 'Entrar em contato',
          type: 'contact_us'
        }
      }
    ]
  },
  traceKey: 'jrc119mvd3uj616tcmhd76hbg',
  isValid: true
}

export const renegotiatedLoanMock = {
  content: {
    retention: {
      status: {
        description: 'ativado',
        value: 'enabled',
        type: 'enum'
      },
      rate: {
        description: '20,00%',
        value: 0.2,
        type: 'percentage'
      }
    },
    generalInformation: {
      status: 'renegotiated',
      totalPaid: {
        description: 'R$ 0,00',
        value: 0,
        type: 'money'
      },
      creditAmount: {
        description: 'R$ 20.228,64',
        value: 2022864,
        type: 'money'
      },
      remainingAmount: {
        description: 'R$ 22.508,06',
        value: 2250806,
        type: 'money'
      }
    },
    installments: [
      {
        number: 1,
        status: {
          description: 'Em atraso',
          value: 'multiple_overdue',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 22.508,06',
          value: 2250806,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 2.250,81',
          value: 225081,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        daysOverdue: 311,
        isCurrentInstallment: true,
        installmentTitle: '10 parcelas não pagas',
        information: 'Em atraso há *311* dias'
      },
      {
        number: 2,
        status: {
          description: 'Em atraso',
          value: 'multiple_overdue',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 22.508,06',
          value: 2250806,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 2.250,81',
          value: 225081,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        daysOverdue: 280,
        isCurrentInstallment: false,
        installmentTitle: '10 parcelas não pagas',
        information: 'Em atraso há *280* dias'
      },
      {
        number: 3,
        status: {
          description: 'Em atraso',
          value: 'multiple_overdue',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 22.508,06',
          value: 2250806,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 2.250,81',
          value: 225081,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        daysOverdue: 250,
        isCurrentInstallment: false,
        installmentTitle: '10 parcelas não pagas',
        information: 'Em atraso há *250* dias'
      },
      {
        number: 4,
        status: {
          description: 'Em atraso',
          value: 'multiple_overdue',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 22.508,06',
          value: 2250806,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 2.250,81',
          value: 225081,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        daysOverdue: 217,
        isCurrentInstallment: false,
        installmentTitle: '10 parcelas não pagas',
        information: 'Em atraso há *217* dias'
      },
      {
        number: 5,
        status: {
          description: 'Em atraso',
          value: 'multiple_overdue',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 22.508,06',
          value: 2250806,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 2.250,81',
          value: 225081,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        daysOverdue: 188,
        isCurrentInstallment: false,
        installmentTitle: '10 parcelas não pagas',
        information: 'Em atraso há *188* dias'
      },
      {
        number: 6,
        status: {
          description: 'Em atraso',
          value: 'multiple_overdue',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 22.508,06',
          value: 2250806,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 2.250,81',
          value: 225081,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        daysOverdue: 158,
        isCurrentInstallment: false,
        installmentTitle: '10 parcelas não pagas',
        information: 'Em atraso há *158* dias'
      },
      {
        number: 7,
        status: {
          description: 'Em atraso',
          value: 'multiple_overdue',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 22.508,06',
          value: 2250806,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 2.250,81',
          value: 225081,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        daysOverdue: 126,
        isCurrentInstallment: false,
        installmentTitle: '10 parcelas não pagas',
        information: 'Em atraso há *126* dias'
      },
      {
        number: 8,
        status: {
          description: 'Em atraso',
          value: 'multiple_overdue',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 22.508,06',
          value: 2250806,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 2.250,81',
          value: 225081,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        daysOverdue: 97,
        isCurrentInstallment: false,
        installmentTitle: '10 parcelas não pagas',
        information: 'Em atraso há *97* dias'
      },
      {
        number: 9,
        status: {
          description: 'Em atraso',
          value: 'multiple_overdue',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 22.508,06',
          value: 2250806,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 2.250,81',
          value: 225081,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        daysOverdue: 66,
        isCurrentInstallment: false,
        installmentTitle: '10 parcelas não pagas',
        information: 'Em atraso há *66* dias'
      },
      {
        number: 10,
        status: {
          description: 'Em atraso',
          value: 'multiple_overdue',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 22.508,06',
          value: 2250806,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 2.250,81',
          value: 225081,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        daysOverdue: 35,
        isCurrentInstallment: false,
        installmentTitle: '10 parcelas não pagas',
        information: 'Em atraso há *35* dias'
      }
    ],
    aboutLoan: {
      informations: [
        {
          title: 'Valor renegociado',
          value: {
            description: 'R$ 20.228,64',
            value: 2022864,
            type: 'money'
          },
          hasTooltip: false
        },
        {
          title: 'Valor total das parcelas',
          value: {
            description: 'R$ 22.508,06',
            value: 2250806,
            type: 'money'
          },
          hasTooltip: false
        },
        {
          title: '10 parcelas de',
          value: {
            description: 'R$ 2.250,81',
            value: 225081,
            type: 'money'
          },
          hasTooltip: false
        },
        {
          title: 'Juros ao mês',
          value: {
            description: '1,99%',
            value: 0.0199,
            type: 'percentage'
          },
          hasTooltip: false
        },
        {
          title: 'Juros ao ano',
          value: {
            description: '26,68%',
            value: 0.2668,
            type: 'percentage'
          },
          hasTooltip: false
        },
        {
          title: 'Contrato vence em',
          value: {
            description: '03 fev 2025',
            value: '2025-02-03',
            type: 'date'
          },
          hasTooltip: false
        },
        {
          title: 'IOF financiado',
          value: {
            description: 'R$ 0,00',
            value: 0,
            type: 'money'
          },
          hasTooltip: true,
          tooltipType: 'iof'
        },
        {
          title: 'Custo Efetivo Total',
          value: {
            description: '26,68% ao ano',
            value: 0.2668,
            type: 'percentage'
          },
          hasTooltip: true,
          tooltipType: 'cet'
        }
      ],
      documents: [
        {
          name: 'Contrato original',
          description: 'Renegociado em 1 abr. 2024',
          url: 'https://credit-sandbox.stone.com.br/contracts-service/api/v1/contracts/jr3kfbqmc1ag9asfvsn8wzg3u'
        },
        {
          name: 'Termos da renegociação',
          description: 'Aprovado em 1 abr. 2024',
          url: 'https://credit-sandbox.stone.com.br/contracts-service/api/v1/contracts/jr3kb1fv92xgb7vvy623ks64r'
        },
        {
          name: 'Descritivo de Crédito',
          description:
            'O Documento Descritivo de Crédito (DDC) é um relatório que detalha as condições do seu contrato de crédito.',
          url: 'https://credit-sandbox.stone.com.br/experience-bff-service/api/web/v1/loans/jr3kb41u9b6pbap4422e49ujw/ddc-pdf'
        }
      ]
    },
    id: 'jr3kb41u9b6pbap4422e49ujw',
    title: 'Renegociação de R$ 20.228,64',
    loanAmount: {
      description: 'R$ 20.228,64',
      value: 2022864,
      type: 'money'
    },
    warranties: [
      {
        name: 'Keane Fletcher',
        document: '14402914701',
        address: 'Rua Manuel Pereira da Costa, 76 CEP 21920-115, Taua, Rio de Janeiro - RJ',
        maritalStatus: 'Single'
      }
    ],
    notifications: [
      {
        variant: 'warning',
        title: 'Evite negativação e cobranças',
        subtitle: 'Quite o valor em atraso para evitar a aplicação das garantias.'
      },
      {
        variant: 'positive',
        title: 'A renegociação já está valendo',
        subtitle: 'As informações foram atualizadas. Você pode consultar as condições anteriores no seu histórico.'
      }
    ]
  },
  traceKey: 'jrc115c9jjtgag6ab1qc9cj3t',
  isValid: true
}

export const settledLoanMock: LoanDetailsResponse = {
  content: {
    retention: {
      status: {
        description: 'pausado',
        value: 'paused',
        type: 'enum'
      },
      rate: {
        description: '5,00%',
        value: 0.05,
        type: 'percentage'
      }
    },
    generalInformation: {
      status: 'settled',
      totalPaid: {
        description: '-R$ 5.367,27',
        value: -536727,
        type: 'money'
      },
      creditAmount: {
        description: 'R$ 5.000,00',
        value: 500000,
        type: 'money'
      },
      remainingAmount: {
        description: 'R$ 0,00',
        value: 0,
        type: 'money'
      },
      interestEconomyAmount: {
        description: '-R$ 137,17',
        value: -13717,
        type: 'money'
      },
      taxAndInterestAmount: {
        description: 'R$ 57,16',
        value: 5716,
        type: 'money'
      }
    },
    installments: [
      {
        number: 1,
        status: {
          description: 'Quitada',
          value: 'settled',
          type: 'enum'
        },
        paidAmount: {
          description: '-R$ 961,19',
          value: -96119,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 902,53',
          value: 90253,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        daysOverdue: 209,
        isCurrentInstallment: false,
        installmentTitle: 'Parcela 1 de 6',
        information: 'Quitada em *26 ago. 2024*'
      },
      {
        number: 2,
        status: {
          description: 'Quitada',
          value: 'settled',
          type: 'enum'
        },
        paidAmount: {
          description: '-R$ 933,13',
          value: -93313,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 902,53',
          value: 90253,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        daysOverdue: 178,
        isCurrentInstallment: false,
        installmentTitle: 'Parcela 2 de 6',
        information: 'Quitada em *26 ago. 2024*'
      },
      {
        number: 3,
        status: {
          description: 'Quitada',
          value: 'settled',
          type: 'enum'
        },
        paidAmount: {
          description: '-R$ 893,32',
          value: -89332,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 902,53',
          value: 90253,
          type: 'money'
        },
        interestEconomyAmount: {
          description: '-R$ 9,21',
          value: -921,
          type: 'money'
        },
        daysOverdue: 147,
        isCurrentInstallment: false,
        installmentTitle: 'Parcela 3 de 6',
        information: 'Quitada em *26 ago. 2024*'
      },
      {
        number: 4,
        status: {
          description: 'Quitada',
          value: 'settled',
          type: 'enum'
        },
        paidAmount: {
          description: '-R$ 876,11',
          value: -87611,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 902,53',
          value: 90253,
          type: 'money'
        },
        interestEconomyAmount: {
          description: '-R$ 26,42',
          value: -2642,
          type: 'money'
        },
        daysOverdue: 115,
        isCurrentInstallment: false,
        installmentTitle: 'Parcela 4 de 6',
        information: 'Quitada em *26 ago. 2024*'
      },
      {
        number: 5,
        status: {
          description: 'Quitada',
          value: 'settled',
          type: 'enum'
        },
        paidAmount: {
          description: '-R$ 859,95',
          value: -85995,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 902,53',
          value: 90253,
          type: 'money'
        },
        interestEconomyAmount: {
          description: '-R$ 42,58',
          value: -4258,
          type: 'money'
        },
        daysOverdue: 86,
        isCurrentInstallment: false,
        installmentTitle: 'Parcela 5 de 6',
        information: 'Quitada em *26 ago. 2024*'
      },
      {
        number: 6,
        status: {
          description: 'Quitada',
          value: 'settled',
          type: 'enum'
        },
        paidAmount: {
          description: '-R$ 843,57',
          value: -84357,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 902,53',
          value: 90253,
          type: 'money'
        },
        interestEconomyAmount: {
          description: '-R$ 58,96',
          value: -5896,
          type: 'money'
        },
        daysOverdue: 56,
        isCurrentInstallment: true,
        installmentTitle: 'Parcela 6 de 6',
        information: 'Quitada em *26 ago. 2024*'
      }
    ],
    aboutLoan: {
      informations: [
        {
          title: 'Valor do pedido',
          value: {
            description: 'R$ 5.000,00',
            value: 500000,
            type: 'money'
          },
          hasTooltip: false
        },
        {
          title: 'Valor total das parcelas',
          value: {
            description: 'R$ 5.415,17',
            value: 541517,
            type: 'money'
          },
          hasTooltip: false
        },
        {
          title: '6 parcelas de',
          value: {
            description: 'R$ 902,53',
            value: 90253,
            type: 'money'
          },
          hasTooltip: false
        },
        {
          title: 'Juros ao mês',
          value: {
            description: '1,99%',
            value: 0.0199,
            type: 'percentage'
          },
          hasTooltip: false
        },
        {
          title: 'Juros ao ano',
          value: {
            description: '26,68%',
            value: 0.2668,
            type: 'percentage'
          },
          hasTooltip: false
        },
        {
          title: 'Contrato vence em',
          value: {
            description: '12 dez 2024',
            value: '2024-12-12',
            type: 'date'
          },
          hasTooltip: false
        },
        {
          title: 'IOF financiado',
          value: {
            description: 'R$ 57,16',
            value: 5716,
            type: 'money'
          },
          hasTooltip: true,
          tooltipType: 'iof'
        },
        {
          title: 'Custo Efetivo Total',
          value: {
            description: '31,80% ao ano',
            value: 0.318,
            type: 'percentage'
          },
          hasTooltip: true,
          tooltipType: 'cet'
        }
      ],
      documents: [
        {
          name: 'Contrato',
          description: '',
          url: 'https://credit-sandbox.stone.com.br/contracts-service/api/v1/contracts/jr576uc1z3qq5sfb3j2sr5sn4'
        },
        {
          name: 'Descritivo de Crédito',
          description:
            'O Documento Descritivo de Crédito (DDC) é um relatório que detalha as condições do seu contrato de crédito.',
          url: 'https://credit-sandbox.stone.com.br/experience-bff-service/api/web/v1/loans/jr576v7ddszd6d6dnwua79tax/ddc-pdf'
        }
      ]
    },
    title: 'Capital de Giro de R$ 5.000,00',
    id: 'jr576v7ddszd6d6dnwua79tax',
    warranties: [
      {
        name: 'Nome do CPF 00505876337',
        document: '00505876337',
        address: 'Rua Alzimiro Jorge Martins, 01 CEP 24866-108, Grande Rio  Itambi, Itaborai - RJ',
        maritalStatus: 'Single'
      }
    ],
    notifications: [
      {
        variant: 'positive',
        title: 'Parabéns! Você quitou seu empréstimo.'
      }
    ]
  },
  traceKey: 'jrb7tc6f7b697mwcnge6k6w84',
  isValid: true
}

export const activeLoanMock: LoanDetailsResponse = {
  content: {
    retention: {
      status: {
        description: 'ativado',
        value: 'enabled',
        type: 'enum'
      },
      rate: {
        description: '5,00%',
        value: 0.05,
        type: 'percentage'
      }
    },
    generalInformation: {
      status: 'open_without_payment',
      totalPaid: {
        description: 'R$ 1.811,79',
        value: 181179,
        type: 'money'
      },
      creditAmount: {
        description: 'R$ 5.000,00',
        value: 500000,
        type: 'money'
      },
      remainingAmount: {
        description: 'R$ 3.607,74',
        value: 360774,
        type: 'money'
      },
      interestEconomyAmount: {
        description: 'R$ 0,46',
        value: 46,
        type: 'money'
      },
      taxAndInterestAmount: {
        description: 'R$ 56,74',
        value: 5674,
        type: 'money'
      }
    },
    installments: [
      {
        number: 1,
        status: {
          description: 'Quitada',
          value: 'settled',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 921,23',
          value: 92123,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 902,45',
          value: 90245,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        daysOverdue: 21,
        isCurrentInstallment: false,
        installmentTitle: 'Parcela 1 de 6',
        information: 'Quitada em *19 fev. 2025*'
      },
      {
        number: 2,
        status: {
          description: 'Quitada',
          value: 'settled',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 888,55',
          value: 88855,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 902,45',
          value: 90245,
          type: 'money'
        },
        interestEconomyAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        daysOverdue: 0,
        isCurrentInstallment: true,
        installmentTitle: 'Parcela 2 de 6',
        information: 'Quitada em *19 fev. 2025*'
      },
      {
        number: 3,
        status: {
          description: 'Futura',
          value: 'future',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 2,01',
          value: 201,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 900,37',
          value: 90037,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 902,45',
          value: 90245,
          type: 'money'
        },
        interestEconomyAmount: {
          description: 'R$ 0,07',
          value: 7,
          type: 'money'
        },
        daysOverdue: 0,
        isCurrentInstallment: false,
        installmentTitle: 'Parcela 3 de 6',
        information: 'Abre em *17 mar. 2025*'
      },
      {
        number: 4,
        status: {
          description: 'Futura',
          value: 'future',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 902,45',
          value: 90245,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 902,45',
          value: 90245,
          type: 'money'
        },
        interestEconomyAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        daysOverdue: 0,
        isCurrentInstallment: false,
        installmentTitle: 'Parcela 4 de 6',
        information: 'Abre em *17 abr. 2025*'
      },
      {
        number: 5,
        status: {
          description: 'Futura',
          value: 'future',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 902,45',
          value: 90245,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 902,45',
          value: 90245,
          type: 'money'
        },
        interestEconomyAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        daysOverdue: 0,
        isCurrentInstallment: false,
        installmentTitle: 'Parcela 5 de 6',
        information: 'Abre em *17 mai. 2025*'
      },
      {
        number: 6,
        status: {
          description: 'Futura',
          value: 'future',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 902,45',
          value: 90245,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 902,45',
          value: 90245,
          type: 'money'
        },
        interestEconomyAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        daysOverdue: 0,
        isCurrentInstallment: false,
        installmentTitle: 'Parcela 6 de 6',
        information: 'Abre em *17 jun. 2025*'
      }
    ],
    aboutLoan: {
      informations: [
        {
          title: 'Valor do pedido',
          value: {
            description: 'R$ 5.000,00',
            value: 500000,
            type: 'money'
          },
          hasTooltip: false
        },
        {
          title: 'Valor total das parcelas',
          value: {
            description: 'R$ 5.414,73',
            value: 541473,
            type: 'money'
          },
          hasTooltip: false
        },
        {
          title: '6 parcelas de',
          value: {
            description: 'R$ 902,45',
            value: 90245,
            type: 'money'
          },
          hasTooltip: false
        },
        {
          title: 'Juros ao mês',
          value: {
            description: '1,99%',
            value: 0.0199,
            type: 'percentage'
          },
          hasTooltip: false
        },
        {
          title: 'Juros ao ano',
          value: {
            description: '26,68%',
            value: 0.2668,
            type: 'percentage'
          },
          hasTooltip: false
        },
        {
          title: 'Contrato vence em',
          value: {
            description: '16 jul 2025',
            value: '2025-07-16',
            type: 'date'
          },
          hasTooltip: false
        },
        {
          title: 'IOF financiado',
          value: {
            description: 'R$ 56,74',
            value: 5674,
            type: 'money'
          },
          hasTooltip: true,
          tooltipType: 'iof'
        },
        {
          title: 'Custo Efetivo Total',
          value: {
            description: '31,76% ao ano',
            value: 0.3176,
            type: 'percentage'
          },
          hasTooltip: true,
          tooltipType: 'cet'
        }
      ],
      documents: [
        {
          name: 'Contrato',
          description: '',
          url: 'https://credit-sandbox.stone.com.br/contracts-service/api/v1/contracts/jrajjmgrtj9w8uf4m98z8a9p7'
        },
        {
          name: 'Descritivo de Crédito',
          description:
            'O Documento Descritivo de Crédito (DDC) é um relatório que detalha as condições do seu contrato de crédito.',
          url: 'https://credit-sandbox.stone.com.br/experience-bff-service/api/web/v1/loans/jrajjmvfzfqtgv2z19q3h6edc/ddc-pdf'
        }
      ]
    },
    id: 'jrajjmvfzfqtgv2z19q3h6edc',
    title: 'Capital de Giro de R$ 5.000,00',
    loanAmount: {
      description: 'R$ 5.000,00',
      value: 500000,
      type: 'money'
    },
    warranties: [
      {
        name: 'Keane Fletcher',
        document: '14402914701',
        address: 'Rua Manuel Pereira da Costa, 76 CEP 21920-115, Taua, Rio de Janeiro - RJ',
        maritalStatus: 'Single'
      }
    ],
    notifications: []
  },
  traceKey: 'jrc1111dsv9q6jt2pa1feryk3',
  isValid: true
}

export const activeLoanWithoutPaymentMock: LoanDetailsResponse = {
  content: {
    retention: {
      status: {
        description: 'ativado',
        value: 'enabled',
        type: 'enum'
      },
      rate: {
        description: '7,00%',
        value: 0.07,
        type: 'percentage'
      }
    },
    generalInformation: {
      status: 'overdue',
      totalPaid: {
        description: 'R$ 0,00',
        value: 0,
        type: 'money'
      },
      creditAmount: {
        description: 'R$ 6.000,00',
        value: 600000,
        type: 'money'
      },
      remainingAmount: {
        description: 'R$ 6.894,84',
        value: 689484,
        type: 'money'
      },
      taxAndInterestAmount: {
        description: 'R$ 68,09',
        value: 6809,
        type: 'money'
      }
    },
    installments: [
      {
        number: 1,
        status: {
          description: 'Em atraso',
          value: 'overdue',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 1.184,05',
          value: 118405,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 41,90',
          value: 4190,
          type: 'money'
        },
        daysOverdue: 11,
        isCurrentInstallment: true,
        installmentTitle: 'Parcela 1 de 6',
        information: 'Em atraso há *11* dias'
      },
      {
        number: 2,
        status: {
          description: 'Aberta',
          value: 'open',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        interestEconomyAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        daysOverdue: 0,
        isCurrentInstallment: false,
        installmentTitle: 'Parcela 2 de 6',
        information: 'Vence em *27 mar. 2025*'
      },
      {
        number: 3,
        status: {
          description: 'Futura',
          value: 'future',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        interestEconomyAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        daysOverdue: 0,
        isCurrentInstallment: false,
        installmentTitle: 'Parcela 3 de 6',
        information: 'Abre em *28 mar. 2025*'
      },
      {
        number: 4,
        status: {
          description: 'Futura',
          value: 'future',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        interestEconomyAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        daysOverdue: 0,
        isCurrentInstallment: false,
        installmentTitle: 'Parcela 4 de 6',
        information: 'Abre em *28 abr. 2025*'
      },
      {
        number: 5,
        status: {
          description: 'Futura',
          value: 'future',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        interestEconomyAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        daysOverdue: 0,
        isCurrentInstallment: false,
        installmentTitle: 'Parcela 5 de 6',
        information: 'Abre em *28 mai. 2025*'
      },
      {
        number: 6,
        status: {
          description: 'Futura',
          value: 'future',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        interestEconomyAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        daysOverdue: 0,
        isCurrentInstallment: false,
        installmentTitle: 'Parcela 6 de 6',
        information: 'Abre em *28 jun. 2025*'
      }
    ],
    aboutLoan: {
      informations: [
        {
          title: 'Valor do pedido',
          value: {
            description: 'R$ 6.000,00',
            value: 600000,
            type: 'money'
          },
          hasTooltip: false
        },
        {
          title: 'Valor total das parcelas',
          value: {
            description: 'R$ 6.852,94',
            value: 685294,
            type: 'money'
          },
          hasTooltip: false
        },
        {
          title: '6 parcelas de',
          value: {
            description: 'R$ 1.142,16',
            value: 114216,
            type: 'money'
          },
          hasTooltip: false
        },
        {
          title: 'Juros ao mês',
          value: {
            description: '3,59%',
            value: 0.0359,
            type: 'percentage'
          },
          hasTooltip: false
        },
        {
          title: 'Juros ao ano',
          value: {
            description: '52,69%',
            value: 0.5269,
            type: 'percentage'
          },
          hasTooltip: false
        },
        {
          title: 'Contrato vence em',
          value: {
            description: '27 jul 2025',
            value: '2025-07-27',
            type: 'date'
          },
          hasTooltip: false
        },
        {
          title: 'IOF financiado',
          value: {
            description: 'R$ 68,09',
            value: 6809,
            type: 'money'
          },
          hasTooltip: true,
          tooltipType: 'iof'
        },
        {
          title: 'Custo Efetivo Total',
          value: {
            description: '58,91% ao ano',
            value: 0.5891,
            type: 'percentage'
          },
          hasTooltip: true,
          tooltipType: 'cet'
        }
      ],
      documents: [
        {
          name: 'Contrato',
          description: '',
          url: 'https://credit-sandbox.stone.com.br/contracts-service/api/v1/contracts/jravvn63v9bxepmkaty4ghpzr'
        },
        {
          name: 'Descritivo de Crédito',
          description:
            'O Documento Descritivo de Crédito (DDC) é um relatório que detalha as condições do seu contrato de crédito.',
          url: 'https://credit-sandbox.stone.com.br/experience-bff-service/api/web/v1/loans/jravvnemnk7bacw2up6s8a21m/ddc-pdf'
        }
      ]
    },
    id: 'jravvnemnk7bacw2up6s8a21m',
    title: 'Capital de Giro de R$ 6.000,00',
    loanAmount: {
      description: 'R$ 6.000,00',
      value: 600000,
      type: 'money'
    },
    warranties: [
      {
        name: 'Águia Mor',
        document: '61289889031',
        address: 'Rua Urussui, 110 CEP 04542050, Itaim Bibi, Sao Paulo - SP',
        maritalStatus: 'Married'
      }
    ],
    notifications: [
      {
        variant: 'warning',
        title: 'Evite negativação e cobranças',
        subtitle: 'Quite o valor em atraso para evitar a aplicação das garantias.'
      }
    ]
  },
  traceKey: 'jrbzzykhadfzdq8hfdy4xws7h',
  isValid: true
}

export const multipleOverdueLoan: LoanDetailsResponse = {
  content: {
    retention: {
      status: {
        description: 'ativado',
        value: 'enabled',
        type: 'enum'
      },
      rate: {
        description: '6,00%',
        value: 0.06,
        type: 'percentage'
      }
    },
    generalInformation: {
      status: 'overdue',
      totalPaid: {
        description: 'R$ 0,00',
        value: 0,
        type: 'money'
      },
      creditAmount: {
        description: 'R$ 6.000,00',
        value: 600000,
        type: 'money'
      },
      remainingAmount: {
        description: 'R$ 7.713,08',
        value: 771308,
        type: 'money'
      },
      taxAndInterestAmount: {
        description: 'R$ 68,09',
        value: 6809,
        type: 'money'
      }
    },
    installments: [
      {
        number: 1,
        status: {
          description: 'Em atraso',
          value: 'multiple_overdue',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 6.570,93',
          value: 657093,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 860,15',
          value: 86015,
          type: 'money'
        },
        daysOverdue: 144,
        isCurrentInstallment: true,
        installmentTitle: '5 parcelas não pagas',
        information: 'Em atraso há *144* dias'
      },
      {
        number: 2,
        status: {
          description: 'Em atraso',
          value: 'multiple_overdue',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 6.570,93',
          value: 657093,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 860,15',
          value: 86015,
          type: 'money'
        },
        daysOverdue: 112,
        isCurrentInstallment: false,
        installmentTitle: '5 parcelas não pagas',
        information: 'Em atraso há *112* dias'
      },
      {
        number: 3,
        status: {
          description: 'Em atraso',
          value: 'multiple_overdue',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 6.570,93',
          value: 657093,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 860,15',
          value: 86015,
          type: 'money'
        },
        daysOverdue: 83,
        isCurrentInstallment: false,
        installmentTitle: '5 parcelas não pagas',
        information: 'Em atraso há *83* dias'
      },
      {
        number: 4,
        status: {
          description: 'Em atraso',
          value: 'multiple_overdue',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 6.570,93',
          value: 657093,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 860,15',
          value: 86015,
          type: 'money'
        },
        daysOverdue: 52,
        isCurrentInstallment: false,
        installmentTitle: '5 parcelas não pagas',
        information: 'Em atraso há *52* dias'
      },
      {
        number: 5,
        status: {
          description: 'Em atraso',
          value: 'multiple_overdue',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 6.570,93',
          value: 657093,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 860,15',
          value: 86015,
          type: 'money'
        },
        daysOverdue: 21,
        isCurrentInstallment: false,
        installmentTitle: '5 parcelas não pagas',
        information: 'Em atraso há *21* dias'
      },
      {
        number: 6,
        status: {
          description: 'Aberta',
          value: 'open',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        interestEconomyAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        daysOverdue: 0,
        isCurrentInstallment: false,
        installmentTitle: 'Parcela 6 de 6',
        information: 'Vence em *17 mar. 2025*'
      }
    ],
    aboutLoan: {
      informations: [
        {
          title: 'Valor do pedido',
          value: {
            description: 'R$ 6.000,00',
            value: 600000,
            type: 'money'
          },
          hasTooltip: false
        },
        {
          title: 'Valor total das parcelas',
          value: {
            description: 'R$ 6.852,94',
            value: 685294,
            type: 'money'
          },
          hasTooltip: false
        },
        {
          title: '6 parcelas de',
          value: {
            description: 'R$ 1.142,16',
            value: 114216,
            type: 'money'
          },
          hasTooltip: false
        },
        {
          title: 'Juros ao mês',
          value: {
            description: '3,59%',
            value: 0.0359,
            type: 'percentage'
          },
          hasTooltip: false
        },
        {
          title: 'Juros ao ano',
          value: {
            description: '52,69%',
            value: 0.5269,
            type: 'percentage'
          },
          hasTooltip: false
        },
        {
          title: 'Contrato vence em',
          value: {
            description: '17 mar 2025',
            value: '2025-03-17',
            type: 'date'
          },
          hasTooltip: false
        },
        {
          title: 'IOF financiado',
          value: {
            description: 'R$ 68,09',
            value: 6809,
            type: 'money'
          },
          hasTooltip: true,
          tooltipType: 'iof'
        },
        {
          title: 'Custo Efetivo Total',
          value: {
            description: '58,91% ao ano',
            value: 0.5891,
            type: 'percentage'
          },
          hasTooltip: true,
          tooltipType: 'cet'
        }
      ],
      documents: [
        {
          name: 'Contrato',
          description: '',
          url: 'https://credit-sandbox.stone.com.br/contracts-service/api/v1/contracts/jr7km4xf3jkmgnx5fc1z373ae'
        },
        {
          name: 'Descritivo de Crédito',
          description:
            'O Documento Descritivo de Crédito (DDC) é um relatório que detalha as condições do seu contrato de crédito.',
          url: 'https://credit-sandbox.stone.com.br/experience-bff-service/api/web/v1/loans/jr7km5kmmq2bf6st48kjya4j6/ddc-pdf'
        }
      ]
    },
    id: 'jr7km5kmmq2bf6st48kjya4j6',
    title: 'Capital de Giro de R$ 6.000,00',
    loanAmount: {
      description: 'R$ 6.000,00',
      value: 600000,
      type: 'money'
    },
    warranties: [
      {
        name: 'Tarcísio Mendes Pereira',
        document: '36670151861',
        address: 'Rua Tomás Coelho, 123 CEP 20540-110, Vila Isabel, Rio de Janeiro - RJ',
        maritalStatus: 'Single'
      }
    ],
    notifications: [
      {
        variant: 'warning',
        title: 'Evite negativação e cobranças',
        subtitle: 'Quite o valor em atraso para evitar a aplicação das garantias.'
      }
    ]
  },
  traceKey: 'jrbzzwvbcwbmcb15sgjgz66am',
  isValid: true
}

export const oneInstallmentOverdueLoan: LoanDetailsResponse = {
  content: {
    retention: {
      status: {
        description: 'ativado',
        value: 'enabled',
        type: 'enum'
      },
      rate: {
        description: '100%',
        value: 1.0,
        type: 'percentage'
      }
    },
    generalInformation: {
      status: 'overdue',
      totalPaid: {
        description: 'R$ 0,00',
        value: 0,
        type: 'money'
      },
      creditAmount: {
        description: 'R$ 6.000,00',
        value: 600000,
        type: 'money'
      },
      remainingAmount: {
        description: 'R$ 7.713,08',
        value: 771308,
        type: 'money'
      },
      taxAndInterestAmount: {
        description: 'R$ 860,15',
        value: 86015,
        type: 'money'
      }
    },
    installments: [
      {
        number: 1,
        status: {
          description: 'Em atraso',
          value: 'overdue',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 2.002,31',
          value: 200231,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 860,15',
          value: 86015,
          type: 'money'
        },
        daysOverdue: 10,
        isCurrentInstallment: true,
        installmentTitle: 'Parcela 1 de 6',
        information: 'Em atraso há *10 dias*'
      },
      {
        number: 2,
        status: {
          description: 'Futura',
          value: 'future',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 6.570,93',
          value: 657093,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 860,15',
          value: 86015,
          type: 'money'
        },
        daysOverdue: 0,
        isCurrentInstallment: false,
        installmentTitle: '5 parcelas não pagas',
        information: 'Abre em *1 mar 2024*'
      },
      {
        number: 3,
        status: {
          description: 'Futura',
          value: 'future',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 6.570,93',
          value: 657093,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 860,15',
          value: 86015,
          type: 'money'
        },
        daysOverdue: 0,
        isCurrentInstallment: false,
        installmentTitle: '5 parcelas não pagas',
        information: 'Abre em *1 abr 2024*'
      },
      {
        number: 4,
        status: {
          description: 'Futura',
          value: 'future',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 6.570,93',
          value: 657093,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 860,15',
          value: 86015,
          type: 'money'
        },
        daysOverdue: 0,
        isCurrentInstallment: false,
        installmentTitle: '5 parcelas não pagas',
        information: 'Abre em *1 jun 2024*'
      },
      {
        number: 5,
        status: {
          description: 'Futura',
          value: 'future',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 6.570,93',
          value: 657093,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 860,15',
          value: 86015,
          type: 'money'
        },
        daysOverdue: 0,
        isCurrentInstallment: false,
        installmentTitle: '5 parcelas não pagas',
        information: 'Abre em *1 jul 2024*'
      },
      {
        number: 6,
        status: {
          description: 'Futura',
          value: 'future',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        interestEconomyAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        daysOverdue: 0,
        isCurrentInstallment: false,
        installmentTitle: 'Parcela 6 de 6',
        information: 'Abre em *1 ago 2024*'
      }
    ],
    aboutLoan: {
      informations: [
        {
          title: 'Valor do pedido',
          value: {
            description: 'R$ 6.000,00',
            value: 600000,
            type: 'money'
          },
          hasTooltip: false
        },
        {
          title: 'Valor total das parcelas',
          value: {
            description: 'R$ 6.852,94',
            value: 685294,
            type: 'money'
          },
          hasTooltip: false
        },
        {
          title: '6 parcelas de',
          value: {
            description: 'R$ 1.142,16',
            value: 114216,
            type: 'money'
          },
          hasTooltip: false
        },
        {
          title: 'Juros ao mês',
          value: {
            description: '3,59%',
            value: 0.0359,
            type: 'percentage'
          },
          hasTooltip: false
        },
        {
          title: 'Juros ao ano',
          value: {
            description: '52,69%',
            value: 0.5269,
            type: 'percentage'
          },
          hasTooltip: false
        },
        {
          title: 'Contrato vence em',
          value: {
            description: '17 mar 2025',
            value: '2025-03-17',
            type: 'date'
          },
          hasTooltip: false
        },
        {
          title: 'IOF financiado',
          value: {
            description: 'R$ 68,09',
            value: 6809,
            type: 'money'
          },
          hasTooltip: true,
          tooltipType: 'iof'
        },
        {
          title: 'Custo Efetivo Total',
          value: {
            description: '58,91% ao ano',
            value: 0.5891,
            type: 'percentage'
          },
          hasTooltip: true,
          tooltipType: 'cet'
        }
      ],
      documents: [
        {
          name: 'Contrato',
          description: '',
          url: 'https://credit-sandbox.stone.com.br/contracts-service/api/v1/contracts/jr7km4xf3jkmgnx5fc1z373ae'
        },
        {
          name: 'Descritivo de Crédito',
          description:
            'O Documento Descritivo de Crédito (DDC) é um relatório que detalha as condições do seu contrato de crédito.',
          url: 'https://credit-sandbox.stone.com.br/experience-bff-service/api/web/v1/loans/jr7km5kmmq2bf6st48kjya4j6/ddc-pdf'
        }
      ]
    },
    id: 'jr7km5kmmq2bf6st48kjya4j6',
    title: 'Capital de Giro de R$ 6.000,00',
    loanAmount: {
      description: 'R$ 6.000,00',
      value: 600000,
      type: 'money'
    },
    warranties: [
      {
        name: 'Tarcísio Mendes Pereira',
        document: '36670151861',
        address: 'Rua Tomás Coelho, 123 CEP 20540-110, Vila Isabel, Rio de Janeiro - RJ',
        maritalStatus: 'Single'
      }
    ],
    notifications: [
      {
        variant: 'warning',
        title: 'Evite o acúmulo de juros e a negativação',
        subtitle:
          'O valor restante está aumentando por causa do atraso. Regularize a situação o quanto antes para evitar essa e outras consequências.',
        button: {
          type: 'overdue_drawer',
          text: 'Conferir detalhes'
        }
      }
    ]
  },
  traceKey: 'jrbzzwvbcwbmcb15sgjgz66am',
  isValid: true
}

export const oneSettledInstallmentMock: LoanDetailsResponse = {
  content: {
    retention: {
      status: {
        description: 'ativado',
        value: 'enabled',
        type: 'enum'
      },
      rate: {
        description: '6%%',
        value: 0.06,
        type: 'percentage'
      }
    },
    generalInformation: {
      status: 'overdue',
      totalPaid: {
        description: 'R$ 0,00',
        value: 0,
        type: 'money'
      },
      creditAmount: {
        description: 'R$ 6.000,00',
        value: 600000,
        type: 'money'
      },
      interestEconomyAmount: {
        description: 'R$ 245,16',
        value: 24516,
        type: 'money'
      },
      remainingAmount: {
        description: 'R$ 7.713,08',
        value: 771308,
        type: 'money'
      }
    },
    installments: [
      {
        number: 1,
        status: {
          description: 'Quitada',
          value: 'settled',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 0,00',
          value: 200231,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        interestEconomyAmount: {
          description: 'R$ 245,16',
          value: 24516,
          type: 'money'
        },

        daysOverdue: 10,
        isCurrentInstallment: true,
        installmentTitle: 'Parcela 1 de 6',
        information: 'Quitada em *1 fev 2024*'
      },
      {
        number: 2,
        status: {
          description: 'Futura',
          value: 'future',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 6.570,93',
          value: 657093,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 860,15',
          value: 86015,
          type: 'money'
        },
        daysOverdue: 0,
        isCurrentInstallment: false,
        installmentTitle: '5 parcelas não pagas',
        information: 'Abre em *1 mar 2024*'
      },
      {
        number: 3,
        status: {
          description: 'Futura',
          value: 'future',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 6.570,93',
          value: 657093,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 860,15',
          value: 86015,
          type: 'money'
        },
        daysOverdue: 0,
        isCurrentInstallment: false,
        installmentTitle: '5 parcelas não pagas',
        information: 'Abre em *1 abr 2024*'
      },
      {
        number: 4,
        status: {
          description: 'Futura',
          value: 'future',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 6.570,93',
          value: 657093,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 860,15',
          value: 86015,
          type: 'money'
        },
        daysOverdue: 0,
        isCurrentInstallment: false,
        installmentTitle: '5 parcelas não pagas',
        information: 'Abre em *1 jun 2024*'
      },
      {
        number: 5,
        status: {
          description: 'Futura',
          value: 'future',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 6.570,93',
          value: 657093,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        taxAndInterestAmount: {
          description: 'R$ 860,15',
          value: 86015,
          type: 'money'
        },
        daysOverdue: 0,
        isCurrentInstallment: false,
        installmentTitle: '5 parcelas não pagas',
        information: 'Abre em *1 jul 2024*'
      },
      {
        number: 6,
        status: {
          description: 'Futura',
          value: 'future',
          type: 'enum'
        },
        paidAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        remainingAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        initialAmount: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        interestEconomyAmount: {
          description: 'R$ 0,00',
          value: 0,
          type: 'money'
        },
        daysOverdue: 0,
        isCurrentInstallment: false,
        installmentTitle: 'Parcela 6 de 6',
        information: 'Abre em *1 ago 2024*'
      }
    ],
    aboutLoan: {
      informations: [
        {
          title: 'Valor do pedido',
          value: {
            description: 'R$ 6.000,00',
            value: 600000,
            type: 'money'
          },
          hasTooltip: false
        },
        {
          title: 'Valor total das parcelas',
          value: {
            description: 'R$ 6.852,94',
            value: 685294,
            type: 'money'
          },
          hasTooltip: false
        },
        {
          title: '6 parcelas de',
          value: {
            description: 'R$ 1.142,16',
            value: 114216,
            type: 'money'
          },
          hasTooltip: false
        },
        {
          title: 'Juros ao mês',
          value: {
            description: '3,59%',
            value: 0.0359,
            type: 'percentage'
          },
          hasTooltip: false
        },
        {
          title: 'Juros ao ano',
          value: {
            description: '52,69%',
            value: 0.5269,
            type: 'percentage'
          },
          hasTooltip: false
        },
        {
          title: 'Contrato vence em',
          value: {
            description: '17 mar 2025',
            value: '2025-03-17',
            type: 'date'
          },
          hasTooltip: false
        },
        {
          title: 'IOF financiado',
          value: {
            description: 'R$ 68,09',
            value: 6809,
            type: 'money'
          },
          hasTooltip: true,
          tooltipType: 'iof'
        },
        {
          title: 'Custo Efetivo Total',
          value: {
            description: '58,91% ao ano',
            value: 0.5891,
            type: 'percentage'
          },
          hasTooltip: true,
          tooltipType: 'cet'
        }
      ],
      documents: [
        {
          name: 'Contrato',
          description: '',
          url: 'https://credit-sandbox.stone.com.br/contracts-service/api/v1/contracts/jr7km4xf3jkmgnx5fc1z373ae'
        },
        {
          name: 'Descritivo de Crédito',
          description:
            'O Documento Descritivo de Crédito (DDC) é um relatório que detalha as condições do seu contrato de crédito.',
          url: 'https://credit-sandbox.stone.com.br/experience-bff-service/api/web/v1/loans/jr7km5kmmq2bf6st48kjya4j6/ddc-pdf'
        }
      ]
    },
    id: 'jr7km5kmmq2bf6st48kjya4j6',
    title: 'Capital de Giro de R$ 6.000,00',
    loanAmount: {
      description: 'R$ 6.000,00',
      value: 600000,
      type: 'money'
    },
    warranties: [
      {
        name: 'Tarcísio Mendes Pereira',
        document: '36670151861',
        address: 'Rua Tomás Coelho, 123 CEP 20540-110, Vila Isabel, Rio de Janeiro - RJ',
        maritalStatus: 'Single'
      }
    ],
    notifications: [
      {
        variant: 'neutral',
        title: '',
        subtitle: 'A próxima parcela abre em 1 mar 2024.'
      }
    ]
  },
  traceKey: 'jrbzzwvbcwbmcb15sgjgz66am',
  isValid: true
}
