import { useState } from 'react'
import { useRouter } from 'next/router'
import { Stack } from '@dlpco/fluid-layout'
import { Button, Divider, GroupHeader, Icon, ListItem, Text } from '@stone-payments/jade'
import jadeTheme from '@stone-payments/jade-design-tokens/cjs/stone'

import { Box, Flex } from '~/domains/platform/design-system'
import BlackBird from '~/domains/platform/lib/blackbird'
import { Choose, If } from '~/domains/platform/lib/utilities-components'

import { StatusTag } from '../../shared/jade-status-color-map'
import { type InstallmentStatus, type LoanDetailsInstallment } from '../entities'

import { ColorIndicator } from './loan-details-jade-color-indicator'
import { LoanDetailsJadeInterestEconomyDrawer } from './loan-details-jade-drawers'
import { DynamicProgressBar } from './loan-details-jade-dynamic-progressbar'

const themeColors = jadeTheme.theme.color

const getProgressBarItems = (installment: LoanDetailsInstallment) => {
  const progressBarItems = []

  const hasPaidAmountValue = installment.paidAmount && installment.paidAmount.value
  const hasInterestEconomyValue = installment.interestEconomyAmount && installment.interestEconomyAmount.value
  const hasTaxAndInterestValue = installment.taxAndInterestAmount && installment.taxAndInterestAmount.value

  // quitado
  if (hasPaidAmountValue) {
    progressBarItems.push({
      maxValue: installment.initialAmount.value,
      currentValue: installment.paidAmount.value || 0,
      surfaceColor: themeColors.background.positive
    })
  }

  // economia de juros
  if (hasInterestEconomyValue) {
    progressBarItems.push({
      maxValue: installment.initialAmount.value,
      currentValue: installment.interestEconomyAmount?.value || 0,
      surfaceColor: themeColors.background.info
    })
  }

  // multa e juros
  if (hasTaxAndInterestValue) {
    progressBarItems.push({
      maxValue: installment.initialAmount.value,
      currentValue: installment.taxAndInterestAmount?.value || 0,
      surfaceColor: themeColors.background.negative
    })
  }

  return progressBarItems
}

const getStatusText: Record<InstallmentStatus, (remainingAmount: string) => JSX.Element> = {
  open: (remainingAmount: string) => (
    <Text variant="heading-medium" color="medium">
      Restam{' '}
      <Text as="span" variant="heading-medium" color="high">
        {remainingAmount}
      </Text>{' '}
      para quitar a parcela
    </Text>
  ),
  future: (remainingAmount: string) => (
    <Text variant="heading-medium" color="medium">
      Restam{' '}
      <Text as="span" variant="heading-medium" color="high">
        {remainingAmount}
      </Text>{' '}
      para quitar a parcela
    </Text>
  ),
  overdue: (remainingAmount: string) => (
    <Text variant="heading-medium" color="medium">
      O valor total em atraso é de{' '}
      <Text as="span" variant="heading-medium" color="high">
        {remainingAmount}
      </Text>
    </Text>
  ),
  multiple_overdue: (remainingAmount: string) => (
    <Text variant="heading-medium" color="medium">
      O valor total em atraso é de{' '}
      <Text as="span" variant="heading-medium" color="high">
        {remainingAmount}
      </Text>
    </Text>
  ),
  settled: () => <Text variant="heading-medium">Parabéns! Você quitou a parcela</Text>,
  due_soon: (remainingAmount: string) => (
    <Text variant="heading-medium" color="medium">
      Restam{' '}
      <Text as="span" variant="heading-medium" color="high">
        {remainingAmount}
      </Text>{' '}
      para quitar a parcela
    </Text>
  )
}

export function LoanDetailsJadeCurrentInstallment({
  currentInstallment,
  isMultipleOverdue
}: {
  currentInstallment?: LoanDetailsInstallment
  isMultipleOverdue: boolean
}) {
  const [isInterestEconomyDrawerOpen, setIsInterestEconomyDrawerOpen] = useState<boolean>(false)
  const { loan: loanId }: { loan?: string } = useRouter().query

  if (!currentInstallment) return

  return (
    <>
      <Flex alignItems={'center'} justifyContent={'space-between'} pr="1rem">
        <GroupHeader variant="heading" title={currentInstallment.installmentTitle} style={{ paddingBottom: '20px' }} />
        <Box pb="0.5rem">
          <StatusTag
            status={currentInstallment.status.value}
            description={
              currentInstallment.status.value === 'overdue' || currentInstallment.status.value === 'multiple_overdue'
                ? `Em atraso há ${currentInstallment.daysOverdue}  dias`
                : currentInstallment.status.description
            }
          />
        </Box>
      </Flex>
      <Box p="0 1.25rem 1rem">
        <Text variant="heading-medium">
          {getStatusText[currentInstallment.status.value](currentInstallment.remainingAmount.description)}
        </Text>
      </Box>
      <Box p="0 1.25rem 1.5rem">
        <DynamicProgressBar
          style={{ height: '24px', borderRadius: '4px', backgroundColor: themeColors.background.body }}
          progressBarList={getProgressBarItems(currentInstallment)}
        />
      </Box>
      <Choose>
        <Choose.When condition={isMultipleOverdue}>
          <ListItem
            content={
              <Stack>
                <Text color="medium">Valor inicial das parcelas</Text>
                <Text color="medium" variant="text-small">
                  Soma do valor inicial das parcelas em atraso.
                </Text>
              </Stack>
            }
            trailing={<Text color="high">{currentInstallment.initialAmount.description}</Text>}
            variant="neutral"
          />
        </Choose.When>
        <Choose.Otherwise>
          <ListItem
            content={<Text color="medium">Valor inicial da parcela</Text>}
            trailing={<Text color="high">{currentInstallment.initialAmount.description}</Text>}
            variant="neutral"
          />
        </Choose.Otherwise>
      </Choose>

      <Divider />
      <Stack space="0.5rem" style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
        <ListItem
          leading={<ColorIndicator color={themeColors.background.positive} />}
          content={<Text color="medium">Quitado</Text>}
          trailing={<Text color="high">{currentInstallment.paidAmount.description}</Text>}
          variant="neutral"
        />
        <If condition={Boolean(currentInstallment.interestEconomyAmount)}>
          <ListItem
            leading={<ColorIndicator color={themeColors.background.info} />}
            content={
              <Flex alignItems="center" gap="0.25rem">
                <Text color="medium">Economia de juros</Text>
                <Icon
                  data-testId="infoDrawerOpen"
                  use="circle-info"
                  size="small"
                  onClick={() => setIsInterestEconomyDrawerOpen(true)}
                />
              </Flex>
            }
            trailing={<Text color="high">{currentInstallment.interestEconomyAmount?.description}</Text>}
            variant="neutral"
          />
        </If>

        <If condition={Boolean(currentInstallment.taxAndInterestAmount)}>
          <ListItem
            leading={<ColorIndicator color={themeColors.background.negative} />}
            content={<Text color="medium">Total de multa e juros</Text>}
            trailing={<Text color="high">{currentInstallment.taxAndInterestAmount?.description}</Text>}
            variant="neutral"
          />
        </If>
      </Stack>
      <Divider />
      <ListItem
        leading={<ColorIndicator color={themeColors.background.body} />}
        content={<Text color="medium">Restante</Text>}
        trailing={
          <Text color="high" weight="semibold">
            {currentInstallment.remainingAmount.description}
          </Text>
        }
        variant="neutral"
      />
      <Box p="0.75rem 1.25rem 1.25rem" width="100%">
        <Button
          variant="neutral-subtle"
          size="medium"
          style={{ width: '100%' }}
          onClick={() =>
            BlackBird.travelTo({
              pathname: '/credito/emprestimo/${loan}/parcela/${installment}',
              urlParams: { loan: loanId, installment: currentInstallment.number }
            })
          }
        >
          Resumo da parcela {currentInstallment.number}
        </Button>
      </Box>

      <LoanDetailsJadeInterestEconomyDrawer
        isOpen={isInterestEconomyDrawerOpen}
        setIsOpen={setIsInterestEconomyDrawerOpen}
      />
    </>
  )
}
