import { SpinnerIllustration } from 'design-elements/shared/illustrations'

import { WrapperLoader } from './styles'

interface LoaderProps {
  size?: number
  fill?: string
}

/**
 *
 * @deprecated reference the Spinner component of the jade design system.
 * @see [Spinner](https://jade-web.pagar.me/docs/?path=/docs/status-feedback-spinner--docs)`
 */
export const Loader = (props: LoaderProps) => {
  const { size, fill } = props

  return (
    <WrapperLoader data-testid="loader">
      <SpinnerIllustration fill={fill} width={size} height={size} />
    </WrapperLoader>
  )
}
