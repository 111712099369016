const MINIMUM_FRACTION_DIGITS = 2

interface FormatPercentageOptions {
  minimumFractionDigits?: number
  percentageSymbol?: boolean
}

/**
 * @deprecated Use `toPercentage` from `shared/utilities/utils-formatters` instead.
 */
export const formatPercentage = (
  value: number,
  { minimumFractionDigits = MINIMUM_FRACTION_DIGITS, percentageSymbol = false }: FormatPercentageOptions = {}
) => {
  const result = Intl.NumberFormat('pt-BR', { minimumFractionDigits }).format(value)

  if (percentageSymbol) {
    return `${result}%`
  }

  return result
}
