import { type TmPlugin } from '../types'

/**
 * @link [LocalizedFormat](https://day.js.org/docs/en/display/format#list-of-localized-formats)
 */
const allowedFormats = {
  brazilianPattern: 'DD/MM/YYYY',
  dateAndMonth: 'DD MMM',
  dateWithDaysOfTheWeek: 'dddd, DD MMM',
  dateOnly: 'DD',
  default: 'L',
  defaultApi: 'YYYY-MM-DD',
  extensiveBrazilianPattern: 'DD [de] MMMM [de] YYYY',
  fancy: 'DD MMM YYYY [•] HH:mm',
  fancyHour: 'DD MMM YYYY [às] HH:mm',
  fullDateAndHour: 'DD MMM YYYY - HH:mm',
  fullMonth: 'MMMM',
  fullYear: 'YYYY',
  hourOnly: 'HH:mm',
  isoStringWithUtcOffset: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
  local: 'LL',
  shortDate: 'DD MMM YY',
  simpleBrazilianPattern: 'MMMM [de] YYYY',
  simplified: 'DD/MM',
  smallFancy: 'DD MMM, YYYY',
  smallHour: 'LT',
  smallMonthFancy: 'MMMM, YYYY',
  variantSmallFancy: 'DD MMM YYYY',
  variantSmallMonthFancy: 'MMMM YYYY',
  yearAndMonth: 'YYYY-MM',
  dayAndMonth: 'DD [de] MMMM',
  weekDay: 'dddd'
}

export interface FormatTmMethods {
  /**
   * @usage should any of these:
   * - `brazilianPattern` > '21/08/2024'
   * - `dateAndMonth` > '21 ago'
   * - `dateWithDaysOfTheWeek` > 'quarta-feira, 21 ago'
   * - `dateOnly` > '21'
   * - `default` > '21/08/2024'
   * - `defaultApi` > '2024-08-21'
   * - `extensiveBrazilianPattern` > '21 de agosto de 2024'
   * - `fancy` > '21 ago 2024 • 21:00'
   * - `fancyHour` > '21 ago 2024 às 21:00'
   * - `fullDateAndHour` > '21 ago 2024 - 21:00'
   * - `fullMonth` > 'agosto'
   * - `fullYear` > '2024'
   * - `hourOnly` > '21:00'
   * - `isoStringWithUtcOffset` > '2024-08-21T21:00:00.000-03:00'
   * - `local` > '21 de agosto de 2024'
   * - `shortDate` > '21 ago 24'
   * - `simpleBrazilianPattern` > 'agosto de 2024'
   * - `simplified` > '21/08'
   * - `smallFancy` > '21 ago, 2024'
   * - `smallHour` > '21:00'
   * - `smallMonthFancy` > 'agosto, 2024'
   * - `variantSmallFancy` > '21 ago 2024'
   * - `variantSmallMonthFancy` > 'agosto 2024'
   * - `yearAndMonth` > '2024-08'
   * - `dayAndMonth` > '12 de agosto'
   * - `weekDay` > 'quarta-feira'
   */
  format(format: keyof typeof allowedFormats): string
  formatDefaultLocalTime(): string
}

export const formatTmPlugin: TmPlugin<FormatTmMethods> = instance => {
  const proto = instance.prototype

  proto.format = function (format) {
    return this['$dateEngine'].format(allowedFormats[format])
  }

  proto.formatDefaultLocalTime = function () {
    return this['$dateEngine'].format()
  }
}
