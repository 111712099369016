import { type RichEvent, Analitica } from '@stone-payments/analitica'
import { AmplitudePlugin } from '@stone-payments/analitica/plugin-amplitude'
import { DatadogPlugin } from '@stone-payments/analitica/plugin-datadog'
import { Experiment } from '@stone-payments/experiment'
import { AmplitudeExperimentJS } from '@stone-payments/experiment/plugins/amplitude-experiment-js'
import {
  AMPLITUDE_API_KEY,
  APP_ENV,
  APP_VERSION,
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  EXPERIMENT_API_KEY
} from 'shared/envs'

const appEnv = APP_ENV() as string
const appVersion = APP_VERSION() as string
const apiKey = AMPLITUDE_API_KEY() as string
const apiKeyExperiment = EXPERIMENT_API_KEY() as string

class AmplitudeDashboard extends AmplitudePlugin {
  name = 'AMPLITUDE'
  constructor(apiKey: string, options: unknown) {
    super(apiKey, options)
  }

  sendEvent(event: RichEvent): void {
    const { metadata, label, ...rest } = event

    this.instance?.logEvent(label, { ...metadata, ...rest })
  }
}

type AnaliticaMetadata = {
  app?: {
    version: string
  }
  identity?: undefined | string
  user?: {
    id: undefined | string
  }
}

export const analiticaInstance = new Analitica(
  {
    app: { version: appVersion },
    user: {
      id: undefined
    }
  } as AnaliticaMetadata,
  [
    new AmplitudeDashboard(apiKey, { defaultTracking: false }),
    new DatadogPlugin({
      applicationId: DATADOG_APPLICATION_ID(),
      clientToken: DATADOG_CLIENT_TOKEN(),
      site: 'datadoghq.com',
      service: 'conta-stone',
      version: APP_VERSION(),
      env: appEnv,
      costCenter: '830190020',
      githubOrg: 'dlpco',
      githubRepo: 'dashboard',
      owner: 'shared-owner',
      project: 'dashboard',
      support: 'interaction-plataforms',
      sessionSampleRate: 10,
      sessionReplaySampleRate: appEnv === 'prod' ? 5 : 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask',
      groupSlack: 'stonks-web'
    })
  ],
  { dryRun: !['prod', 'sdb'].includes(appEnv) }
)

export const experimentInstance = new Experiment([new AmplitudeExperimentJS(apiKeyExperiment)])
