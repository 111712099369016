import { type PaymentAccount } from '~/domains/platform/infra/deus-ex-machina/ports'

import { Balance } from './balance'
import { Wrapper } from './styles'

export interface BalanceCardProps {
  accountId: PaymentAccount['id']
  showBalance?: boolean
}

export function BalanceCard({ accountId, showBalance }: BalanceCardProps) {
  return (
    <Wrapper aria-live="polite" data-testid="button" aria-expanded={showBalance}>
      <Balance accountId={accountId} />
    </Wrapper>
  )
}
