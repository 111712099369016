import { useEffect } from 'react'
import { removeFalsyProperties } from '@credit-web/common/object-utils'
import { Button, IconShape, Modal, Text } from '@stone-payments/jade'

import { loanConcessionBasePath } from '~/domains/credit/ports/credit.routes'
import { Flex } from '~/domains/platform/design-system'
import BlackBird from '~/domains/platform/lib/blackbird'

import analitica from '../helpers/analitica'
import { useLoanConcessionModal } from '../hooks/use-loan-concession-modal'

interface Props {
  isOpen: boolean
  offerId?: string
  toggleModal: () => void
  proposalId?: string
  bannerProps?: {
    offerId?: string
    proposalId?: string
  }
}

export function LoanConcessionModal({ isOpen, toggleModal, offerId, proposalId, bannerProps }: Props) {
  const hasBannerProps = Object.prototype.hasOwnProperty.call(bannerProps || {}, 'offerId')
  const { offerId: id, offerAmount } = useLoanConcessionModal({
    offerId: bannerProps?.offerId ?? offerId ?? '',
    offerOrigin: hasBannerProps ? 'useLoanHome' : 'useGoToOffers'
  })

  const goToSimulation = () => {
    analitica.events.modal.click('continuar')
    const query = hasBannerProps
      ? removeFalsyProperties({ ...bannerProps })
      : removeFalsyProperties({ offerId, proposalId })
    BlackBird.travelTo({ pathname: loanConcessionBasePath, query })
  }

  useEffect(() => {
    if (isOpen) analitica.events.modal.view({ offerId: id, offerAmount: offerAmount || '' })
  }, [isOpen, id, offerAmount])

  return (
    <Modal
      open={isOpen}
      toggle={toggleModal}
      aria-modal="true"
      aria-labelledby="loan-concession-modal-title"
      onClose={() => analitica.events.modal.click('fechar')}
    >
      <Flex gap=".8rem" flexDirection="column" alignItems="center" justifyContent="center">
        <Flex mb=".4rem">
          <IconShape use="money-hand" variant="positive" size="medium" aria-hidden="true" />
        </Flex>
        <Text color="high" variant="heading-small" id="loan-concession-modal-title">
          Simule seu empréstimo aqui ou direto pelo aplicativo
        </Text>
        <Text color="medium" variant="text-medium" weight="regular">
          Por segurança, a finalização do seu pedido é feita pelo aplicativo da Stone.
        </Text>
        <Button
          aria-label="Continuar para simulação"
          variant="primary-solid"
          onClick={goToSimulation}
          style={{ width: '100%', marginTop: '1.2rem' }}
        >
          Continuar
        </Button>
      </Flex>
    </Modal>
  )
}
