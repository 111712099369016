import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser'

function dynamicBold(templateString: string, templateVariables: Record<string, any>) {
  return templateString.replace(/\*(.*?)\*/g, (_, g) => templateVariables[g])
}

function dynamicRenderBold(templateString: string, templateVariables: Record<string, any>) {
  const entries = Object.entries(templateVariables).map(([key, value]) => [key, renderToString(value)])

  return parse(dynamicBold(templateString, Object.fromEntries(entries)))
}

export function boldTextParser(rawText: string) {
  const stringsBetweenAsterisksRegex = /\*.+?\*/g

  const stringValidate = rawText.match(stringsBetweenAsterisksRegex)

  if (stringValidate) {
    const stringsWithoutTemplateLiteral = stringValidate.map(x => {
      return x.slice(1, -1)
    })

    const templateVariables = Object.fromEntries(
      Object.entries(stringsWithoutTemplateLiteral).map(([, value]) => [
        value,
        <strong style={{ display: 'inline' }} key={value} data-testid={value}>
          {value}
        </strong>
      ])
    )

    return dynamicRenderBold(rawText, templateVariables)
  }

  return rawText
}
