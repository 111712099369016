import { type ScenarioMock } from '~/scaffold/mocks/types/mock-flags-types'

import { variation1, variation2, variation3 } from './loan-installment-details-statements-mock-content'

export const loanInstallmentStatementsDetails: ScenarioMock[] = [
  {
    scenario: 'Variação 1',
    mocks: [
      {
        method: 'GET',
        url: '**/experience-bff-service/management/api/web/v1/loans/**/installments/**/statements**',
        status: 200,
        delay: 200,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(variation1)
      }
    ]
  },
  {
    scenario: 'Variação 2',
    mocks: [
      {
        method: 'GET',
        url: '**/experience-bff-service/management/api/web/v1/loans/**/installments/**/statements**',
        status: 200,
        delay: 200,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(variation2)
      }
    ]
  },
  {
    scenario: 'Variação 3',
    mocks: [
      {
        method: 'GET',
        url: '**/experience-bff-service/management/api/web/v1/loans/**/installments/**/statements',
        status: 200,
        delay: 200,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(variation3)
      }
    ]
  }
]
