import { useState } from 'react'
import { useRouter } from 'next/router'
import { Stack } from '@dlpco/fluid-layout'
import { type ButtonVariants, Button, Divider, Spinner, Text } from '@stone-payments/jade'
import jadeTheme from '@stone-payments/jade-design-tokens/cjs/stone'

import { SharedCustomCard } from '~/domains/credit/shared/components/credit-custom-card'
import { SharedWithCustomNavbar } from '~/domains/credit/shared/components/with-custom-navbar'
import { Flex } from '~/domains/platform/design-system'
import { Dimmer } from '~/domains/platform/design-system/dimmer'
import BlackBird from '~/domains/platform/lib/blackbird'
import { If } from '~/domains/platform/lib/utilities-components'

import { StatusTag } from '../../shared/jade-status-color-map'
import { type LoanDetailsInstallment } from '../entities'
import { useLoanDetails } from '../hooks/use-loan-details'

import { Aside } from './loan-details-jade-aside'
import { LoanDetailsJadeCurrentInstallment } from './loan-details-jade-current-installment'
import { LoanDetailsJadeInstallmentList } from './loan-details-jade-installment-list'
import { LoanDetailsJadeNotifications } from './loan-details-jade-notifications'

const themeSpacings = jadeTheme.global.space

export function LoanDetailsJade() {
  const { loan: loanId }: { loan?: string } = useRouter().query

  const [currentInstallment, setCurrentInstallment] = useState<LoanDetailsInstallment | undefined>()

  const { data: loanData, isLoading } = useLoanDetails({ loanId })

  if (isLoading)
    return (
      <Dimmer isVisible data-testid="loading-component">
        <Flex justifyContent="center" alignItems="center">
          <Spinner size="xlarge" color="brand" />
        </Flex>
      </Dimmer>
    )

  if (!loanData) return <div />

  const isMultipleOverdue = currentInstallment?.status.value === 'multiple_overdue'

  const loanValue = loanData?.generalInformation.creditAmount
  const isLoanSettled = loanData?.generalInformation.status === 'settled'
  const hasOverdueOrDueSoonInstallments = loanData?.installments.find(
    item => item.status.value === 'overdue' || item.status.value === 'due_soon'
  )

  const paymentButtons = {
    overdueOrDueSoon: {
      text: 'Fazer um pagamento',
      color: 'primary-solid'
    },
    default: {
      text: 'Antecipar pagamento',
      color: 'neutral-subtle'
    }
  }

  const selectedPaymentButton = hasOverdueOrDueSoonInstallments ? 'overdueOrDueSoon' : 'default'

  return (
    <SharedWithCustomNavbar
      navigationBarTitle={loanValue ? `Capital de Giro de ${loanValue.description}` : 'Capital de Giro'}
      navigationBarNavigateButtonTarget={'/credito'}
      navigationBarNavigateButtonType="back"
      trailingItem={
        <Button
          variant={paymentButtons[selectedPaymentButton].color as ButtonVariants}
          icon="money-hand"
          onClick={() =>
            BlackBird.travelTo({
              // eslint-disable-next-line no-template-curly-in-string
              pathname: '/credito/emprestimo/${loan}/antecipar-pagamento/inicio',
              urlParams: { loan: loanId }
            })
          }
        >
          {paymentButtons[selectedPaymentButton].text}
        </Button>
      }
    >
      <Flex gap="1.5rem" padding={[0, themeSpacings[500]]} width="100%" maxWidth="var(--comp-page-container-max-width)">
        <Flex flexDirection="column" width="100%" maxWidth="640px">
          <Stack space="1rem">
            <If condition={Boolean(loanData?.notifications && loanData?.notifications.length > 0)}>
              <LoanDetailsJadeNotifications notifications={loanData?.notifications || []} />
            </If>

            <SharedCustomCard style={{ width: '100%' }}>
              <If condition={isLoanSettled && !currentInstallment}>
                <Flex flexDirection="column" gap="1.5rem" padding={themeSpacings[300]}>
                  <Flex justifyContent="space-between">
                    <Text variant="heading-xsmall">{loanData?.installments.length} parcelas quitadas</Text>
                    <StatusTag status="settled" description="Quitado" />
                  </Flex>
                  <Text variant="heading-small">Parabéns! Você quitou seu empréstimo</Text>
                </Flex>
              </If>

              <LoanDetailsJadeCurrentInstallment
                currentInstallment={currentInstallment}
                isMultipleOverdue={isMultipleOverdue}
              />

              <Divider />

              <LoanDetailsJadeInstallmentList
                isLoanSettled={isLoanSettled}
                currentInstallment={currentInstallment}
                setCurrentInstallment={setCurrentInstallment}
                installmentList={loanData?.installments || []}
              />
            </SharedCustomCard>
          </Stack>
        </Flex>
        {<Aside {...loanData} />}
      </Flex>
    </SharedWithCustomNavbar>
  )
}
