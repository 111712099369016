import { create } from 'zustand'

type LoanConcessionModalProps = {
  offerId?: string
  proposalId?: string
}

interface Props {
  loanConcessionModalIsOpen: boolean
  loanConcessionModalProps?: LoanConcessionModalProps
  toggleLoanConcessionModal: (isOpen?: boolean) => void
  setLoanConcessionModalProps: ({ loanConcessionModalProps }: any) => void
}

export const useLoanHomeLoanConcessionDialog = create<Props>((set, get) => {
  return {
    loanConcessionModalIsOpen: false,
    toggleLoanConcessionModal: isOpen =>
      set(() => ({ loanConcessionModalIsOpen: isOpen !== undefined ? isOpen : !get().loanConcessionModalIsOpen })),
    loanConcessionModalProps: undefined,
    setLoanConcessionModalProps: loanConcessionModalProps => set(() => ({ loanConcessionModalProps }))
  }
})

interface LoanConcessionStore {
  isEditingLoanValue: boolean
  setIsEditingLoanValue: (isEditing: boolean) => void
  hasNavigatedToProcessing: boolean
  incrementNavigatedToProcessing: () => void
  errorStates: boolean[]
  handleError: (errors: boolean[]) => void
  hasError: boolean
  requestedAmount: number
  setRequestedAmount: (requestedAmount: number) => void
}

export const useLoanConcessionStore = create<LoanConcessionStore>(set => ({
  isEditingLoanValue: false,
  setIsEditingLoanValue: isEditing => set({ isEditingLoanValue: isEditing }),
  hasNavigatedToProcessing: false,
  incrementNavigatedToProcessing: () =>
    set(state => {
      const newCount = (state.hasNavigatedToProcessing ? 1 : 0) + 1
      return { hasNavigatedToProcessing: newCount > 0 }
    }),
  errorStates: [],
  handleError: (errors: boolean[]) =>
    set(state => {
      const newErrorStates = [...state.errorStates, ...errors]
      return { errorStates: newErrorStates, hasError: newErrorStates.some(error => error) }
    }),
  hasError: false,
  requestedAmount: 0,
  setRequestedAmount: requestedAmount => set({ requestedAmount })
}))
