import { type InstallmentDetailsResponse } from 'modules/credit/loans/src/lib/loan-installment-details/entities'

export const futureInstallmentWithoutPaymentMock: InstallmentDetailsResponse = {
  content: {
    installmentNumber: 6,
    loanId: 'jr7km5kmmq2bf6st48kjya4j6',
    detailsInstallment: {
      remainingAmount: {
        description: 'R$ 1.142,16',
        value: 114216,
        type: 'money'
      },
      status: {
        description: 'Futura',
        value: 'future',
        type: 'enum'
      },
      variant: 'inactive',
      actions: [
        {
          type: 'anticipate_payment'
        }
      ],
      information: [
        {
          icon: 'calendar',
          label: 'Abre em *18 fev. 2025*'
        },
        {
          icon: 'calendar-money',
          label: 'Vence em *17 mar. 2025*'
        }
      ]
    },
    aboutInstallment: [
      [
        {
          title: 'Valor inicial',
          value: {
            description: 'R$ 1.142,16',
            value: 114216,
            type: 'money'
          },
          variant: 'inactive'
        }
      ],
      [
        {
          title: 'Você pagou (-)',
          value: {
            description: 'R$ 0,00',
            value: 0,
            type: 'money'
          },
          variant: 'positive'
        },
        {
          title: 'Economia de juros (-)',
          value: {
            description: 'R$ 0,00',
            value: 0,
            type: 'money'
          },
          variant: 'positive'
        }
      ]
    ]
  },
  traceKey: 'jrb7r38c18jha2ktk3pdy7pmn',
  isValid: true
}

export const futureInstallmentWithPaymentMock: InstallmentDetailsResponse = {
  content: {
    installmentNumber: 3,
    loanId: 'jrajjmvfzfqtgv2z19q3h6edc',
    detailsInstallment: {
      remainingAmount: {
        description: 'R$ 900,37',
        value: 90037,
        type: 'money'
      },
      status: {
        description: 'Futura',
        value: 'future',
        type: 'enum'
      },
      variant: 'inactive',
      actions: [
        {
          type: 'anticipate_payment'
        }
      ],
      information: [
        {
          icon: 'calendar',
          label: 'Abre em *17 mar. 2025*'
        },
        {
          icon: 'calendar-money',
          label: 'Vence em *16 abr. 2025*'
        }
      ]
    },
    aboutInstallment: [
      [
        {
          title: 'Valor inicial',
          value: {
            description: 'R$ 902,45',
            value: 90245,
            type: 'money'
          },
          variant: 'inactive'
        }
      ],
      [
        {
          title: 'Você pagou (-)',
          value: {
            description: 'R$ 2,01',
            value: 201,
            type: 'money'
          },
          variant: 'positive'
        },
        {
          title: 'Economia de juros (-)',
          value: {
            description: 'R$ 0,07',
            value: 7,
            type: 'money'
          },
          variant: 'positive'
        }
      ]
    ]
  },
  traceKey: '84fd72e2675042da9a6422aeb',
  isValid: true
}

export const openInstallmentWithPaymentMock: InstallmentDetailsResponse = {
  content: {
    installmentNumber: 1,
    loanId: 'jrajjmvfzfqtgv2z19q3h6edc',
    detailsInstallment: {
      remainingAmount: {
        description: 'R$ 872,07',
        value: 87207,
        type: 'money'
      },
      status: {
        description: 'Vence em breve',
        value: 'due_soon',
        type: 'enum'
      },
      variant: 'attention',
      actions: [
        {
          type: 'payment'
        }
      ],
      information: [
        {
          icon: 'calendar',
          label: 'Aberta desde *11 jan. 2025*'
        },
        {
          icon: 'calendar-money',
          label: 'Vence em *17 fev. 2025*'
        }
      ]
    },
    aboutInstallment: [
      [
        {
          title: 'Valor inicial',
          value: {
            description: 'R$ 902,45',
            value: 90245,
            type: 'money'
          },
          variant: 'inactive'
        }
      ],
      [
        {
          title: 'Você pagou (-)',
          value: {
            description: 'R$ 29,99',
            value: 2999,
            type: 'money'
          },
          variant: 'positive'
        },
        {
          title: 'Economia de juros (-)',
          value: {
            description: 'R$ 0,39',
            value: 39,
            type: 'money'
          },
          variant: 'positive'
        }
      ]
    ],
    alert: 'due_soon'
  },
  traceKey: '84fd72e2675042da9a6422aeb',
  isValid: true
}

export const openInstallmentWithoutPaymentMock: InstallmentDetailsResponse = {
  content: {
    installmentNumber: 3,
    loanId: 'jr95x36pax464yd67a7gnmtfj',
    detailsInstallment: {
      remainingAmount: {
        description: 'R$ 951,80',
        value: 95180,
        type: 'money'
      },
      status: {
        description: 'Aberta',
        value: 'open',
        type: 'enum'
      },
      variant: 'neutral',
      actions: [
        {
          type: 'anticipate_payment'
        }
      ],
      information: [
        {
          icon: 'calendar',
          label: 'Aberta desde *22 jan. 2025*'
        },
        {
          icon: 'calendar-money',
          label: 'Vence em *21 fev. 2025*'
        }
      ]
    },
    aboutInstallment: [
      [
        {
          title: 'Valor inicial',
          value: {
            description: 'R$ 951,80',
            value: 95180,
            type: 'money'
          },
          variant: 'inactive'
        }
      ],
      [
        {
          title: 'Você pagou (-)',
          value: {
            description: 'R$ 0,00',
            value: 0,
            type: 'money'
          },
          variant: 'positive'
        },
        {
          title: 'Economia de juros (-)',
          value: {
            description: 'R$ 0,00',
            value: 0,
            type: 'money'
          },
          variant: 'positive'
        }
      ]
    ]
  },
  traceKey: 'jrb7t98fy9t1gmfzdcq1ve7zx',
  isValid: true
}

export const dueSoonInstallmentMock: InstallmentDetailsResponse = {
  content: {
    installmentNumber: 5,
    loanId: 'jr7km5kmmq2bf6st48kjya4j6',
    detailsInstallment: {
      remainingAmount: {
        description: 'R$ 1.142,16',
        value: 114216,
        type: 'money'
      },
      status: {
        description: 'Vence em breve',
        value: 'due_soon',
        type: 'enum'
      },
      variant: 'attention',
      actions: [
        {
          type: 'payment'
        }
      ],
      information: [
        {
          icon: 'calendar',
          label: 'Aberta desde *18 jan. 2025*'
        },
        {
          icon: 'calendar-money',
          label: 'Vence em *17 fev. 2025*'
        }
      ]
    },
    aboutInstallment: [
      [
        {
          title: 'Valor inicial',
          value: {
            description: 'R$ 1.142,16',
            value: 114216,
            type: 'money'
          },
          variant: 'inactive'
        }
      ],
      [
        {
          title: 'Você pagou (-)',
          value: {
            description: 'R$ 0,00',
            value: 0,
            type: 'money'
          },
          variant: 'positive'
        },
        {
          title: 'Economia de juros (-)',
          value: {
            description: 'R$ 0,00',
            value: 0,
            type: 'money'
          },
          variant: 'positive'
        }
      ]
    ],
    alert: 'due_soon'
  },
  traceKey: '84fd72e2675042da9a6422aeb',
  isValid: true
}

export const overdueInstalmentMock: InstallmentDetailsResponse = {
  content: {
    installmentNumber: 1,
    loanId: 'jr7km5kmmq2bf6st48kjya4j6',
    installmentPlanId: 'jr7km6ws6vt2ehpek3hryep72',
    isOverdue: true,
    detailsInstallment: {
      remainingAmount: {
        description: 'R$ 1.430,52',
        value: 143052,
        type: 'money'
      },
      status: {
        description: 'Em atraso',
        value: 'overdue',
        type: 'enum'
      },
      variant: 'negative',
      actions: [
        {
          type: 'payment'
        }
      ],
      information: [
        {
          icon: 'calendar-money',
          label: 'Em atraso há *144 dias*'
        },
        {
          icon: 'money-hand',
          label: 'Multa e juros *R$ 288,37*'
        }
      ]
    },
    aboutLoan: {
      remainingAmount: {
        description: 'R$ 7.713,08',
        value: 771308,
        type: 'money'
      },
      remainingAmountAdvancePayment: {
        description: 'R$ 7.704,07',
        value: 770407,
        type: 'money'
      }
    },
    aboutInstallment: [
      [
        {
          title: 'Valor inicial',
          value: {
            description: 'R$ 1.142,16',
            value: 114216,
            type: 'money'
          },
          variant: 'inactive'
        }
      ],
      [
        {
          title: 'Juros por atraso (+)',
          value: {
            description: 'R$ 54,82',
            value: 5482,
            type: 'money'
          },
          variant: 'negative'
        },
        {
          title: 'Multa por atraso (+)',
          value: {
            description: 'R$ 22,84',
            value: 2284,
            type: 'money'
          },
          variant: 'negative'
        }
      ],
      [
        {
          title: 'Você pagou (-)',
          value: {
            description: 'R$ 0,00',
            value: 0,
            type: 'money'
          },
          variant: 'positive'
        },
        {
          title: 'Economia de juros (-)',
          value: {
            description: 'R$ 0,00',
            value: 0,
            type: 'money'
          },
          variant: 'positive'
        }
      ]
    ],
    notification: 'help_overdue',
    alert: 'overdue'
  },
  traceKey: '84fd72e2675042da9a6422aeb',
  isValid: true
}

export const settledInstallmentMock: InstallmentDetailsResponse = {
  content: {
    installmentNumber: 1,
    loanId: 'jr576v7ddszd6d6dnwua79tax',
    isOverdue: false,
    detailsInstallment: {
      remainingAmount: {
        description: 'R$ 0,00',
        value: 0,
        type: 'money'
      },
      status: {
        description: 'Quitada',
        value: 'settled',
        type: 'enum'
      },
      variant: 'positive',
      actions: [],
      information: [
        {
          icon: 'circle-money',
          label: 'Você pagou *R$ 961,19*'
        },
        {
          icon: 'calendar-money',
          label: 'Quitada em *26 ago. 2024*'
        }
      ]
    },
    aboutLoan: {
      remainingAmount: {
        description: 'R$ 0,00',
        value: 0,
        type: 'money'
      },
      remainingAmountAdvancePayment: {
        description: 'R$ 0,00',
        value: 0,
        type: 'money'
      }
    },
    aboutInstallment: [
      [
        {
          title: 'Valor inicial',
          value: {
            description: 'R$ 902,53',
            value: 90253,
            type: 'money'
          },
          variant: 'inactive'
        }
      ],
      [
        {
          title: 'Juros por atraso (+)',
          value: {
            description: 'R$ 13,54',
            value: 1354,
            type: 'money'
          },
          variant: 'negative'
        },
        {
          title: 'Multa por atraso (+)',
          value: {
            description: 'R$ 18,05',
            value: 1805,
            type: 'money'
          },
          variant: 'negative'
        }
      ],
      [
        {
          title: 'Você pagou (-)',
          value: {
            description: 'R$ 961,19',
            value: 96119,
            type: 'money'
          },
          variant: 'positive'
        },
        {
          title: 'Economia de juros (-)',
          value: {
            description: 'R$ 0,00',
            value: 0,
            type: 'money'
          },
          variant: 'positive'
        }
      ]
    ]
  },
  traceKey: '84fd72e2675042da9a6422aeb',
  isValid: true
}
