import { useCallback } from 'react'
import { Stack } from '@dlpco/fluid-layout'
import { Button, Divider, Drawer, Icon, IconButton, IconShape, ListItem, Text } from '@stone-payments/jade'
import jadeTheme from '@stone-payments/jade-design-tokens/cjs/stone'
import styled from 'styled-components'

import { Box, Flex } from '~/domains/platform/design-system'
import { chat } from '~/domains/platform/infra/chat/ports/chat'
import { For } from '~/domains/platform/lib/utilities-components'
import { exportFile } from '~/lib/helpers'

import { type LoanDetails } from '../entities'
import { openContract } from '../services/contracts'

const themeSpacings = jadeTheme.global.space
const themeColors = jadeTheme.theme.color

const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${themeColors.background.surface};
  height: 100vh;
  align-items: center;
  padding: ${themeSpacings[300]};
  justify-content: space-between;
`
const DrawerHelpButton = () => (
  <Box
    borderRadius="16px"
    border={`1px solid ${themeColors.border.low}`}
    backgroundColor={themeColors.content['on-color-high']}
  >
    <ListItem
      onClick={() => window.open('https://ajuda.stone.com.br/', '_blank')}
      leading={<IconShape use="circle-help" size="medium" variant="neutral" />}
      content={
        <ListItem.Content label="Mais dúvidas?" description="Confira mais informações sobre o Capital de Giro." />
      }
      trailing={<Icon use="chevron-right" size="medium" color="low" />}
    />
  </Box>
)

interface DrawerProps {
  isOpen: boolean
  setIsOpen: (toggleState: boolean) => void
}

export function LoanDetailsJadeInterestEconomyDrawer({ isOpen, setIsOpen }: DrawerProps) {
  return (
    <Drawer open={isOpen} toggle={(toggleState: boolean) => setIsOpen(toggleState)} title="Economia de juros">
      <DrawerContent>
        <Box>
          <Text variant="text-large" color="medium">
            Todo pagamento feito antes do vencimento da parcela aberta economiza juros e{' '}
            <b>reduz o valor da parcela.</b>
          </Text>
          <br />
          <Text variant="text-large" color="medium">
            Ou seja: a economia de juros te ajuda a pagar o empréstimo.
          </Text>
        </Box>

        <DrawerHelpButton />
      </DrawerContent>
    </Drawer>
  )
}

export function LoanDetailsJadeCETDrawer({ isOpen, setIsOpen }: DrawerProps) {
  return (
    <Drawer open={isOpen} toggle={(toggleState: boolean) => setIsOpen(toggleState)} title="Custo Efetivo Total">
      <DrawerContent>
        <Box>
          <Text variant="text-large" color="medium">
            O Custo Efetivo Total (CET) é a soma de todos os custos da operação de empréstimo, ou seja, juros e IOF.
          </Text>
        </Box>

        <DrawerHelpButton />
      </DrawerContent>
    </Drawer>
  )
}

export function LoanDetailsJadeIOFDrawer({ isOpen, setIsOpen }: DrawerProps) {
  return (
    <Drawer open={isOpen} toggle={(toggleState: boolean) => setIsOpen(toggleState)} title="IOF financiado">
      <DrawerContent>
        <Box>
          <Text variant="text-large" color="medium">
            O Imposto sobre Operação Financeiras (IOF) é um imposto federal aplicado sobre qualquer operação financeira.
          </Text>
        </Box>

        <DrawerHelpButton />
      </DrawerContent>
    </Drawer>
  )
}

interface DocumentsDrawerProps extends DrawerProps {
  documents: LoanDetails['aboutLoan']['documents']
}

export function LoanDetailsJadeDocumentsDrawer({ isOpen, setIsOpen, documents }: DocumentsDrawerProps) {
  const handleDownload = useCallback(async (uri?: string, fileName: string = 'Documento') => {
    const response = await openContract(uri || '')
    const { data } = response
    if (data) {
      await exportFile({
        data,
        name: fileName + '.pdf'
      })
    }
  }, [])

  return (
    <Drawer open={isOpen} toggle={(toggleState: boolean) => setIsOpen(toggleState)} title="">
      <Flex backgroundColor={themeColors.background.surface} height="100vh">
        <Stack space="2rem">
          <Box pt={themeSpacings[300]} pl={themeSpacings[300]}>
            <Text variant="heading-large">Documentos</Text>
            <Text color="medium">Escolha um documento para baixar.</Text>
          </Box>
          <Box>
            <For
              of={documents}
              render={(item, index) => (
                <Box key={item.name + index}>
                  <ListItem
                    content={<ListItem.Content label={item.name} description={item.description} />}
                    trailing={
                      <IconButton
                        icon="action-download"
                        variant="neutral-subtle"
                        onClick={() => handleDownload(item.url, item.name)}
                      />
                    }
                  />
                  <Divider />
                </Box>
              )}
            />
          </Box>
        </Stack>
      </Flex>
    </Drawer>
  )
}

export function OverdueConsequencesDrawer({ isOpen, setIsOpen }: DrawerProps) {
  return (
    <Drawer open={isOpen} toggle={(toggleState: boolean) => setIsOpen(toggleState)} title="Consequências do atraso">
      <Flex
        flexDirection="column"
        height="100vh"
        alignItems="center"
        padding={themeSpacings[300]}
        backgroundColor={themeColors.background.surface}
        justifyContent="space-between"
      >
        <Box>
          <Text color="medium">
            Ao atrasar uma parcela, as garantias definidas na contratação do empréstimo são aplicadas para receber o
            pagamento. Estas são as consequências do atraso:
          </Text>
          <Box pl="1rem">
            <ul>
              <li>
                <Text color="medium">
                  Aumento do percentual de pagamento automático <b>para até 100%</b> ao atrasar uma parcela
                </Text>
              </li>
              <li>
                <Text color="medium">
                  Retenção de recebimentos <b>na pessoa física na sua conta Stone</b> e todos os direitos relacionados a
                  ela
                </Text>
              </li>
              <li>
                <Text color="medium">
                  Retenção dos <b>seus recebimentos em outras adquirentes</b>, além da Stone
                </Text>
              </li>
              <li>
                <Text color="medium">
                  No caso de empréstimo de pessoa jurídica,{' '}
                  <b>o garantidor avalista pode ser acessado a qualquer momento</b> para que pague o valor em atraso
                </Text>
              </li>
              <li>
                <Text color="medium">
                  Seu documento <b>pode ser negativado junto aos órgãos de proteção ao crédito</b>
                </Text>
              </li>
              <li>
                <Text color="medium">
                  Pode haver protestos e medidas de cobrança extrajudiciais e judiciais,{' '}
                  <b>incluindo a penhora de bens</b> de sua titularidade
                </Text>
              </li>
            </ul>
          </Box>
          <Text color="medium">
            Cuide do seu empréstimo. Se estiver tendo dificuldades no pagamento, converse com a gente.
          </Text>
        </Box>

        <Button variant="neutral-subtle" onClick={() => chat.open('LOANS')} style={{ width: '100%' }}>
          Conversar com a Stone
        </Button>
      </Flex>
    </Drawer>
  )
}
