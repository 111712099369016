export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

type DefaultBodyTypes =
  | ReadableStream
  | string
  | FormData
  | URLSearchParams
  | Blob

export type HttpBody = Record<string, unknown> | DefaultBodyTypes

export type HttpHeaders = Record<string, string>

export type HttpParams = Record<string, unknown>

type HttpErrorParams<T> = {
  status: number
  data: T
  message?: string
}

/**
 * HttpClient error
 * @extends Error
 *
 * This error is thrown when a request fails.
 *
 * @param status - HTTP status code
 * @param data - Response data
 * @param message - Error message
 *
 * @example
 * ```ts
 * try {
 *   const res = await httpClient.get('/some-endpoint')
 * } catch (err) {
 *   if (err instanceof HttpClienteError) {
 *    console.error('Request failed', err)
 *   }
 * }
 */
export class HttpClienteError<T> extends Error {
  status: number
  data: T

  constructor({ status, data, message }: HttpErrorParams<T>) {
    super(message ?? `Request failed with status ${status}`)
    this.status = status
    this.data = data
  }
}

export type HttpClientResponse<T> = {
  data: T
  status: number
}

export type HttpResponseType = 'json' | 'blob' | 'text' | 'arrayBuffer'

export type HttpRequestInfo = {
  url: URL
  options: RequestInit
}

type HttpErrorHandlersParams = {
  res: Response
  requestInfo: HttpRequestInfo
}

type HttpErrorHandlersResponse<T, E> = Promise<
  HttpClientResponse<T> | HttpClienteError<E> | void
>

/**
 * Http error handler
 *
 * This function is called when an error occurs in the request.
 *
 * @param res - Response object
 * @param requestInfo - Request information
 * @returns - A promise that resolves when the error is handled
 *
 * @example
 * ```ts
 * const unauthorizedHandler: HttpErrorHandler = async ({ res }) => {
 *   if (res.status === 401) {
 *     isomorphicPush('/sair?shouldRedirect=true')
 *   }
 * }
 */
export type HttpErrorHandler = <T, E = unknown>({
  res,
  requestInfo
}: HttpErrorHandlersParams) => HttpErrorHandlersResponse<T, E>

export type HttpErrorHandlerWithPriority = {
  key?: string
  handler: HttpErrorHandler
  priority: HttpErrorHandlerPriorityType
}

export const HttpErrorHandlerPriority = {
  /**
   * Critical error handler priority
   *
   * Use this priority for error handlers that should be executed first than default error handlers.
   */
  CRITICAL: 0,
  /**
   * High error handler priority
   *
   * Use this priority for error handlers that should be executed after critical error handlers.
   * This is the default priority for default error handlers.
   */
  HIGH: 1,
  /**
   * Medium error handler priority
   *
   * Use this priority for error handlers that should be executed after high error handlers.
   */
  MEDIUM: 2,
  /**
   * Low error handler priority
   *
   * Use this priority for error handlers that should be executed after medium error handlers.
   */
  LOW: 3
} as const

export type HttpErrorHandlerPriorityType =
  (typeof HttpErrorHandlerPriority)[keyof typeof HttpErrorHandlerPriority]

/**
 * Options for configuring an HTTP request.
 *
 *
 * @param body - The body of the request.
 * @param method - The HTTP method to be used for the request.
 * @param headers - The HTTP headers to be sent with the request.
 * @param params - Query parameters to be added to the request URL.
 * @param responseType - The expected response type.
 *
 * @example
 * ```ts
 * const options: Options = {
 *   method: 'POST',
 *   headers: {
 *     'Content-Type': 'application/json'
 *   },
 *   body: JSON.stringify({ key: 'value' }),
 *   responseType: 'json'
 * }
 * ```
 */
export type Options = Omit<RequestInit, 'body' | 'method' | 'headers'> & {
  body?: HttpBody
  method?: HttpMethod
  headers?: HttpHeaders
  params?: HttpParams
  responseType?: HttpResponseType
}

export type ChallengerRequired = {
  id: string
  required_types: Array<'pin' | 'otp' | 'password'>
}
