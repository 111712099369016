import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'

import { SharedWithCustomNavbar } from '~/domains/credit/shared/components/with-custom-navbar'
import BlackBird from '~/domains/platform/lib/blackbird'

import {
  type LoanGenericFeedbackProps,
  SharedLoanGenericFeedback
} from '../../shared/components/shared-loan-generic-feedback'
import { SharedPageContainerWrapper } from '../../shared/components/shared-page-container-wrapper'
import { RefinancingFlowBreadcrumb } from '../components/refinancing/refinancing-flow-breadcrumb'
import { RefinancingFlowLoading } from '../components/refinancing/refinancing-flow-loading'
import { type RefinancingSteps } from '../helpers/refinancing-flow-routes'
import { checkRefinancingFeatureFlags } from '../shared/check-refinancing-feature-flags'

type LoanGenericFeedbackPropsContext = Omit<LoanGenericFeedbackProps, 'navigationBarTitle'>

interface RefinancingContextProps {
  hasFeatureFlags?: boolean
  contractId: string
  saveContractId: (data: string) => void
  step: RefinancingSteps | undefined
  setStep: (data: RefinancingSteps) => void
  navigationBarTitle: string
  setNavigationBarTitle: (data: string) => void
  feedbackContextError: LoanGenericFeedbackPropsContext | undefined
  setContextFeedbackError: (data?: LoanGenericFeedbackPropsContext) => void
  isContextLoading: boolean
  setContextLoading: (data: boolean) => void
  reset: () => void
}

export const defaultRefinancingNavigationBarTitle = 'Renegociação'

const RefinancingContext = createContext<RefinancingContextProps>({
  hasFeatureFlags: undefined,
  contractId: '',
  navigationBarTitle: defaultRefinancingNavigationBarTitle,
  feedbackContextError: undefined,
  isContextLoading: false,
  step: undefined,
  // eslint-disable-next-line prettier/prettier
  saveContractId: () => { },
  // eslint-disable-next-line prettier/prettier
  setNavigationBarTitle: () => { },
  // eslint-disable-next-line prettier/prettier
  setContextFeedbackError: () => { },
  // eslint-disable-next-line prettier/prettier
  setContextLoading: () => { },
  // eslint-disable-next-line prettier/prettier
  setStep: () => { },
  // eslint-disable-next-line prettier/prettier
  reset: () => { }
})

const Feedback = React.memo(
  ({
    feedbackError,
    navigationBarTitle
  }: {
    feedbackError: LoanGenericFeedbackPropsContext | undefined
    navigationBarTitle: string
  }) => {
    return <SharedLoanGenericFeedback {...feedbackError} navigationBarTitle={navigationBarTitle} hasSagaKey={true} />
  }
)

export const RefinancingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const hasFeatureFlags = checkRefinancingFeatureFlags()
  const { step: routeStep } = BlackBird.getQuery()
  const [contractId, setContractId] = useState<string>('')
  const [step, setStep] = useState<RefinancingSteps | undefined>(undefined)
  const [isContextLoading, setContextLoading] = useState<boolean>(false)
  const [navigationBarTitle, setNavigationBarTitle] = useState<string>(defaultRefinancingNavigationBarTitle)
  const [feedbackContextError, setContextFeedbackError] = useState<LoanGenericFeedbackPropsContext | undefined>()

  const saveContractId = (data: string) => {
    setContractId(data)
  }

  useEffect(() => {
    setStep(routeStep)
  }, [routeStep])

  const updateFeedbackError = (error?: LoanGenericFeedbackPropsContext) => {
    setContextFeedbackError({ ...error }) // Forces state change
  }

  const reset = () => {
    setContextFeedbackError(undefined)
  }

  // Wrapping the context value in useMemo to avoid unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      hasFeatureFlags,
      contractId,
      saveContractId,
      step,
      setStep,
      navigationBarTitle,
      setNavigationBarTitle,
      feedbackContextError,
      setContextFeedbackError: updateFeedbackError,
      isContextLoading,
      setContextLoading,
      reset
    }),
    [hasFeatureFlags, contractId, step, navigationBarTitle, feedbackContextError, isContextLoading]
  )

  const isFeedbackError = typeof feedbackContextError !== 'undefined'

  if (!hasFeatureFlags) {
    BlackBird.travelTo('/credito')
    return null
  }

  return (
    <RefinancingContext.Provider value={contextValue}>
      <SharedWithCustomNavbar
        navigationBarTitle="Renegociação"
        backgroundColor="body"
        navigationBarNavigateButtonTarget={'/credito'}
        navigationBarNavigateButtonType={'close'}
      >
        <>
          {isFeedbackError && <Feedback feedbackError={feedbackContextError} navigationBarTitle={navigationBarTitle} />}
          {!isFeedbackError && (
            <SharedPageContainerWrapper>
              <RefinancingFlowBreadcrumb step={step} />
              {isContextLoading ? <RefinancingFlowLoading withBreadcrumbSkeleton={true} /> : children}
            </SharedPageContainerWrapper>
          )}
        </>
      </SharedWithCustomNavbar>
    </RefinancingContext.Provider>
  )
}

export const useRefinancingContext = () => {
  const context = useContext(RefinancingContext)
  if (!context) {
    console.error('useRefinancingContext must be used within a RefinancingProvider')
  }
  return context
}
